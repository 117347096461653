import React, { memo } from 'react';

import { SelectDropdown } from '~/components/dropdown';

function Fonts({ execCommandWithArgs, execCommand }) {
  const titleSize = [
    {
      value: '6',
      label: 'Titulo',
    },

    {
      value: '5',
      label: 'Sub título',
    },
  ];

  const fontName = [
    {
      value: 'Arial',
      label: 'Arial',
    },
    {
      value: 'Calibri',
      label: 'Calibri',
    },
    {
      value: 'Helvetica',
      label: 'Helvetica',
    },
    {
      value: 'Verdana',
      label: 'Verdana',
    },
    {
      value: 'Lucida Grande',
      label: 'Lucida Grande',
    },
    {
      value: 'Georgia',
      label: 'Georgia',
    },
    {
      value: 'Courier New',
      label: 'Courier New',
    },
    {
      value: 'Times New Roman',
      label: 'Times New Roman',
    },
  ];

  const fontSize = [
    {
      value: '1',
      label: '12',
    },

    {
      value: '2',
      label: '16',
    },

    {
      value: '3',
      label: '20',
    },

    {
      value: '4',
      label: '24',
    },

    {
      value: '5',
      label: '32',
    },

    {
      value: '6',
      label: '38',
    },
    {
      value: '7',
      label: '46',
    },
  ];

  function IncreaseTitleSize(event) {
    execCommand('bold');
    execCommandWithArgs('fontSize', event.target.value);
  }

  function SetFontFamily(event) {
    execCommandWithArgs('fontName', event.target.value);
  }

  function IncreaseFontSize(event) {
    execCommandWithArgs('fontSize', event.target.value);
  }

  return (
    <>
      <SelectDropdown
        menuTitle="Títulos"
        dropdownId="title"
        items={titleSize}
        ItemOnClick={IncreaseTitleSize}
      />
      <SelectDropdown
        menuTitle="Fontes"
        dropdownId="font-name"
        items={fontName}
        ItemOnClick={SetFontFamily}
      />
      <SelectDropdown
        menuTitle="Tamanho"
        dropdownId="font"
        items={fontSize}
        ItemOnClick={IncreaseFontSize}
      />
    </>
  );
}

export default memo(Fonts);
