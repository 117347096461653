//Environments
import System from '~/environments/System';

export default {
  async Create(data) {
    //? Checks whether you have the name and value field
    if (!data.name || !data.value) {
      return false;
    }
    //? Prepares the expiration field
    let stretchExpire;
    if (data.expires) {
      stretchExpire = `expires=${data.expires};`;
    } else {
      stretchExpire = `expires=${
        System.Production ? System.CookieExpires : ''
      };`;
    }
    //? Prepare the domain field
    let stretchDomain;
    if (data.domain) {
      stretchDomain = `domain=${data.domain};`;
    } else {
      stretchDomain = `domain=${System.Production ? System.CookieDomain : ''};`;
    }
    //? Set the cookie
    document.cookie = `${data.name}=${data.value}; ${stretchExpire} ${stretchDomain} path=/;`;
    return true;
  },
  async Get(name) {
    //? Checks whether you have the name field
    if (!name) {
      return false;
    }
    //? Get
    let replace = new RegExp('(^| )' + name + '=([^;]+)');
    let match = document.cookie.match(replace);
    return match ? match[2] : false;
  },
  async Delete(name) {
    //? Checks whether you have the name field
    if (!name) {
      return false;
    }
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
    return true;
  },
};
