import styled from 'styled-components';

const Image = styled.div`
  position: relative;
  border-radius: 8px;

  &::before {
    content: '';
    display: block;
    filter: blur(1px);
    width: 100%;
    min-height: 100%;
    background-image: url(${(props) => props.src});
    background-repeat: no-repeat;
    background-position: top center;
    background-size: cover;
    position: absolute;
    z-index: 1;
  }

  &::after {
    content: '';
    display: block;
    width: 100%;
    min-height: 100%;
    background-image: url(${(props) => props.src});
    background-repeat: no-repeat;
    background-position: top center;
    background-size: contain;
    position: absolute;
    z-index: 1;
  }
`;

export default Image;
