import styled from 'styled-components';

export const ProductServicesButtonContainer = styled.div`
  min-width: 100%;
  display: flex;
  padding: 0;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: -30px 0 20px 0;

  @media (max-width: 680px) {
    width: 100%;

    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;
