import React from 'react';

import { MdAttachMoney } from 'react-icons/md';
import { GiPayMoney, GiReceiveMoney } from 'react-icons/gi';

import Item from './components/Item';

import { AntRow, AntCol } from './style';

export default function CashCharts({ isMobile, theme }) {
  const styles = {
    title: {
      margin: 0,
      color: theme.color.primary,
    },
    icon: {
      backgroundColor: theme.color.primary,
      borderRadius: 8,
      padding: 5,
      fontSize: 50,
      marginRight: 10,
      color: theme.color.white,
    },

    row: {
      alignItems: 'center',
      justifyContent: 'space-between',
    },
  };

  return (
    <AntRow style={styles.row}>
      <AntCol xl={7} lg={8} md={8} sm={24} xs={24}>
        <Item
          theme={theme}
          title="Valor total do sistema"
          amount="R$17.000.00"
          Icon={MdAttachMoney}
        />
      </AntCol>
      <AntCol
        style={{ margin: isMobile && '10px 0' }}
        xl={7}
        lg={8}
        md={8}
        sm={24}
        xs={24}
      >
        <Item
          theme={theme}
          title="Valor total do sistema"
          amount="R$9.000.00"
          Icon={GiPayMoney}
        />
      </AntCol>
      <AntCol xl={7} lg={8} md={8} sm={24} xs={24}>
        <Item
          theme={theme}
          title="Valor total do sistema"
          amount="R$8.000.00"
          Icon={GiReceiveMoney}
        />
      </AntCol>
    </AntRow>
  );
}
