import React, { useState, useContext } from 'react';
//Libraries
import { SearchOutlined } from '@ant-design/icons';
//Contexts
import { AxiosContext } from '~/contexts/AxiosContext';
//Own Components
import FoundPersonContainer from './components/FoundPersonContainer';
//Components
import Input from '~/components/input';
import Button from '~/components/button';
import Loading from '~/components/loading';
//Utils
import { cpfMaskContinuos } from '~/utils/Masks';

export default function SearchView({ setFoundPerson, foundPerson }) {
  const { CallToTheAPI } = useContext(AxiosContext);

  const [inputSearch, setInputSearch] = useState('');
  const [queryAPI, setQueryAPI] = useState(false);

  const HandleChangeLogin = () => (event) => {
    if (event.target.value.length > 14) {
      return;
    }
    if (event.target.value.length <= 14) {
      return setInputSearch(cpfMaskContinuos(event.target.value));
    }
  };

  async function GetPermissions(person) {
    const params = {
      operation: ['Manage', 'Permission', 'Get'],
      urlParams: {
        personId: person.id,
      },
    };
    const response = await CallToTheAPI(params);
    if (response.success) {
      setQueryAPI(false);
      const numberOfPermissions = Object.entries(response.body.permission)
        .length;
      if (numberOfPermissions !== 0) {
        person.permissions = response.body.permission;
      }
      setFoundPerson(person);
    }
  }

  async function SearchPerson() {
    setQueryAPI(true);
    const params = {
      operation: ['Manage', 'PersonSearch', 'Simple'],
      data: {
        filter: {
          searchString: inputSearch.replace(/[^\d]+/g, ''),
        },
      },
    };
    const response = await CallToTheAPI(params);
    if (response.success) {
      GetPermissions(response.body.peopleList.elements[0]);
    }
  }

  return (
    <div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Input
          prefix={<SearchOutlined />}
          value={inputSearch}
          placeholder="CPF do usuário"
          margin="0px 20px 0px 0px"
          onChange={HandleChangeLogin()}
          width="175px"
        />
        <Button
          type="primary"
          disabled={inputSearch.length === 14 ? false : true}
          onClick={() => SearchPerson()}
        >
          Pesquisar
        </Button>
      </div>
      {queryAPI ? <Loading text="Procurando" /> : <></>}
      {foundPerson ? <FoundPersonContainer foundPerson={foundPerson} /> : <></>}
    </div>
  );
}
