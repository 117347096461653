import React, { useState } from 'react';

import Debug from '~/environments/Debug';

import PersonInfo from './components/PersonInfo';
import JudgeContainer from './components/JudgeContainer';
import Files from './components/Files';
import PaymentTypeDetails from './components/PaymentTypeDetails';

import { Body } from './style';

export default function DepositDetails({ propertys, CloseDrawer }) {
  const [comproveImages, setComproveImages] = useState([]);

  const { theme, deposit, GetDepositsAgain } = propertys;

  const isDebug = Debug.Administrator.ManualDeposits;

  return (
    <Body backgroudColor={theme.color.white}>
      <PersonInfo deposit={deposit} />
      {deposit.settings && <Files deposit={deposit} />}
      {deposit.settings && <PaymentTypeDetails deposit={deposit} />}

      <JudgeContainer
        theme={theme}
        deposit={deposit}
        isDebug={isDebug}
        GetDepositsAgain={GetDepositsAgain}
        CloseDrawer={CloseDrawer}
        comproveImages={comproveImages}
        setComproveImages={setComproveImages}
      />
    </Body>
  );
}
