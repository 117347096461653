import React, { memo } from 'react';

import { FaCopy, FaCut } from 'react-icons/fa';

import Button from '~/components/button';

function CopyCut({ execCommand }) {
  return (
    <>
      <Button
        style={{ margin: '0 5px 5px 0' }}
        onClick={() => execCommand('copy')}
        icon={<FaCopy />}
      />

      <Button
        style={{ margin: '0 5px 5px 0' }}
        onClick={() => execCommand('cut')}
        icon={<FaCut />}
      />
    </>
  );
}

export default memo(CopyCut);
