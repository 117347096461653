import React, { memo } from 'react';

import {
  FaBold,
  FaItalic,
  FaUnderline,
  FaStrikethrough,
  FaSubscript,
  FaSuperscript,
  FaUndo,
  FaRedo,
} from 'react-icons/fa';

import Button from '~/components/button';

function TextModifier({ execCommand }) {
  return (
    <>
      <Button
        style={{ margin: '0 5px 5px 0' }}
        onClick={(event) => {
          console.log(`event`);
          execCommand('bold');
        }}
        icon={<FaBold />}
      />
      <Button
        style={{ margin: '0 5px 5px 0' }}
        onClick={() => execCommand('italic')}
        icon={<FaItalic />}
      />
      <Button
        style={{ margin: '0 5px 5px 0' }}
        onClick={() => execCommand('underline')}
        icon={<FaUnderline />}
      />
      <Button
        style={{ margin: '0 5px 5px 0' }}
        onClick={() => execCommand('strikeThrough')}
        icon={<FaStrikethrough />}
      />

      <Button
        style={{ margin: '0 5px 5px 0' }}
        onClick={() => execCommand('subscript')}
        icon={<FaSubscript />}
      />
      <Button
        style={{ margin: '0 5px 5px 0' }}
        onClick={() => execCommand('superscript')}
        icon={<FaSuperscript />}
      />
      <Button
        style={{ margin: '0 5px 5px 0' }}
        onClick={() => execCommand('undo')}
        icon={<FaUndo />}
      />
      <Button
        style={{ margin: '0 5px 5px 0' }}
        onClick={() => execCommand('redo')}
        icon={<FaRedo />}
      />
    </>
  );
}

export default memo(TextModifier);
