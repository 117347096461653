import React from 'react';
// Libraries
import { Row, Col } from 'antd';

export default function HeaderType1({ LeftElements, RightElements }) {
  return (
    <div>
      <Row>
        <Col
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
          }}
          xs={24}
          sm={24}
          md={12}
          lg={12}
          xl={12}
        >
          {LeftElements ? <LeftElements /> : <></>}
        </Col>
        <Col
          style={{ display: 'flex', justifyContent: 'flex-end' }}
          xs={24}
          sm={24}
          md={12}
          lg={12}
          xl={12}
        >
          {RightElements ? <RightElements /> : <></>}
        </Col>
      </Row>
    </div>
  );
}
