import React, { memo, useState } from 'react';

import Search from '~/components/search';

import { Mention, Option } from './style';

function Mentions({
  caretPosition,
  ChangeMentionCSS,
  theme,
  editorRef,
  options,
  onChange,
}) {
  const [value, setValue] = useState('');

  return (
    <Mention
      left={caretPosition.left - 10}
      top={-380 + caretPosition.top}
      scrollBarThumbColor={theme.color.scrollBar}
      scrollBarColor={theme.color.white}
      shadow={theme.color.cardShadowBox}
      id="editor-mention"
    >
      <Search
        size="middle"
        value={value}
        onChange={(event) => {
          const { value } = event.target;
          setValue(value);
          if (onChange) {
            onChange(value);
          }
        }}
      />

      {options.map((option) => (
        <Option
          backgroundColor={theme.color.white}
          backgroundColorOnHover={theme.color.primary}
          color={theme.color.textOnSecondaryBackground}
          colorOnHover={theme.color.white}
          key={option.value}
          value={option.value}
          onClick={(event) => {
            editorRef.current.innerHTML = editorRef.current.innerHTML.replace(
              '@',
              event.target.value
            );

            editorRef.current.innerHTML.replace('@', event.target.value);

            ChangeMentionCSS(0, 'none');
          }}
        >
          {option.label}
        </Option>
      ))}
    </Mention>
  );
}

export default memo(Mentions);
