import React, { useEffect, useState, useContext } from 'react';

import SocialMeAPI from '~/utils/SocialMeAPI';

import Header from './components/Header';
import Content from './components/Content';

import Debug from '~/environments/Debug';

import Card from '~/components/card';
import Divider from '~/components/divider';
import Loading from '~/components/loading';

import { ThemeContext } from '~/contexts/ThemeContext';
import { ResponsiveContext } from '~/contexts/ResponsiveContext';

export default function IncompleteDonations() {
  const isDebug = Debug.Administrator.IncompleteDonations;

  const [loadingContent, setLoadingContent] = useState(true);
  const [search, setSearch] = useState('');
  const [data, setData] = useState();
  const [pageParameters, setPageParameters] = useState({
    currentPage: 1,
    totalElements: 0,
    limit: 10,
  });

  const { isMobile } = useContext(ResponsiveContext);
  const { theme } = useContext(ThemeContext);

  useEffect(() => {
    async function GetData() {
      const params = {
        operation: ['Donation', 'List'],
        data: {
          filter: {
            is_complete: false,
            is_resolved: false,
            is_published: false,
          },
          pagination: {
            sort: {
              by: 'id',
              order: 'asc',
            },
          },
        },
      };

      if (isDebug) console.log('📦 Params: ', params);

      const response = await SocialMeAPI(params);

      if (isDebug) console.log('🚀 API Response, Donation List: ', response);

      if (response.success) {
        if (isDebug) console.log('Donations was listed successfully!');

        setPageParameters({
          currentPage: response.body.donationRequests.currentPage,
          totalElements: response.body.donationRequests.totalElements,
          limit: response.body.donationRequests.limit,
        });
        setData(response.body.donationRequests.elements);
        setLoadingContent(false);
      } else if (isDebug) {
        console.log('None donations was listed');
      }
    }
    GetData();
  }, [isDebug]);

  async function GetDataAgain(newPageParameters) {
    setLoadingContent(true);
    const params = {
      operation: ['Donation', 'List'],
      data: {
        filter: {
          searchString: search,
          is_complete: false,
          is_resolved: false,
          is_published: false,
        },
        pagination: {
          limit: newPageParameters
            ? newPageParameters.limit
            : pageParameters.limit,
          page: newPageParameters
            ? newPageParameters.currentPage
            : pageParameters.currentPage,
          sort: {
            by: 'created_at',
            order: 'asc',
          },
        },
      },
    };

    if (isDebug) console.log('📦 Params: ', params);

    const response = await SocialMeAPI(params);

    if (isDebug)
      console.log('🚀 API Response, Donation List (Again): ', response);

    if (response.success) {
      if (isDebug) console.log('Donations was listed successfully!');

      setPageParameters({
        currentPage: response.body.donationRequests.currentPage,
        totalElements: response.body.donationRequests.totalElements,
        limit: response.body.donationRequests.limit,
      });
      setData(response.body.donationRequests.elements);
      setLoadingContent(false);
    } else if (isDebug) {
      console.log('None donations was listed');
    }
  }

  return (
    <Card>
      <Header
        search={search}
        setSearch={setSearch}
        GetDataAgain={GetDataAgain}
      />
      <Divider borderColor={theme.color.white} />
      {loadingContent ? (
        <Loading text="Carregando pedidos" />
      ) : (
        <Content
          data={data}
          theme={theme}
          isMobile={isMobile}
          pageParameters={pageParameters}
          GetDataAgain={GetDataAgain}
        />
      )}
    </Card>
  );
}
