import React, { useContext } from 'react';
//Contexts
import { PermissionContext } from '~/contexts/PermissionContext';
//Components
import Title from '~/components/typography/title';
//Own Components
import ButtonAdd from './components/ButtonAdd';

export default function Permissions({ PickUpPeopleWithPermissionsAgain }) {
  const { CheckPermission } = useContext(PermissionContext);
  return (
    <div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Title level={4}>Permissões</Title>
        {CheckPermission('UPDATE-PERSON-PERMISSION') && (
          <ButtonAdd
            PickUpPeopleWithPermissionsAgain={PickUpPeopleWithPermissionsAgain}
          />
        )}
      </div>
    </div>
  );
}
