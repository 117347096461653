import API from '~/environments/APIs';

const ProductOrService = {
  Search: {
    baseURL: API.socialMe,
    url: '/person/personId/offerings',
    method: 'post',
    timeout: '50000',
  },
  Get: {
    baseURL: API.socialMe,
    url: '/manage/prod-serv/ps-models',
    method: 'post',
    timeout: '50000',
  },
  Add: {
    baseURL: API.socialMe,
    url: '/manage/prod-serv/ps-model',
    method: 'post',
    timeout: '50000',
  },
  Edit: {
    baseURL: API.socialMe,
    url: '/manage/prod-serv/ps-model/offeringId',
    method: 'put',
    timeout: '50000',
  },
  Delete: {
    baseURL: API.socialMe,
    url: '/manage/prod-serv/ps-model/offeringId',
    method: 'delete',
    timeout: '50000',
  },
};

export default ProductOrService;
