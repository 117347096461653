import styled from 'styled-components';

export const Mention = styled.div`
  width: 160px;
  height: 180px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 6px;
  background-color: white;
  position: relative;
  left: ${(props) => `${props.left}px`};
  top: ${(props) => `${props.top}px`};
  margin-bottom: ${(props) => `${props.top}px`};
  transition: 0.5s;
  display: none;
  opacity: 0;
  overflow-y: auto;
  z-index: 1;

  &::-webkit-scrollbar {
    width: 8px;
    background-color: ${(props) => props.scrollBarColor};
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${(props) => props.scrollBarThumbColor};
  }
`;

export const Option = styled.option`
  padding: 5px;
  color: ${(props) => props.color};
  background-color: ${(props) => props.backgroundColor};

  transition: 0.5s all;
  cursor: pointer;

  &:hover {
    color: ${(props) => props.colorOnHover};
    background-color: ${(props) => props.backgroundColorOnHover};
  }
`;
