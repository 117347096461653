import React, { useContext, useState, useEffect } from 'react';
// Libraries
import { message, Drawer, Form } from 'antd';
// Components
import Button from '~/components/button';
import Loading from '~/components/loading';
//Own Components
import CheckboxGroup from './components/CheckboxGroup';
// Contexts
import { AxiosContext } from '~/contexts/AxiosContext';
import { ResponsiveContext } from '~/contexts/ResponsiveContext';
//Json
import permissionGroups from '../../../permissionGroups.json';

export default function EditButton({
  itemData,
  PickUpPeopleWithPermissionsAgain,
}) {
  const { CallToTheAPI } = useContext(AxiosContext);
  const { isMobile } = useContext(ResponsiveContext);

  const [visibleDrawer, setVisibleDrawer] = useState(false);
  const [form] = Form.useForm();
  const [formValues, setFormValues] = useState('');
  const [loadingButton, setLoadingButton] = useState(false);
  const [loadingForm, setLoadingForm] = useState(true);
  const [initialValues, setInitialValues] = useState([]);

  useEffect(() => {
    async function PrepareInitialValues() {
      let array = {};
      for (const key in itemData.permissions) {
        array[key] = true;
      }
      setInitialValues(array);
      setLoadingForm(false);
    }
    if (itemData) {
      PrepareInitialValues();
    }
  }, [itemData]);

  const ShowDrawer = () => {
    setVisibleDrawer(true);
  };
  const CloseDrawer = () => {
    setVisibleDrawer(false);
  };
  async function UpdatePermissions(data) {
    const params = {
      operation: ['Manage', 'Permission', 'Update'],
      urlParams: {
        personId: itemData.id,
      },
      data,
    };
    //console.log('params -> ', params.data);
    const response = await CallToTheAPI(params);
    if (response.success) {
      CloseDrawer();
      message.success('Permissões atualizadas com sucesso');
      setLoadingButton(false);
      return PickUpPeopleWithPermissionsAgain();
    }
    setLoadingButton(false);
  }

  const onFinish = (data) => {
    //console.log('Received data of form: ', data);
    setLoadingButton(true);
    let treatedDate = { permissions: [] };
    for (const key in data) {
      if (data[key]) {
        const newItem = {
          permission: key,
        };
        treatedDate.permissions.push(newItem);
      }
    }
    console.log('treatedDate -> ', treatedDate);
    UpdatePermissions(treatedDate);
  };
  return (
    <>
      <Button
        text="Editar"
        onClick={() => ShowDrawer()}
        style={{ marginRight: 5 }}
      />

      <Drawer
        title={`EDITANDO ${itemData.name || ''}`}
        width={isMobile ? '100%' : 600}
        onClose={CloseDrawer}
        visible={visibleDrawer}
        bodyStyle={{ padding: 20 }}
        footer={
          <div
            style={{
              textAlign: 'right',
            }}
          >
            <Button onClick={CloseDrawer} style={{ marginRight: 8 }}>
              Cancelar
            </Button>

            <Button
              loading={loadingButton}
              disabled={loadingButton}
              type="primary"
              onClick={() => {
                form
                  .validateFields()
                  .then((values) => {
                    //form.resetFields();
                    //form.setFieldsValue(values);
                    onFinish(values);
                  })
                  .catch((info) => {
                    //console.log('Validate Failed:', info);
                  });
              }}
            >
              Atualizar
            </Button>
          </div>
        }
      >
        {loadingForm ? (
          <Loading text="Carregando permissões" />
        ) : (
          <Form
            labelCol={{
              xs: { span: 24 },
              sm: { span: 24 },
            }}
            wrapperCol={{
              xs: { span: 24 },
              sm: { span: 24 },
            }}
            form={form}
            layout="vertical"
            size="small"
            onValuesChange={() => setFormValues(form.getFieldsValue())}
            scrollToFirstError
            initialValues={initialValues}
          >
            <CheckboxGroup
              permissionGroups={permissionGroups}
              form={form}
              values={formValues}
            />
          </Form>
        )}
      </Drawer>
    </>
  );
}
