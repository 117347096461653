import API from '~/environments/APIs';

const Person = {
  Search: {
    baseURL: API.socialMe,
    url: '/person/search',
    method: 'post',
    timeout: '50000',
  },
  Show: {
    baseURL: API.socialMe,
    url: '/manage/person/profile/personId',
    method: 'get',
    timeout: '50000',
  },
};

export default Person;
