import React from 'react';

import Button from '~/components/button';
import Drawer from '~/components/drawer';

import { ProductServicesButtonContainer } from './style';

import Products from './components/Products';
import Services from './components/Services';

export default function ProductServiceButtons({
  donation,
  setDonation,
  isDebug,
}) {
  return (
    <ProductServicesButtonContainer>
      <Drawer
        title="Adicione produtos"
        placement="right"
        Trigger={<Button type="primary">Adicionar produto</Button>}
        DrawerContentComponent={Products}
        DrawerContentComponentParams={{
          donation,
          setDonation,
          isDebug,
        }}
      />

      <Drawer
        title="Adicione serviços"
        placement="right"
        Trigger={<Button type="primary">Adicionar serviço</Button>}
        DrawerContentComponent={Services}
        DrawerContentComponentParams={{
          donation,
          setDonation,
          isDebug,
        }}
      />
    </ProductServicesButtonContainer>
  );
}
