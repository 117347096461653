import React, { useState } from 'react';

import Card from '~/components/card';
import Editor from '~/components/textEditor';

export default function TextEditor() {
  const [html, setHTML] = useState('');

  console.log(`html: `, html);

  const variables = [
    {
      value: 'valor',
      label: 'Label',
    },
  ];

  return (
    <Card>
      <Editor emojis variables={variables} setValue={setHTML} />
    </Card>
  );
}
