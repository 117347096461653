import React, { useState, createContext, useContext } from 'react';
//Contexts
import { ResponsiveContext } from '~/contexts/ResponsiveContext';

export const SidebarContext = createContext();

const SidebarContextProvider = ({ children }) => {
  const { isMobile } = useContext(ResponsiveContext);
  const [collapsed, setCollapsed] = useState(false);
  const [mobileCollapsed, setMobileCollapsed] = useState(false);

  function ToggleCollapsed() {
    if (isMobile) {
      setMobileCollapsed(!mobileCollapsed);
    } else {
      setCollapsed(!collapsed);
    }
  }

  return (
    <SidebarContext.Provider
      value={{ collapsed, mobileCollapsed, ToggleCollapsed }}
    >
      {children}
    </SidebarContext.Provider>
  );
};

export default SidebarContextProvider;
