import React, { useState } from 'react';

import { Form, message } from 'antd';
import SocialMeAPI from '~/utils/SocialMeAPI';

import Button from '~/components/button';
import FormItem from '~/components/form/FormItem';
import { InputDynamicComplete } from '~/components/autoComplete';
import InputNumber from '~/components/inputNumber';
import Input from '~/components/input';

import ServiceItem from './components/ServiceItem';

export default function Services({ propertys }) {
  const [services, setServices] = useState([]);

  const [selectedService, setSelectedService] = useState({});

  const [form] = Form.useForm();

  let timer = null;

  const isDebug = propertys.isDebug;

  const SearchOptions = (event) => {
    const { value } = event.target;

    async function GetProductsFromAPI(searchString) {
      const params = {
        operation: ['Donation', 'ProductServices', 'Search'],
        data: {
          pagination: {
            limit: 1000,
          },
          filter: {
            searchString,
            kind: 'SERVICE',
          },
        },
      };

      if (isDebug) console.log('📦 Params: ', params);

      const response = await SocialMeAPI(params);

      if (isDebug)
        console.log(
          '🚀 API Response, Donation ProductServices Add: ',
          response
        );

      if (response.success) {
        if (isDebug) console.log('Services was listed successfully');

        setServices(response.body.psModels.elements);
      } else if (isDebug) {
        console.log('No services was found');
      }
    }

    clearTimeout(timer);

    timer = setTimeout(() => {
      GetProductsFromAPI(value);
    }, 500);
  };

  function setServiceName(service) {
    setSelectedService(service);

    const { name } = service;

    form.setFieldsValue({
      name,
    });

    setServices([]);
  }

  async function AddService(values) {
    const { donation, setDonation } = propertys;

    const {
      name,
      personalized_name,
      description,
      valuePerUnit,
      quantity,
    } = values;

    const { id, avatar } = selectedService;

    const data = {
      name: personalized_name,
      description,
      value: Number(valuePerUnit * 1000000),
      product_service_model: id,
      quantity,
    };

    const params = {
      operation: ['Donation', 'ProductServices', 'Add'],
      urlParams: {
        donationId: donation.id,
      },

      data,
    };

    if (isDebug) console.log('📦 Params: ', params);

    const response = await SocialMeAPI(params);

    if (isDebug)
      console.log('🚀 API Response, Donation ProductServices Add: ', response);

    if (response.success) {
      if (isDebug) console.log('Service was add successfully');

      setDonation({
        ...donation,
        product_services: [
          ...donation.product_services,
          {
            id: response.body.newProductServiceOfDonationRequest.id,
            ps_avatar: avatar,
            ...data,
            ps_name: name,
            ps_kind: 'SERVICE',
          },
        ],
      });

      form.resetFields();

      message.success(`Serviço adicionado`);
    } else if (!response.success) {
      if (isDebug) console.log('No service was added');
      message.error('Deve adicionar um dos serviços disponíveis');
    }
  }

  return (
    <>
      <Form
        form={form}
        layout="vertical"
        onFinish={AddService}
        initialValues={{
          personalized_name: '',
          valuePerUnit: 1,
          quantity: 1,
        }}
        style={{
          padding: '10px',
        }}
      >
        <FormItem
          label="Serviço"
          name="name"
          rules={[
            {
              required: true,
              message: 'Por favor, informe um serviço',
            },
          ]}
          item={
            <InputDynamicComplete
              options={services}
              onSelect={setServiceName}
              OptionComponent={ServiceItem}
              onChange={SearchOptions}
            />
          }
        />

        <FormItem
          label="Nome personalizado"
          name="personalized_name"
          rules={[
            {
              required: true,
              message: 'Por favor, informe um noma para seu serviço',
            },
          ]}
          item={<Input />}
        />

        <FormItem
          label="Descrição"
          name="description"
          rules={[
            {
              required: true,
              message: 'Por favor, informe uma descrição',
            },
          ]}
          item={<Input />}
        />

        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '30% 70%',
            marginTop: '30px',
          }}
        >
          <FormItem
            label="Quantidade"
            name="quantity"
            rules={[
              {
                required: true,
                message:
                  'Por favor, informe a quantidade de vezes que o serviço será feito',
              },
            ]}
            item={<InputNumber defaultValue={1} min={1} max={99999999} />}
          />

          <FormItem
            label="Valor"
            name="valuePerUnit"
            rules={[
              {
                required: true,
                message: 'Por favor, informe um valor por serviço feito',
              },
            ]}
            item={<InputNumber defaultValue={1} min={1} max={99999999} />}
          />
        </div>

        <FormItem
          style={{
            marginTop: '30px',
          }}
          item={
            <Button htmlType="submit" type="primary">
              Adicionar Serviço
            </Button>
          }
        />
      </Form>
    </>
  );
}
