import React, { useState, createContext } from 'react';

export const DebugContext = createContext();

// console.log('😀🍪❌✔❗❓⛔🚫🧭🛸⚠🔗🔧🔑🔒🔓📍📌📊📋📉📈💼📦💳📒📙📘📗📕💡🔍💻🔋📱🔔💎🏆🔥💧⚡🚀⌛🛑🚧🌎')

const DebugContextProvider = ({ children }) => {
  const [debug] = useState({
    Context: {
      Axios: false,
      Browser: false,
      Language: false,
      Auth: false,
      Theme: false,
      Wallet: false,
      Permission: false,
    },
    Accounts: false,
    Wallet: {
      Auth: false,
      Home: false,
    },
    Administrator: {
      Products: false,
      Services: false,
      Permissions: false,
      ActivityTypes: true,
    },
  });

  return (
    <DebugContext.Provider value={{ debug }}>{children}</DebugContext.Provider>
  );
};

export default DebugContextProvider;
