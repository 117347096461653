import React, { memo } from 'react';

import Button from '~/components/button';

function Reset({ editorRef }) {
  function CleanEditor() {
    editorRef.current.focus();
    editorRef.current.innerHTML = '';
  }

  return (
    <Button onClick={CleanEditor} style={{ margin: '0 5px 5px 0' }}>
      Resetar
    </Button>
  );
}

export default memo(Reset);
