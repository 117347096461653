import React, { useContext } from 'react';
// Libraries
import { message, Popconfirm, Button } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
// Components
//import Button from '~/components/button';
// Contexts
import { AxiosContext } from '~/contexts/AxiosContext';
import { ThemeContext } from '~/contexts/ThemeContext';

export default function Actions({
  parametersForDeleteData,
  GetDataAgain,
  deleteDataMessages,
}) {
  // Contexts
  const { CallToTheAPI } = useContext(AxiosContext);
  const { theme } = useContext(ThemeContext);
  // Functions
  async function DeletePhone() {
    const response = await CallToTheAPI(parametersForDeleteData);
    console.log(response);
    if (deleteDataMessages) {
      if (response.success) {
        if (deleteDataMessages.success) {
          message.success(deleteDataMessages.success);
        }
      } else if (deleteDataMessages.error) {
        message.error(deleteDataMessages.error);
      }
    }
    return GetDataAgain();
  }
  return (
    <Popconfirm
      placement="top"
      title="Deseja mesmo fazer isso?"
      onConfirm={() => DeletePhone()}
      okText="Sim, delete agora"
      cancelText="Não"
    >
      <Button
        type="secondary"
        shape="square"
        icon={<DeleteOutlined style={{ color: theme.color.danger }} />}
      />
    </Popconfirm>
  );
}
