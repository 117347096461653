import React, { memo, useContext } from 'react';

import { ThemeContext } from '~/contexts/ThemeContext';

import { ItemContainer, DropdownContainer, Dropdown } from './style';

function ButtonDropdown({
  dropdownId,
  menuTitle,
  itemsContainerStyle,
  content,
}) {
  const { theme } = useContext(ThemeContext);

  function ChangeMenuVisibility(visibility) {
    const menu = document.getElementById(dropdownId);

    menu.style.visibility = visibility;
  }

  return (
    <DropdownContainer>
      <Dropdown
        onMouseLeave={() => ChangeMenuVisibility('hidden')}
        onMouseOver={() => ChangeMenuVisibility('visible')}
        onFocus={() => ChangeMenuVisibility('visible')}
        borderColor={theme.color.borderColor}
        primary={theme.color.primary}
      >
        {menuTitle}
      </Dropdown>
      <ItemContainer
        onMouseOver={() => ChangeMenuVisibility('visible')}
        onMouseLeave={() => ChangeMenuVisibility('hidden')}
        theme={theme}
        style={itemsContainerStyle}
        id={dropdownId}
      >
        {content}
      </ItemContainer>
    </DropdownContainer>
  );
}

export default memo(ButtonDropdown);
