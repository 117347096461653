import React, { useContext, useState, useEffect } from 'react';
//Contexts
import { PermissionContext } from '~/contexts/PermissionContext';
//Components
import Loading from '~/components/loading';
//Page
import Page from './index';

export default function PermissionLayer() {
  const { CheckPermission } = useContext(PermissionContext);
  const [hasPermission, setHasPermission] = useState(false);

  useEffect(() => {
    setHasPermission(CheckPermission('SEARCH-SYSTEM-PERSON', true));
  }, [CheckPermission]);

  return hasPermission ? <Page /> : <Loading text="Verificando permissão" />;
}
