import styled from 'styled-components';

export const Textarea = styled.div`
  width: 100%;
  min-height: 400px;
  border-radius: 2px;
  border: 1px solid ${(props) => props.borderColor};
  padding: 10px 8px;
  transition: 0.5s;
  overflow-y: auto;
  z-index: 0;
  outline: none;

  &:focus {
    border-color: ${(props) => props.borderColorOnFocus};
    border-right-width: 1px;
    box-shadow: 0 0 0 2px rgb(97 47 116 / 20%);
  }
`;
