import React, { useContext } from 'react';
//Contexts
import { PermissionContext } from '~/contexts/PermissionContext';
//Own Components
import Actions from './components/Actions';
//Components
import { ImageWithTextCell } from '~/components/table/cells';
import Table from '~/components/table';
import Pagination from '~/components/pagination';

export default function Content({ data, GetDataAgain, pageParameters }) {
  const { CheckPermission } = useContext(PermissionContext);

  const tableColumns = [
    {
      title: 'Local',
      dataIndex: 'name',
      key: 'name',
      render: (name, record) => (
        <ImageWithTextCell image={record.avatar} text={name} />
      ),
    },
  ];

  if (CheckPermission('UPDATE-PLACE') || CheckPermission('DELETE-PLACE')) {
    tableColumns.push({
      title: 'Ações',
      key: 'action',
      render: (data) => <Actions GetDataAgain={GetDataAgain} data={data} />,
    });
  }

  return (
    <div>
      <Table tableColumns={tableColumns} dataReceived={data} />
      <Pagination
        onChange={(currentPage, limit) => GetDataAgain({ currentPage, limit })}
        onShowSizeChange={(currentPage, limit) =>
          GetDataAgain({ currentPage, limit })
        }
        total={pageParameters.totalElements}
        showSizeChanger
        showQuickJumper
        showTotal={(total) => `Total ${total} items`}
      />
    </div>
  );
}
