import React, { memo } from 'react';

import {
  FaAlignCenter,
  FaAlignLeft,
  FaAlignRight,
  FaAlignJustify,
  FaParagraph,
} from 'react-icons/fa';

import Button from '~/components/button';

function Spacement({ execCommand }) {
  return (
    <>
      <Button
        style={{ margin: '0 5px 5px 0' }}
        onClick={() => execCommand('justifyCenter')}
        icon={<FaAlignCenter />}
      />
      <Button
        style={{ margin: '0 5px 5px 0' }}
        onClick={() => execCommand('justifyLeft')}
        icon={<FaAlignLeft />}
      />
      <Button
        style={{ margin: '0 5px 5px 0' }}
        onClick={() => execCommand('justifyRight')}
        icon={<FaAlignRight />}
      />
      <Button
        style={{ margin: '0 5px 5px 0' }}
        onClick={() => execCommand('justifyFull')}
        icon={<FaAlignJustify />}
      />

      <Button
        style={{ margin: '0 5px 5px 0' }}
        onClick={() => execCommand('insertParagraph')}
        icon={<FaParagraph />}
      />
    </>
  );
}

export default memo(Spacement);
