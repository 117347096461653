import React from 'react';

import { EditFilled } from '@ant-design/icons';

import Drawer from '~/components/drawer';
import Table from '~/components/table';
import Button from '~/components/button';

import DepositDetails from './components/DepositDetails';

import {
  ImageWithPrimaryAndSecondaryTextCell,
  PrimaryTextCell,
} from '~/components/table/cells';

import { WalletFormatConversion } from '~/utils/Transformations';

export default function DepositsTable({
  theme,
  manualDeposits,
  GetDepositsAgain,
}) {
  const columns = [
    {
      title: 'Solicitante',
      dataIndex: 'person_name',
      key: 'person_name',
      align: 'left',
      width: '70%',
      render: (person_name, record) => (
        <ImageWithPrimaryAndSecondaryTextCell
          src={record.person_avatar || ''}
          primaryText={person_name}
          secondaryText={record.person_cpf_number}
        />
      ),
    },
    {
      title: 'Valor',
      dataIndex: 'amount',
      key: 'amount',
      align: 'center',
      width: '15%',
      render: (amount) => (
        <PrimaryTextCell
          textAlign="center"
          text={WalletFormatConversion(amount)}
        />
      ),
    },

    {
      title: 'Ação',
      dataIndex: '_id',
      key: '_id',
      align: 'center',
      width: '15%',
      render: (_id, record) => (
        <Drawer
          title="Detalhes da depósito"
          Trigger={
            <Button
              disabled={record.status !== 'PENDING'}
              icon={<EditFilled />}
            >
              Julgar
            </Button>
          }
          DrawerContentComponent={DepositDetails}
          DrawerContentComponentParams={{
            theme,
            deposit: record,
            GetDepositsAgain,
          }}
        />
      ),
    },
  ];

  return <Table tableColumns={columns} dataReceived={manualDeposits} />;
}
