import React from 'react';
// Libraries
import { Space } from 'antd';
// Components
import Button from '~/components/button';

export default function StatusButton({ searchStatus, setSearchStatus }) {
  const StatusButton = ({ status, text }) => {
    return (
      <Button
        onClick={() =>
          setSearchStatus(searchStatus === status ? 'ALL' : status)
        }
        type={searchStatus === status ? 'primary' : 'dashed'}
      >
        {text}
      </Button>
    );
  };
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Space>
        <StatusButton status="ACTIVE" text="Ativos" />
        <StatusButton status="INACTIVE" text="Inativos" />
      </Space>
    </div>
  );
}
