import React, { useContext } from 'react';
//Contexts
import { ThemeContext } from '~/contexts/ThemeContext';
//Components
import Title from '~/components/typography/title';

export default function Home() {
  const { theme } = useContext(ThemeContext);

  return (
    <div
      style={{
        backgroundColor: theme.color.backgroundSecondaryLight,
        width: '100%',
        height: 400,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Title level={4}>INÍCIO</Title>
    </div>
  );
}
