import React, { useContext } from 'react';
//Contexts
import { PermissionContext } from '~/contexts/PermissionContext';
//Components
import Table from '~/components/table';
//Own Components
import { PeopleCell } from './components/TableCells';
import ExpandedRow from './components/ExpandedRow';
import EditButton from './components/EditButton';

export default function Content({
  peopleWithPermissions,
  PickUpPeopleWithPermissionsAgain,
}) {
  const { CheckPermission } = useContext(PermissionContext);

  const tableColumns = [
    {
      title: 'Usuário',
      dataIndex: 'Usuário',
      key: 'name',
      render: (name, record) => <PeopleCell people={record} />,
    },
  ];

  if (CheckPermission('UPDATE-PERSON-PERMISSION')) {
    tableColumns.push({
      title: 'Ações',
      key: 'action',
      render: (data) => (
        <EditButton
          itemData={data}
          PickUpPeopleWithPermissionsAgain={PickUpPeopleWithPermissionsAgain}
        />
      ),
    });
  }

  return (
    <div>
      <Table
        dataReceived={peopleWithPermissions}
        tableColumns={tableColumns}
        bordered
        expandable={{
          expandedRowRender: (record) => <ExpandedRow record={record} />,
        }}
      />
    </div>
  );
}
