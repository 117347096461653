import React from 'react';
//Libraries
import { Divider } from 'antd';
import Animate, { FadeIn } from 'animate-css-styled-components';
//Own Components
import Header from './components/Header';
import Form from './components/Form';

export default function AddingPermissions({ foundPerson, form }) {
  return (
    <div>
      <Animate Animation={[FadeIn]} duration={['0.8s']} delay={['0.2s']}>
        <Header foundPerson={foundPerson} />
        <Divider style={{ margin: '12px 0px' }} />
        <Form form={form} foundPerson={foundPerson} />
      </Animate>
    </div>
  );
}
