import React, { useContext } from 'react';

import Button from '~/components/button';
import Drawer from '~/components/drawer';
import Text from '~/components/typography/text';

import SocialMeAPI from '~/utils/SocialMeAPI';

import { ResponsiveContext } from '~/contexts/ResponsiveContext';

import { WalletFormatConversion } from '~/utils/Transformations';

import EditProductOrService from './components/EditProductOrService';

import { Container, ListItem, TextContent, DeleteIcon } from './style';

export default function ProductServicesItem({
  productService,
  donation,
  setDonation,
  theme,
  isDebug,
}) {
  const {
    ps_kind,
    description,
    // ps_avatar,
    name,
    value,
    quantity,
    id,
  } = productService;

  const { isMobile } = useContext(ResponsiveContext);

  async function DeleteProductOrService() {
    const params = {
      operation: ['Donation', 'ProductServices', 'Delete'],
      urlParams: {
        donationId: donation.id,
        productServiceId: id,
      },
    };

    if (isDebug) console.log('📦 Params: ', params);

    const response = await SocialMeAPI(params);

    if (isDebug)
      console.log(
        '🚀 API Response, Donation ProductServices Delete: ',
        response
      );

    if (response.success) {
      if (isDebug) console.log('Produto or services was deleted successfully');

      setDonation({
        ...donation,
        product_services: donation.product_services.filter(
          (item) => item.id !== id
        ),
      });
    } else if (isDebug) {
      console.log('Produto or services was not deleted');
    }
  }

  return (
    <Container
      borderColor={theme.color.borderColor}
      color={theme.color.textOnPrimaryBackground}
    >
      <ListItem width="100%">
        {/* <Avatar src={ps_avatar} size="50px" /> */}
        <TextContent>
          <Text strong style={{ textAlign: 'left' }}>
            {`${ps_kind === 'PRODUCT' ? 'Produto:' : 'Serviço:'} ${name}`}
          </Text>
          <Text style={{ textAlign: 'left' }}>{description}</Text>
        </TextContent>
      </ListItem>

      <ListItem width={isMobile ? '100%' : '50%'} column align="center">
        <Text>{`Valor da Unidade: ${WalletFormatConversion(value)}`}</Text>
        <Text>{`Quantidade: ${quantity}`}</Text>
      </ListItem>

      <ListItem align="center" width={isMobile ? '100%' : '50%'}>
        <Drawer
          title={`Editando ${ps_kind === 'PRODUCT' ? 'Produto' : 'Serviço'} `}
          width={isMobile ? '100%' : 600}
          placement="right"
          Trigger={<Button>Editar</Button>}
          DrawerContentComponent={EditProductOrService}
          DrawerContentComponentParams={{
            donation,
            setDonation,
            productService,

            isDebug,
          }}
        />
        <DeleteIcon size={32} onClick={DeleteProductOrService} />
      </ListItem>
    </Container>
  );
}
