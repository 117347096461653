import React from 'react';

import { Row } from 'antd';

import Item from './components/Item';

export default function SystemProfitChart({ theme }) {
  return (
    <Row>
      <Item theme={theme} system="Doações.me" amount="R$17.000,00" />
    </Row>
  );
}
