import React, { useState } from 'react';
//Libraries
import { Form } from 'antd';
//Own Components
import CheckboxGroup from './components/CheckboxGroup';
//Json
import permissionGroups from '../../../../../../../../../permissionGroups.json';

export default function PermissionsForm({ form, foundPerson }) {
  const [values, setValues] = useState('');
  return (
    <div>
      <Form
        labelCol={{ xs: { span: 24 }, sm: { span: 24 } }}
        wrapperCol={{ xs: { span: 24 }, sm: { span: 24 } }}
        form={form}
        layout="vertical"
        size="small"
        scrollToFirstError
        onValuesChange={() => setValues(form.getFieldsValue())}
        initialValues={foundPerson.permissions}
      >
        <CheckboxGroup
          permissionGroups={permissionGroups}
          form={form}
          values={values}
        />
      </Form>
    </div>
  );
}
