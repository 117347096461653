import styled from 'styled-components';

import { CaretDownOutlined } from '@ant-design/icons';

export const DropdownContainer = styled.div`
  position: relative;
  display: flex;
  margin: 0 5px 5px 0;
`;

export const Dropdown = styled.div`
  text-align: center;
  padding: 5px 10px;
  border-radius: 8px;
  border: 1px solid ${(props) => props.borderColor};
  transition: all 0.3s;
  cursor: pointer;

  &:focus,
  &:hover {
    outline: none;
    color: ${(props) => props.primary};
    border: 1px solid ${(props) => props.primary};
  }

  &:hover svg {
    color: ${(props) => props.primary};
  }
`;

export const ItemContainer = styled.div`
  position: absolute;
  margin-top: 35px;
  z-index: 1;
  background-color: ${(props) => props.theme.color.white};
  border: 1px solid ${(props) => props.theme.color.borderColor};
  visibility: hidden;
`;

export const CaretIcon = styled(CaretDownOutlined)`
  margin: 5px 0 0 5px;
`;
