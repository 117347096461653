import React, { useContext } from 'react';
// Libraries
import { message, Popconfirm } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
// Contexts
import { AxiosContext } from '~/contexts/AxiosContext';
import { ThemeContext } from '~/contexts/ThemeContext';

export default function Actions({ place, address, GetAddressesAgain }) {
  const { CallToTheAPI } = useContext(AxiosContext);
  const { theme } = useContext(ThemeContext);

  async function DeleteAddress() {
    const params = {
      operation: ['Places', 'LinkedAddresses', 'Delete'],
      urlParams: {
        placeId: place.id,
        addressId: address.id,
      },
    };
    const response = await CallToTheAPI(params);
    if (response.success) {
      GetAddressesAgain();
      return message.success('Endereço Deletado!');
    }
    return message.error('Alguma coisa aconteceu...');
  }

  return (
    <Popconfirm
      placement="top"
      title="Deseja deletar esse endereço?"
      onConfirm={() => DeleteAddress()}
      okText="Sim, delete agora"
      cancelText="Não"
    >
      <DeleteOutlined style={{ color: theme.color.danger }} />
      {/* 
      <Button
        type="secondary"
        shape="square"
        icon={<DeleteOutlined style={{ color: theme.color.danger }} />}
      />
      */}
    </Popconfirm>
  );
}
