import React, { memo, useRef, useContext, useEffect, useState } from 'react';

import { ThemeContext } from '~/contexts/ThemeContext';

import { Container, OptionsContainer } from './style';

import Colors from './components/Colors';
import Textarea from './components/Textarea';
import Variables from './components/Variables';
import CopyCut from './components/CopyCut';
import Fonts from './components/FontAndTitle';
import List from './components/List';
import Spacement from './components/Spacement';
import TextModifier from './components/TextModifier';
import Emojis from './components/Emojis';
import Reset from './components/Reset';
import ConfigLink from './components/Link';
import Files from './components/Files';
import GenerateTable from './components/GenerateTable';
import EditMode from './components/EditMode';
import Documents from './components/Documents';

import './style.css';

// const EditorComponents = {
//   component: {},
// };

function TextEditor({
  emojis,
  defaultValue,
  variables,
  setValue,
  documents,
  onChangeMention,
}) {
  const [mainEditor, setMainEditor] = useState();
  const [editMode, setEditMode] = useState(true);

  const { theme } = useContext(ThemeContext);

  const editorRef = useRef();

  function execCommand(command) {
    document.execCommand(command, false, null);
    setValue(editorRef.current.innerHTML);
  }

  function execCommandWithArgs(command, value) {
    document.execCommand(command, false, value);
    setValue(editorRef.current.innerHTML);
  }

  useEffect(() => {
    setMainEditor(editorRef.current);

    editorRef.current.focus();
  }, []);

  return (
    <Container>
      <OptionsContainer>
        <TextModifier execCommand={execCommand} />
        <CopyCut execCommand={execCommand} />
        <List execCommand={execCommand} />
        <Spacement execCommand={execCommand} />

        <ConfigLink
          setValue={setValue}
          editorRef={editorRef}
          editMode={editMode}
          mainEditor={mainEditor}
          execCommand={execCommand}
        />
        <Files
          setValue={setValue}
          editorRef={editorRef}
          editMode={editMode}
          mainEditor={mainEditor}
        />
        <Fonts
          theme={theme}
          execCommand={execCommand}
          execCommandWithArgs={execCommandWithArgs}
        />
        <Reset editorRef={editorRef} />
        <GenerateTable
          setValue={setValue}
          theme={theme}
          setMainEditor={setMainEditor}
          execCommand={execCommand}
          editorRef={editorRef}
          editMode={editMode}
        />

        <EditMode
          editMode={editMode}
          setEditMode={setEditMode}
          editorRef={editorRef}
        />

        {variables && (
          <Variables
            variables={variables}
            setValue={setValue}
            editorRef={editorRef}
            theme={theme}
            mainEditor={mainEditor}
          />
        )}

        {documents && (
          <Documents
            documents={documents}
            theme={theme}
            editorRef={editorRef}
          />
        )}

        <Colors execCommandWithArgs={execCommandWithArgs} />

        {emojis && (
          <Emojis
            mainEditor={mainEditor}
            setValue={setValue}
            editorRef={editorRef}
          />
        )}
      </OptionsContainer>

      <Textarea
        editorRef={editorRef}
        mainEditor={mainEditor}
        editMode={editMode}
        setMainEditor={setMainEditor}
        value={defaultValue}
        setValue={setValue}
        onChangeMention={onChangeMention}
        theme={theme}
      />
    </Container>
  );
}

export default memo(TextEditor);
