import React, { memo, useContext } from 'react';

import { BsFillTrashFill, BsEye } from 'react-icons/bs';

import Text from '~/components/typography/text';

import { ThemeContext } from '~/contexts/ThemeContext';

import { Item, Image } from './style';

function MainFilesGroups({ files, size, onDelete }) {
  const { theme } = useContext(ThemeContext);

  function openFileInANewTab(file) {
    window.open(file, '_blank');
  }

  function getFileExtension(file) {
    const seperateFileString = file.split('.');

    const stringSize = seperateFileString.length - 1;

    const extension = seperateFileString[stringSize];

    return extension.toUpperCase();
  }

  function VerifyIfTheFileIsAImage(fileExtension) {
    return ['PNG', 'JPG', 'JPEG'].some(
      (extension) => extension === fileExtension
    );
  }

  return (
    <>
      {files.map((file) => {
        const extension = getFileExtension(file);

        const hasImage = VerifyIfTheFileIsAImage(extension);

        return (
          <Item
            key={file}
            bordercolor={theme.color.borderColor}
            size={size || 120}
            backgroundColor={theme.color.backgroundSecondary}
            iconColor={theme.color.white}
            iconHovercolor={theme.color.green}
            hasOnDelete={onDelete}
            border={hasImage ? 'none' : `2px solid ${theme.color.borderColor}`}
          >
            {onDelete && <BsFillTrashFill onClick={() => onDelete(file)} />}

            <BsEye onClick={() => openFileInANewTab(file)} />

            {hasImage ? (
              <Image size={size || 120} src={file} />
            ) : (
              <Text strong style={{ fontSize: 32, letterSpacing: 5 }}>
                {extension}
              </Text>
            )}
          </Item>
        );
      })}
    </>
  );
}

export default memo(MainFilesGroups);
