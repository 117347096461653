import React, { memo } from 'react';

import { FaListUl, FaListOl } from 'react-icons/fa';

import Button from '~/components/button';

function List({ execCommand }) {
  return (
    <>
      <Button
        style={{ margin: '0 5px 5px 0' }}
        onClick={() => execCommand('insertUnorderedList')}
        icon={<FaListUl />}
      />
      <Button
        style={{ margin: '0 5px 5px 0' }}
        onClick={() => execCommand('insertOrderedList')}
        icon={<FaListOl />}
      />
    </>
  );
}

export default memo(List);
