import styled from 'styled-components';

export const Container = styled.div``;
export const LoadContainer = styled.div`
  /* height: calc(100vh - 125px); */
  height: ${(props) => props.height || '300px'};
  display: flex;
  justify-content: center;
  align-items: center;
`;
