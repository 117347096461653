import React, { useState } from 'react';
import Chart from 'react-apexcharts';

import Card from '~/components/card';

import { Container } from './style';

export default function MontlyProfitChart({ theme }) {
  const [chart] = useState({
    options: {
      chart: {
        id: 'basic-bar',
      },
      colors: [theme.color.primary],
      tooltip: {
        enabled: false,
      },
      xaxis: {
        style: {
          fontSize: '20px',

          fontWeight: 700,
        },
        categories: [
          'Janeiro',
          'Feverreiro',
          'Março',
          'Abril',
          'Maio',
          'Junho',
          'Julho',
          'Agosto',
          'Setembro',
          'Outubro',
          'Novembro',
          'Dezembro',
        ],
      },
    },
    dataLabels: {
      formatter: function (value, options) {
        console.log(`value`, value);
        console.log(`options`, options);
        return value;
      },
      textAnchor: 'middle',
    },
    series: [
      {
        name: 'Lucro',
        data: [
          '10%',
          '18%',
          '19%',
          '23%',
          '29%',
          '31%',
          '25%',
          '22%',
          '28%',
          '29%',
          '33%',
          '28%',
        ],
      },
    ],
  });

  return (
    <Container>
      <Card>
        <Chart
          options={chart.options}
          series={chart.series}
          type="bar"
          width="1000"
          height="400"
        />
      </Card>
    </Container>
  );
}
