import React, { memo, useState, useEffect } from 'react';
import { position } from 'caret-pos';

import Mentions from '../Mentions';

import { Textarea } from './style';

function EditorTextarea({
  editorRef,
  mainEditor,
  value,
  setValue,
  setMainEditor,
  editMode,
  theme,
  onChangeMention,
}) {
  const [caretPosition, setCaretPosition] = useState({ left: 0, top: 0 });

  useEffect(() => {
    if (value) {
      const textEditor = document.getElementById('text-editor');

      textEditor.innerHTML = value;
    }
  }, [value]);

  function ChangeMentionCSS(opacity, display) {
    const mention = document.getElementById('editor-mention');

    mention.style.opacity = opacity;
    mention.style.display = display;
  }

  function SetPositionInMainEditor() {
    const { left, top } = position(mainEditor);


    if (mainEditor === editorRef.current) {
      setCaretPosition({
        left: Math.round(left),
        top: top - window.scrollY,
      });
    }
  }

  function OpenMentionsAfterPressArrobaKey(event) {
    if (event.key === '@') {
      SetPositionInMainEditor();

      ChangeMentionCSS(1, 'block');
    } else if (event.key !== 'Shift' && event.key !== '2') {
      ChangeMentionCSS(0, 'none');
    }

    setValue(event.target.innerHTML);
  }

  const mentionOptions = [
    {
      value: 'Jonh doe',
      label: 'Jonh doe',
    },
    {
      value: 'Mary leaf',
      label: 'Mary leaf',
    },
    {
      value: 'Cage donsen',
      label: 'Cage donsen',
    },
    {
      value: 'Sarah dwasen',
      label: 'Sarah dwasen',
    },
    {
      value: 'James smith',
      label: 'James smith',
    },
    {
      value: 'Roger cabegem',
      label: 'Roger cabegem',
    },
  ];

  return (
    <>
      <Textarea
        id="text-editor"
        ref={editorRef}
        onClick={(event) => {
          if (event.target === editorRef.current) {
            setMainEditor(event.target);
          }
        }}
        onKeyUp={(event) => OpenMentionsAfterPressArrobaKey(event)}
        borderColor={theme.color.borderColor}
        borderColorOnFocus={theme.color.primary}
        contentEditable={editMode}
      />
      <Mentions
        caretPosition={caretPosition}
        ChangeMentionCSS={ChangeMentionCSS}
        theme={theme}
        editorRef={editorRef}
        options={mentionOptions}
        onChange={onChangeMention}
      />
    </>
  );
}

export default memo(EditorTextarea);
