import styled from 'styled-components';

export const Content = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
`;

export const Image = styled.img`
  display: flex;
  align-items: center;
  object-fit: cover;
  height: ${(props) => `${props.size}px`};
  width: ${(props) => `${props.size}px`};
  justify-content: center;
  border-radius: 4px;
  background-color: #fafafa;
  cursor: pointer;
`;

export const Item = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${(props) => `${props.size}px`};
  width: ${(props) => `${props.size}px`};
  border-radius: 4px;
  background-color: ${(props) => props.backgroundColor};
  border: ${(props) => props.border};
  margin: 0 5px 5px 0;
  cursor: pointer;

  &::after {
    content: '';
    transition: all 0.4s;
    border-radius: 4px;
    height: ${(props) => `${props.size}px`};
    width: ${(props) => `${props.size}px`};
    background-color: rgba(0, 0, 0, 0);
    top: 0;
    left: 0;
    position: absolute;
  }

  & svg {
    position: absolute;
    cursor: pointer;
    color: ${(props) => props.iconColor};
    font-size: 22px;
    z-index: 1;
    display: none;
    transition: all 0.4s;

    &:hover {
      color: ${(props) => `${props.iconHovercolor} !important`};
    }
  }

  & svg:nth-child(1) {
    top: ${(props) => `${props.size / 2 - 10}px`};
    left: ${(props) => {
      if (props.hasOnDelete) {
        return `${props.size / 2 + 5}px`;
      }
      return `${props.size / 2 - 10}px`;
    }};
  }

  & svg:nth-child(2) {
    top: ${(props) => `${props.size / 2 - 10}px`};
    left: ${(props) => {
      if (props.hasOnDelete) {
        return `${props.size / 2 - 25}px`;
      }
      return `${props.size / 2}px`;
    }};
  }

  &:hover svg {
    display: block;
  }

  &:hover:after {
    background-color: rgba(0, 0, 0, 0.6);
  }
`;
