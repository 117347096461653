import React, { useContext } from 'react';
//Contexts
import { PermissionContext } from '~/contexts/PermissionContext';
//Own Components
import Actions from './components/Actions';
//Components
import { BadgeCell, ImageWithTextCell } from '~/components/table/cells';
import Table from '~/components/table';
import Pagination from '~/components/pagination';
import Text from '~/components/typography/text';

export default function Content({ data, GetDataAgain, pageParameters }) {
  const { CheckPermission } = useContext(PermissionContext);

  const tableColumns = [
    {
      title: 'Tipo de Atividade',
      dataIndex: 'name',
      key: 'name',
      render: (name, record) => (
        <ImageWithTextCell image={record.avatar} text={name} />
      ),
    },
    {
      title: 'Descrição',
      dataIndex: 'description',
      key: 'description',
      render: (description) => (
        <Text type="secondary">{description || ''}</Text>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status) => (
        <BadgeCell
          text={status === 'ACTIVE' ? 'Ativo' : 'Inativo'}
          status={status === 'ACTIVE' ? 'success' : 'error'}
        />
      ),
    },
  ];

  if (
    CheckPermission('UPDATE-ACTIVITY-TYPE') ||
    CheckPermission('DELETE-ACTIVITY-TYPE')
  ) {
    tableColumns.push({
      title: 'Ações',
      key: 'action',
      render: (data) => <Actions GetDataAgain={GetDataAgain} data={data} />,
    });
  }

  return (
    <div>
      <Table tableColumns={tableColumns} dataReceived={data} />
      <Pagination
        onChange={(currentPage, limit) => GetDataAgain({ currentPage, limit })}
        onShowSizeChange={(currentPage, limit) =>
          GetDataAgain({ currentPage, limit })
        }
        total={pageParameters.totalElements}
        showSizeChanger
        showQuickJumper
        showTotal={(total) => `Total ${total} items`}
      />
    </div>
  );
}
