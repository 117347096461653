import React, { createContext, useEffect, useContext, useState } from 'react';
//Contexts
import { DebugContext } from './DebugContext';
import { AxiosContext } from './AxiosContext';
//Environments
import System from '~/environments/System';
//Utils
import Cookie from '~/utils/Cookie';

export const BrowserContext = createContext();

const BrowserContextProvider = (props) => {
  const { CallToTheAPI } = useContext(AxiosContext);
  const { debug } = useContext(DebugContext);
  let isDebug = debug.Context.Browser;

  const [endedBrowserLogic, setEndedBrowserLogic] = useState(false);

  useEffect(() => {
    async function SetNewBrowserToken() {
      if (isDebug) console.log('🚀 API: Set new browser token');
      const params = {
        operation: ['Auth', 'BrowserToken', 'Set'],
      };
      const response = await CallToTheAPI(params);
      if (isDebug) console.log('  ⚡ API Response: ', response);
      if (response.success) {
        if (isDebug) console.log('🍪 Creating the Browser Cookie');
        let creatingTheCookie = await Cookie.Create({
          name: System.BrowserCookieName,
          value: response.body.brwsrtkn,
        });
        if (creatingTheCookie) {
          if (isDebug) console.log('  ✔ Browser cookie successfully created');
        } else {
          if (isDebug) console.log('  ❗ Error creating Browser Cookie');
        }
        return setEndedBrowserLogic(true);
      }
    }
    async function CheckBrowserToken() {
      if (isDebug) console.log('🚀 API: Check browser token');
      const params = {
        operation: ['Auth', 'BrowserToken', 'Check'],
      };
      const response = await CallToTheAPI(params);
      if (isDebug) console.log('  ⚡ API Response: ', response);
      if (response.success) {
        if (isDebug) console.log('    ✔ Valid browser token');
        return setEndedBrowserLogic(true);
      } else {
        if (isDebug) console.log('    ❌ Invalid browser token');
        SetNewBrowserToken();
      }
    }
    async function VerifyBrowserToken() {
      if (isDebug) console.log('🔍 Searching for browser token');
      const BrowserTokenCookies = await Cookie.Get(System.BrowserCookieName);
      if (BrowserTokenCookies) {
        if (isDebug) console.log('  ✔ Browser token found');
        CheckBrowserToken();
      } else {
        if (isDebug) console.log('  ❌ Browser token not found');
        SetNewBrowserToken();
      }
    }
    VerifyBrowserToken();
  }, [CallToTheAPI, isDebug]);

  return (
    <BrowserContext.Provider value={{ endedBrowserLogic }}>
      {props.children}
    </BrowserContext.Provider>
  );
};

export default BrowserContextProvider;
