import React, { memo } from 'react';

import { FaFileImage } from 'react-icons/fa';

import Button from '~/components/button';

function Files({ setValue, mainEditor, editorRef }) {
  function InsertImages() {
    const src = prompt('Informe o caminho da imagem: ', '');
    const width = prompt('Largura', '');
    const height = prompt('Altura', '');

    mainEditor.ownerDocument.execCommand(
      'insertHTML',
      false,
      `<img  src=${src} alt="Imagem" height=${
        height ? Number(height) : 50
      } width=${width ? Number(width) : 50} />`
    );

    setValue(editorRef.current.innerHTML);
  }

  return (
    <Button
      style={{ margin: '0 5px 5px 0' }}
      onClick={() => InsertImages()}
      icon={<FaFileImage />}
    />
  );
}

export default memo(Files);
