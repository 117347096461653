import React, { useContext } from 'react';

import { ThemeContext } from '~/contexts/ThemeContext';
import { ResponsiveContext } from '~/contexts/ResponsiveContext';

import SystemProfitChart from './components/SystemProfitChart';
import MontlyProfitChart from './components/MontlyProfitChart';
import CashCharts from './components/CashCards';

import { Container } from './style';

export default function FinancialReport() {
  const { theme } = useContext(ThemeContext);
  const { isMobile } = useContext(ResponsiveContext);

  return (
    <Container
      className="custom-scroll"
      backgroundColor={theme.color.backgroundSecondary}
    >
      <CashCharts isMobile={isMobile} theme={theme} />
      <MontlyProfitChart theme={theme} />
      <SystemProfitChart theme={theme} />
    </Container>
  );
}
