import React, { useContext } from 'react';
//Contexts
import { PermissionContext } from '~/contexts/PermissionContext';
//Own Components
import Actions from './components/Actions';
//Components
import {
  PrimaryAndSecondaryTextCell,
  BadgeCell,
} from '~/components/table/cells';
import Table from '~/components/table';
import Pagination from '~/components/pagination';
//Utils
import { WalletFormatConversion } from '~/utils/GeneralFunctions';

export default function Content({ data, GetDataAgain, pageParameters }) {
  const { CheckPermission } = useContext(PermissionContext);

  const tableColumns = [
    {
      title: 'Produto',
      dataIndex: 'name',
      key: 'name',
      render: (name, record) => (
        <PrimaryAndSecondaryTextCell
          primaryText={name || 'sem nome'}
          secondaryText={`NCM: ${record.ncm ? record.ncm : 'sem ncm'}`}
        />
      ),
    },
    {
      title: 'Preço base',
      dataIndex: 'value',
      key: 'value',
      render: (value) => WalletFormatConversion(value),
    },
    {
      title: 'Categoria',
      dataIndex: 'category',
      key: 'category',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status) => (
        <BadgeCell
          text={status === 'ACTIVE' ? 'Ativo' : 'Inativo'}
          status={status === 'ACTIVE' ? 'success' : 'error'}
        />
      ),
    },
  ];

  if (
    CheckPermission('UPDATE-PRODUCT-SERVICE-MODEL') ||
    CheckPermission('DELETE-PRODUCT-SERVICE-MODEL')
  ) {
    tableColumns.push({
      title: 'Ações',
      key: 'action',
      render: (data) => <Actions GetDataAgain={GetDataAgain} data={data} />,
    });
  }

  return (
    <div>
      <Table tableColumns={tableColumns} dataReceived={data} />
      <Pagination
        onChange={(currentPage, limit) => GetDataAgain({ currentPage, limit })}
        onShowSizeChange={(currentPage, limit) =>
          GetDataAgain({ currentPage, limit })
        }
        total={pageParameters.totalElements}
        showSizeChanger
        showQuickJumper
        showTotal={(total) => `Total ${total} itens`}
      />
    </div>
  );
}
