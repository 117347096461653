import React, { memo } from 'react';

import { ButtonDropdown } from '~/components/dropdown';

function Fonts({ execCommandWithArgs }) {
  const colors = [
    'rgba(128,0,0)',
    'rgba(139,0,0)',
    'rgba(165,42,42)',
    'rgba(178,34,34)',
    'rgba(220,20,60)',
    'rgba(255,0,0)',
    'rgb(255,69,0)',
    'rgba(255,140,0)',
    'rgba(255,165,0)',
    'rgba(255,215,0)',
    'rgb(184,134,11)',
    'rgb(218,165,32)',
    'rgb(255,255,0)',
    'rgb(154,205,50)',
    'rgb(85,107,47)',
    'rgb(107,142,35)',
    'rgb(124,252,0)',
    'rgb(127,255,0)',
    'rgb(173,255,47)',
    'rgb(0,100,0)',
    'rgb(0,128,0)',
    'rgb(0,250,154)',
    'rgb(0,128,128)',
    'rgb(0,139,139)',
    'rgb(0,255,255)',
    'rgb(0,206,209)',
    'rgb(72,209,204)',
    'rgb(0,191,255)',
    'rgb(30,144,255)',
    'rgb(100,149,237)',
    'rgb(70,130,180)',
    'rgb(0,0,139)',
    'rgb(0,0,255)',

    'rgb(135,206,250)',
    'rgb(173,216,230)',
    'rgb(65,105,225)',
    'rgb(0,0,0)',
    'rgb(105,105,105)',
    'rgb(128,128,128)',
    'rgb(169,169,169)',
    'rgb(192,192,192)',
    'rgb(211,211,211)',
    'rgb(220,220,220)',
    'rgb(245,245,245)',
    'rgb(255,255,255)',
  ];

  function SetFontColor(value) {
    execCommandWithArgs('foreColor', value);
  }

  function SetBackgroundColor(value) {
    execCommandWithArgs('hiliteColor', value);
  }

  const foreColorContent = (
    <div className="colors-container">
      {colors.map((background) => (
        <button
          key={background}
          onClick={() => SetFontColor(background)}
          style={{ background }}
        />
      ))}
    </div>
  );

  const hiliteColorContent = (
    <div className="colors-container">
      {colors.map((background) => (
        <button
          key={background}
          onClick={() => SetBackgroundColor(background)}
          style={{ background }}
        />
      ))}
    </div>
  );

  return (
    <>
      <ButtonDropdown
        dropdownId="foreColor"
        menuTitle="Cor de letra"
        content={foreColorContent}
      />
      <ButtonDropdown
        dropdownId="hiliteColor"
        menuTitle="Cor de fundo"
        content={hiliteColorContent}
      />
    </>
  );
}

export default memo(Fonts);
