import React from 'react';
//Components
import FormItem from '~/components/form/FormItem';
import Input from '~/components/input';
import TextArea from '~/components/textArea';
import Select from '~/components/select';
import Checkbox from '~/components/checkbox';
import DynamicTags from '~/components/tags/dynamicTags';

export default function AddFormItems({ tags, setTags, akas, setAkas }) {
  const KindOptions = [
    {
      value: 'PRODUCT',
      label: 'Produto',
    },
    {
      value: 'SERVICE',
      label: 'Serviço',
    },
  ];

  return (
    <div>
      <FormItem
        label="Nome"
        name={['name']}
        rules={[
          {
            required: true,
            message: 'Um nome é obrigatório',
          },
        ]}
        item={<Input />}
      />
      <FormItem
        label="Categoria"
        name={['category']}
        rules={[
          {
            required: true,
            message: 'Uma categoria é obrigatória',
          },
        ]}
        item={<Input />}
      />
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <FormItem label="SPED" name={['sped']} item={<Input width="100px" />} />
        <FormItem
          label="Preço base"
          name={['value']}
          rules={[
            {
              required: true,
              message: 'Um preço é obrigatório',
            },
          ]}
          item={<Input width="100px" prefix="R$" />}
        />
        <FormItem
          label="Tipo"
          name={['kind']}
          item={<Select disabled options={KindOptions} />}
        />
        <FormItem
          label="Status"
          name="status"
          valuePropName="checked"
          item={<Checkbox text={'Ativo'} />}
        />
      </div>
      <FormItem label="Descrição" name={['desc']} item={<TextArea />} />
      <FormItem label="Avatar" name={['avatar']} item={<Input />} />

      <FormItem
        label="Tags"
        name={['tags']}
        item={<DynamicTags tags={tags} setTags={setTags} />}
      />

      <FormItem
        label="AKAS"
        name={['akas']}
        item={<DynamicTags tags={akas} setTags={setAkas} />}
      />
    </div>
  );
}
