import React from 'react';

import { PlusOutlined } from '@ant-design/icons';

import { Container, Div } from './style';

import Title from '~/components/typography/title';
import Search from '~/components/search';
import Button from '~/components/button';

export default function Header({ search, setSearch }) {
  return (
    <Container>
      <Title level={4}>Instituições - SIGAM</Title>
      <Div>
        <Search value={search} onChange={(value) => setSearch(value)} />
        <Button type="primary" icon={<PlusOutlined />}>
          Nova instituição
        </Button>
      </Div>
    </Container>
  );
}
