import React from 'react';

import Text from '~/components/typography/text';
import Avatar from '~/components/avatar';

import { CapitalizeString } from '~/utils/Transformations';

import { Container, TextContent } from './style';

export default function BasicInfo({ person }) {
  return (
    <Container>
      <Avatar style={{ marginRight: 15 }} size={50} src={person.avatar} />
      <TextContent>
        <Text>{CapitalizeString(person.name)}</Text>
        <Text kind="secondary">{person.main_contact.toLowerCase()}</Text>
      </TextContent>
    </Container>
  );
}
