import React from 'react';

import Title from '~/components/typography/title';
import Text from '~/components/typography/text';
import Card from '~/components/card';
import { Image } from '~/components/image';

import { Box } from './style';

export default function Item({ theme, system, amount }) {
  const styles = {
    title: {
      margin: 0,
      color: theme.color.primary,
    },
    icon: {
      backgroundColor: theme.color.primary,
      borderRadius: 8,
      padding: 5,
      fontSize: 50,
      marginRight: 10,
      color: theme.color.white,
    },
    column: {
      padding: '0 10px',
    },
    row: {
      alignItems: 'center',
      justifyContent: 'space-around',
    },
  };

  return (
    <Card style={styles.card}>
      <Box>
        <Image
          src="https://socialme-assets.s3.us-east-1.amazonaws.com/images/brands/socialMe.png"
          width="120px"
          height="120px"
        />
        <Text style={{ fontSize: 18 }} kind="secondary">
          {system}
        </Text>
        <Title style={styles.title} level={3}>
          {amount}
        </Title>
      </Box>
    </Card>
  );
}
