import React, { useContext, memo } from 'react';
//Contexts
import { ThemeContext } from '~/contexts/ThemeContext';
// Libraries
import { NavLink } from 'react-router-dom';
import { Badge, Tag, Avatar } from 'antd';
//Components
import Text from '~/components/typography/text';
//Utils
import { CapitalizeFirstLetter } from '~/utils/GeneralFunctions';

function People(record) {
    const { theme } = useContext(ThemeContext);
    const people = record.people;
    return (
        <div style={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
        }}>
            <div>
                {people.avatar ?
                    <Avatar style={{ marginRight: 10 }} src={people.avatar} />
                    :
                    <Avatar
                        style={{
                            color: theme.color.textOnPrimaryBackground,
                            backgroundColor: theme.color.primary,
                            marginRight: 10,
                        }}
                    >
                        {people.social_name ? people.social_name.charAt(0) : people.name.charAt(0)}
                    </Avatar>
                }
            </div>
            <div>
                <Text strong>{people.social_name ? CapitalizeFirstLetter(people.social_name) : CapitalizeFirstLetter(people.name)}</Text>
            </div>
        </div>
    )
}
export const PeopleCell = memo(
    People
);

function ImageWithPrimaryAndSecondaryText({
    primaryText,
    secondaryText,
    image,
    paddingLeft,
}) {
    const { theme } = useContext(ThemeContext);
    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
            }}
        >
            {image ? (
                <img
                    style={{ height: 32, marginRight: 10, paddingLeft }}
                    src={image}
                    alt="..."
                />
            ) : (
                    <Avatar
                        style={{
                            color: theme.color.textOnPrimaryBackground,
                            backgroundColor: theme.color.primary,
                            marginRight: 10,
                        }}
                    >
                        {primaryText ? primaryText.charAt(0) : ''}
                    </Avatar>
                )}

            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Text strong>{primaryText}</Text>
                <Text type="secondary">{secondaryText}</Text>
            </div>
        </div>
    );
}
export const ImageWithPrimaryAndSecondaryTextCell = memo(
    ImageWithPrimaryAndSecondaryText
);

function PrimaryAndSecondaryText({ primaryText, secondaryText }) {
    return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Text strong>{primaryText}</Text>
            <Text type="secondary">{secondaryText}</Text>
        </div>
    );
}
export const PrimaryAndSecondaryTextCell = memo(PrimaryAndSecondaryText);

function TextLink({ text, url }) {
    const { theme } = useContext(ThemeContext);
    return (
        <div style={{ display: 'flex' }}>
            <NavLink to={url || '#'}>
                <Text strong color={theme.color.primary}>
                    {text}
                </Text>
            </NavLink>
        </div>
    );
}
export const TextLinkCell = memo(TextLink);

function Tags({ tags, tag, color, fieldName, random }) {
    const randomTags = ['tag', 'tag'];
    const colors = [
        'magenta',
        'red',
        'volcano',
        'orange',
        'gold',
        'lime',
        'green',
        'cyan',
        'blue',
        'geekblue',
        'purple',
    ];
    if (random) {
        return (
            <div>
                {randomTags.map((item, index) => {
                    const tagColor =
                        color || colors[Math.floor(Math.random() * colors.length)];
                    return (
                        <Tag color={tagColor} key={index} style={{ marginBottom: 5 }}>
                            {item.toUpperCase()}-{tagColor.toUpperCase()}
                        </Tag>
                    );
                })}
            </div>
        );
    }
    if (tags) {
        return (
            <div>
                {tags.map((item) => {
                    const tagColor =
                        color || colors[Math.floor(Math.random() * colors.length)];
                    return (
                        <Tag color={tagColor} key={item._id}>
                            {item[fieldName].toUpperCase()}
                        </Tag>
                    );
                })}
            </div>
        );
    }
    if (tag) {
        return (
            <div>
                <Tag color={color || colors[Math.floor(Math.random() * colors.length)]}>
                    {tag}
                </Tag>
            </div>
        );
    }
}
export const TagsCell = memo(Tags);

function BadgeContainer({ text, status }) {
    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <Badge status={status} text={text} />
        </div>
    );
}
export const BadgeCell = memo(BadgeContainer);

function ImageWithText({ text, image, paddingLeft }) {
    const { theme } = useContext(ThemeContext);
    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
            }}
        >
            {image ? (
                <img
                    style={{ height: 32, marginRight: 10, paddingLeft }}
                    src={image}
                    alt="..."
                />
            ) : (
                    <Avatar
                        style={{
                            color: theme.color.textOnPrimaryBackground,
                            backgroundColor: theme.color.primary,
                            marginRight: 10,
                        }}
                    >
                        {text ? text.charAt(0) : ''}
                    </Avatar>
                )}

            <Text>{text}</Text>
        </div>
    );
}
export const ImageWithTextCell = memo(ImageWithText);
