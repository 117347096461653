import React from 'react';

import Title from '~/components/typography/title';
import Text from '~/components/typography/text';
import Card from '~/components/card';

import { Box, Div } from './style';

export default function Item({ theme, title, Icon, amount }) {
  const styles = {
    title: {
      margin: 0,
      color: theme.color.primary,
    },
    icon: {
      backgroundColor: theme.color.primary,
      borderRadius: 8,
      padding: 5,
      fontSize: 50,
      marginRight: 10,
      color: theme.color.white,
    },
  };

  return (
    <Card>
      <Box>
        <Icon style={styles.icon} />
        <Div>
          <Text kind="secondary">{title}</Text>
          <Title style={styles.title} level={3}>
            {amount}
          </Title>
        </Div>
      </Box>
    </Card>
  );
}
