import React, { useState, createContext, useEffect, useContext } from 'react';
//Contexts
import { DebugContext } from './DebugContext';
//Environments
import { LightTheme, DarkTheme } from '~/environments/Themes';
import System from '~/environments/System';
//Utils
import Cookie from '~/utils/Cookie';

export const ThemeContext = createContext();

const ThemeContextProvider = (props) => {
  const { debug } = useContext(DebugContext);
  let isDebug = debug.Context.Theme;

  const [theme, setTheme] = useState();
  const [activeTheme, setActiveTheme] = useState('LightTheme');

  useEffect(() => {
    async function VerifyCookie() {
      const UserThemeCookie = await Cookie.Get(System.ThemeCookieName);
      if (UserThemeCookie) {
        if (UserThemeCookie === 'Light') {
          return setActiveTheme('LightTheme');
        }
        if (UserThemeCookie === 'Dark') {
          return setActiveTheme('DarkTheme');
        }
      }
    }
    VerifyCookie();
  }, [isDebug]);

  useEffect(() => {
    async function SetCookie() {
      let creatingTheCookie = await Cookie.Create({
        name: System.ThemeCookieName,
        value: activeTheme === 'LightTheme' ? 'Light' : 'Dark',
      });
      if (creatingTheCookie) {
        if (isDebug) console.log('  ✔ Theme cookie successfully created');
        return setTheme(activeTheme === 'LightTheme' ? LightTheme : DarkTheme);
      } else {
        if (isDebug) console.log('  ❗ Error creating Theme Cookie');
      }
    }
    SetCookie();
  }, [activeTheme, isDebug]);

  /*
  useEffect(() => {
    setTimeout(function () {
      setActiveTheme('DarkTheme');
    }, 3000);
  }, []);
  */

  const ChangeTheme = (newTheme) => {
    setActiveTheme(newTheme);
  };

  const ToggleTheme = () => {
    if (activeTheme === 'LightTheme') {
      return setActiveTheme('DarkTheme');
    } else {
      return setActiveTheme('LightTheme');
    }
  };

  return (
    <ThemeContext.Provider
      value={{ ChangeTheme, ToggleTheme, activeTheme, theme }}
    >
      {props.children}
    </ThemeContext.Provider>
  );
};

export default ThemeContextProvider;
