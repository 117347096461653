import React, { memo } from 'react';

import { message } from 'antd';

import { FaLink, FaUnlink } from 'react-icons/fa';

import Button from '~/components/button';

function Link({ execCommand, setValue, mainEditor, editorRef, editMode }) {
  function InsertLink() {
    const url = prompt('Digite a URL: ', 'https://');
    const label = prompt('Escreva o texto o link: ', '');

    if (url !== '' && label !== '') {
      mainEditor.ownerDocument.execCommand(
        'insertHTML',
        false,
        `<a href=${url} target="_blank">${label}</a>`
      );

      setValue(editorRef.current.innerHTML);
    } else {
      message.error('Informe um link e texto válidos');
    }
  }

  return (
    <>
      <Button
        style={{ margin: '0 5px 5px 0' }}
        onClick={() => InsertLink()}
        icon={<FaLink />}
      />

      <Button
        style={{ margin: '0 5px 5px 0' }}
        onClick={() => execCommand('unlink')}
        icon={<FaUnlink />}
      />
    </>
  );
}

export default memo(Link);
