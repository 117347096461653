import React, { useContext } from 'react';

import { Empty, Avatar, Tooltip } from 'antd';

import { PermissionContext } from '~/contexts/PermissionContext';

import { EditFilled, UserOutlined, AntDesignOutlined } from '@ant-design/icons';

import { ImageWithTextCell } from '~/components/table/cells';

import Table from '~/components/table';
import Button from '~/components/button';
import { ExternalLink } from '~/components/link';
import Pagination from '~/components/pagination';

export default function Content({ data, GetDataAgain, pageParameters }) {
  const { CheckPermission } = useContext(PermissionContext);

  const tableColumns = [
    {
      title: 'Ativo',
      dataIndex: 'avatar',
      key: 'avatar',
      width: '75%',
      render: (avatar, record) => (
        <ExternalLink to={`/pessoa/${record.person_id}`}>
          <ImageWithTextCell src={avatar || record} text={record.person_name} />
        </ExternalLink>
      ),
    },
    {
      title: 'Data',
      dataIndex: 'created_at',
      key: 'created_at',
      width: '15%',
      render: (created_at, record) => (
        <Avatar.Group
          maxCount={2}
          maxStyle={{ color: '#f56a00', backgroundColor: '#fde3cf' }}
        >
          <Avatar src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" />
          <Avatar style={{ backgroundColor: '#f56a00' }}>K</Avatar>
          <Tooltip title="Ant User" placement="top">
            <Avatar
              style={{ backgroundColor: '#87d068' }}
              icon={<UserOutlined />}
            />
          </Tooltip>
          <Avatar
            style={{ backgroundColor: '#1890ff' }}
            icon={<AntDesignOutlined />}
          />
        </Avatar.Group>
      ),
    },
  ];

  if (CheckPermission('JUDGE-DONATION-REQUEST')) {
    tableColumns.push({
      title: 'Ações',
      key: 'action',
      align: 'center',
      width: '10%',
      render: (data, record) => (
        <>
          <Button>Gerenciar</Button>
          <Button icon={<EditFilled />}>Editar</Button>
        </>
      ),
    });
  }

  return data.length !== 0 ? (
    <>
      <Table tableColumns={tableColumns} dataReceived={data} />
      <Pagination
        onChange={(currentPage, limit) => GetDataAgain({ currentPage, limit })}
        onShowSizeChange={(currentPage, limit) =>
          GetDataAgain({ currentPage, limit })
        }
        total={pageParameters.totalElements}
        limit={pageParameters.limit}
        currentPage={pageParameters.currentPage}
        showSizeChanger
        showQuickJumper
        showTotal={(total) => `Total ${total} itens`}
      />
    </>
  ) : (
    <Empty description="Não há nenhuma doação." />
  );
}
