import React, { useEffect, useState } from 'react';

import { Form, message } from 'antd';
import SocialMeAPI from '~/utils/SocialMeAPI';

import Button from '~/components/button';
import Loading from '~/components/loading';
import FormItem from '~/components/form/FormItem';
import { InputDynamicComplete } from '~/components/autoComplete';
import InputNumber from '~/components/inputNumber';
import Input from '~/components/input';
import Select from '~/components/select';

import ProductOrServiceOptionItem from './components/ProductOrServiceOptionItem';

import { Grid } from './style';

export default function EditProductOrService({
  CloseDrawer,
  propertys: { productService, setDonation, isDebug, donation },
}) {
  const [isProduct] = useState(productService.ps_kind === 'PRODUCT');
  const [productServices, setProductServices] = useState([]);
  const [productModelId, setProductModelId] = useState();
  const [unitysMeasure, setUnitysMeasure] = useState([]);
  const [loading, setLoading] = useState(true);

  const [form] = Form.useForm();

  let timer = null;

  function transform(options, label, value) {
    return options.map((option) => {
      return {
        label: option[label],
        value: option[value].toString(),
      };
    });
  }

  const SearchOptions = (event) => {
    const { value } = event.target;

    async function GetProductsOrServicesFromAPI(searchString) {
      const params = {
        operation: ['Donation', 'ProductServices', 'Search'],
        data: {
          pagination: {
            limit: 100,
          },
          filter: {
            searchString,
            kind: isProduct ? 'PRODUCT' : 'SERVICE',
          },
        },
      };

      if (isDebug) console.log('📦 Params: ', params);

      const response = await SocialMeAPI(params);

      if (isDebug)
        console.log(
          '🚀 API Response, Donation ProductServices Search: ',
          response
        );

      if (response.success) {
        if (isDebug) console.log('Product or service successfully');

        setProductServices(response.body.psModels.elements);
      } else if (isDebug) {
        console.log('No product or service was listed');
      }
    }

    clearTimeout(timer);

    timer = setTimeout(() => {
      GetProductsOrServicesFromAPI(value);
    }, 500);
  };

  function setProductOrServiceName(product) {
    const { name, id } = product;

    setProductModelId(id);

    form.setFieldsValue({
      name,
    });

    setProductServices([]);
  }

  useEffect(() => {
    async function GetUnitsMeasureFromAPI() {
      const params = {
        operation: ['System', 'MeasurementUnit', 'GetAll'],
      };

      if (isDebug) console.log('📦 Params: ', params);

      const response = await SocialMeAPI(params);

      if (isDebug)
        console.log(
          '🚀 API Response, System MeasurementUnit GetAll: ',
          response
        );

      if (response.success) {
        if (isDebug) console.log('Units measure was listed');

        const sequelizedMeasureUnits = transform(
          response.body.mUnities,
          'name',
          'id'
        );

        setUnitysMeasure(sequelizedMeasureUnits);
      } else if (isDebug) {
        console.log('No units measure was listed');
      }
    }

    if (isProduct) {
      if (unitysMeasure.length > 0) {
        setLoading(false);
      } else {
        setLoading(true);

        GetUnitsMeasureFromAPI();
      }
    } else {
      setLoading(false);
    }
  }, [unitysMeasure, isProduct, isDebug]);

  function GetMeasuryUnitIdByName(name) {
    const unity = unitysMeasure.filter((item) => {
      return item.value === name;
    });

    return unity[0].value;
  }

  async function EditProductOrService(values) {
    const { id, ps_avatar, ps_kind } = productService;

    const {
      personalized_name,
      valuePerUnit,
      description,
      quantity,
      unitysMeasure,
    } = values;

    const data = {
      name: personalized_name,
      description,
      value: Number(valuePerUnit * 1000000),
      quantity,
    };

    if (isProduct) {
      data.measurement_unity = GetMeasuryUnitIdByName(unitysMeasure);
    }

    if (productModelId) {
      data.product_service_model = productModelId;
    }

    const params = {
      operation: ['Donation', 'ProductServices', 'Edit'],
      urlParams: {
        donationId: donation.id,
        productServiceId: id,
      },

      data,
    };

    if (isDebug) console.log('📦 Params: ', params);

    const response = await SocialMeAPI(params);

    if (isDebug)
      console.log('🚀 API Response, Donation ProductServices Edit: ', response);

    if (response.success) {
      if (isDebug) console.log('Product os service was edited');

      setDonation({
        ...donation,
        product_services: donation.product_services.map((product_service) => {
          if (product_service.id === id) {
            return {
              id,
              ps_avatar,
              ...data,
              ps_kind,
            };
          }
          return product_service;
        }),
      });

      CloseDrawer();

      message.success(`${isProduct ? 'Produto' : 'Serviço'} editado`);
    } else if (!response.success) {
      if (isDebug) console.log('No producto os service was edited');
      message.error('Houve um erro ao editar');
    }
  }

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <Form
          form={form}
          layout="vertical"
          onFinish={EditProductOrService}
          initialValues={{
            quantity: productService.quantity,
            description: productService.description,
            valuePerUnit: productService.value / 1000000,
            personalized_name: productService.name,
            name: productService.ps_name,
            unitysMeasure: isProduct
              ? unitysMeasure[unitysMeasure.length - 3].value
              : '',
          }}
          style={{
            padding: '10px',
          }}
        >
          <FormItem
            label={`${isProduct ? 'Produto' : 'Serviço'}`}
            name="name"
            rules={[
              {
                required: true,
                message: `Por favor, informe um ${
                  isProduct ? 'Produto' : 'Serviço'
                }`,
              },
            ]}
            item={
              <InputDynamicComplete
                options={productServices}
                onSelect={setProductOrServiceName}
                OptionComponent={ProductOrServiceOptionItem}
                onChange={SearchOptions}
              />
            }
          />

          <FormItem
            label="Nome Personalizado"
            name="personalized_name"
            rules={[
              {
                required: true,
                message: `Por favor, informe um nome para o seu ${
                  isProduct ? 'Produto' : 'Serviço'
                }`,
              },
            ]}
            item={<Input />}
          />

          <FormItem
            label="Descrição"
            name="description"
            rules={[
              {
                required: true,
                message: `Por favor, informe uma descrição`,
              },
            ]}
            item={<Input />}
          />

          {isProduct ? (
            <FormItem
              label="Unidade de medida"
              name="unitysMeasure"
              item={<Select options={unitysMeasure} />}
            />
          ) : (
            <div />
          )}

          <Grid>
            <FormItem
              label="Quantidade"
              name="quantity"
              item={
                <InputNumber
                  defaultValue={productService.quantity}
                  min={1}
                  max={999999999}
                />
              }
            />

            <FormItem
              label="Valor"
              name="valuePerUnit"
              item={
                <InputNumber
                  defaultValue={productService.value / 1000000}
                  min={1}
                  max={999999999}
                />
              }
            />

            <FormItem
              style={{
                marginTop: '30px',
              }}
              item={
                <Button htmlType="submit" type="primary">
                  {`Editar ${isProduct ? 'Produto' : 'Serviço'}`}
                </Button>
              }
            />
          </Grid>
        </Form>
      )}
    </>
  );
}
