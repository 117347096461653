import React from 'react';

import { message, Form } from 'antd';

import Card from '~/components/card';
import Button from '~/components/button';
import FormItem from '~/components/form/FormItem';
import TextArea from '~/components/textArea';
import { UploadArray } from '~/components/uploadInput';

import SocialMeAPI from '~/utils/SocialMeAPI';

import { ButtonContainer } from './style';

export default function JudgeContainer({
  deposit,
  comproveImages,
  setComproveImages,
  description,
  theme,
  CloseDrawer,
  GetDepositsAgain,
  isDebug,
}) {
  const [form] = Form.useForm();

  async function onFinish(values, judgement) {
    const params = {
      operation: ['Admin', 'ManualDeposits', judgement],
      urlParams: {
        depositId: deposit._id,
      },
      data: {
        files: comproveImages,
        settings: {
          ...deposit.settings,
          comment: values.short_description,
        },
      },
    };

    if (isDebug) console.log('📦 Params: ', params);

    const response = await SocialMeAPI(params);

    if (isDebug)
      console.log('🚀 API Response, Admin ManualDeposits Judge: ', response);

    if (response.success) {
      if (isDebug) console.log('Judgement was succesfully maded');

      message.success('Julgamento realizado com sucesso');

      GetDepositsAgain();

      CloseDrawer();
    } else {
      message.error('Ocorreu um erro ao julgar');
      if (isDebug) console.log('No judgment happen');
    }
  }

  const cardStyle = {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 15,
  };

  async function onSelectImage(file) {
    const data = new FormData();

    data.append('file', file);

    const params = {
      operation: ['Admin', 'ManualDeposits', 'File'],
      urlParams: {
        personId: deposit.person_id,
      },
      data,
    };

    if (isDebug) console.log('📦 Params: ', params);

    const response = await SocialMeAPI(params);

    if (isDebug)
      console.log('🚀 API Response, Admin ManualDeposits File: ', response);

    if (response.success) {
      if (isDebug) console.log('A file was set in ASW');

      setComproveImages([...comproveImages, response.body.file]);
    } else if (isDebug) {
      console.log('No file was set');
    }
  }

  return (
    <Card style={cardStyle}>
      <UploadArray onSelect={onSelectImage} files={comproveImages} />
      <Form form={form} layout="vertical">
        <FormItem
          label="Comentário do administrador"
          name={['short_description']}
          initialValue={description}
          item={
            <TextArea
              maxLength={280}
              showCount
              autoSize={{ minRows: 4, maxRows: 6 }}
            />
          }
        />
      </Form>
      <ButtonContainer>
        <Button
          style={{
            backgroundColor: theme.color.danger,
            color: theme.color.white,
          }}
          onClick={() => {
            form.validateFields().then((values) => {
              onFinish(values, 'Reject');
            });
          }}
        >
          Rejeitar
        </Button>

        <Button
          style={{
            backgroundColor: theme.color.green,
            color: theme.color.white,
          }}
          onClick={() => {
            form.validateFields().then((values) => {
              onFinish(values, 'Approve');
            });
          }}
        >
          Aprovar
        </Button>
      </ButtonContainer>
    </Card>
  );
}
