import API from '~/environments/APIs';

const Admin = {
  ActivityType: {
    List: {
      baseURL: API.socialMe,
      url: '/manage/activity/activity-types',
      method: 'post',
      timeout: '50000',
    },
    Add: {
      baseURL: API.socialMe,
      url: '/manage/activity/activity-type',
      method: 'post',
      timeout: '50000',
    },
    Edit: {
      baseURL: API.socialMe,
      url: '/manage/activity/activity-type/activityTypeId',
      method: 'put',
      timeout: '50000',
    },
    Delete: {
      baseURL: API.socialMe,
      url: '/manage/activity/activity-type/activityTypeId',
      method: 'delete',
      timeout: '50000',
    },
  },
  ManualDeposits: {
    List: {
      baseURL: API.socialMe,
      url: '/manage/dw/operation/manuals',
      method: 'post',
      timeout: '50000',
    },
    Show: {
      baseURL: API.socialMe,
      url: '/manage/dw/operation/manual/depositId',
      method: 'get',
      timeout: '50000',
    },
    Approve: {
      baseURL: API.socialMe,
      url: '/dw/operation/manual/depositId/APPROVE',
      method: 'post',
      timeout: '50000',
    },
    Reject: {
      baseURL: API.socialMe,
      url: '/dw/operation/manual/depositId/REJECT',
      method: 'post',
      timeout: '50000',
    },
    File: {
      baseURL: API.socialMe,
      url: '/manage/dw/operation/manuals/personId/file',
      method: 'post',
      timeout: '50000',
    },
  },
  PendingDonations: {
    List: {
      baseURL: API.socialMe,
      url: '/manage/donation/requests',
      method: 'post',
      timeout: '50000',
    },
    Show: {
      baseURL: API.socialMe,
      url: '/manage/donation/request/donationId',
      method: 'get',
      timeout: '50000',
    },
    Judge: {
      baseURL: API.socialMe,
      url: '/manage/donation/request/donationId/judgment',
      method: 'post',
      timeout: '50000',
    },
  },
};

export default Admin;
