import React, { useState, useContext } from 'react';
// Libraries
import { Drawer, message, Form } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
// Contexts
import { ResponsiveContext } from '~/contexts/ResponsiveContext';
//Own Components
import AddForm from './components/AddForm';
//Components
import Button from '~/components/button';
// Utils
import SocialMeAPI from '~/utils/SocialMeAPI';

export default function StoreButton({ GetDataAgain }) {
  const { isMobile } = useContext(ResponsiveContext);

  const [visibleDrawer, setVisibleDrawer] = useState(false);
  const [form] = Form.useForm();
  const [formValues, setFormValues] = useState('');
  const [loadingButton, setLoadingButton] = useState(false);
  const [tags, setTags] = useState([]);
  const [akas, setAkas] = useState([]);

  const ShowDrawer = () => {
    setVisibleDrawer(true);
  };
  const CloseDrawer = () => {
    setVisibleDrawer(false);
  };
  async function Add(data) {
    const params = {
      operation: ['Admin', 'ActivityType', 'Add'],
      data,
    };
    const response = await SocialMeAPI(params);
    if (response.success) {
      GetDataAgain();
      message.success('Tipo de Atividade Cadastrado!');
      setLoadingButton(false);
      return setVisibleDrawer(false);
    }
    message.error('Alguma coisa aconteceu...');
    setLoadingButton(false);
  }
  const onFinish = (data) => {
    data.tags = tags;
    data.akas = akas;
    data.national_agent = `Agente Nacional ${data.national_agent_id}`;
    Add(data);
  };

  return (
    <>
      <Button
        text="Novo Tipo de Atividade"
        onClick={() => ShowDrawer()}
        type="primary"
        icon={<PlusOutlined />}
      />

      <Drawer
        title="Novo Tipo de Atividade"
        width={isMobile ? '100%' : 600}
        onClose={CloseDrawer}
        visible={visibleDrawer}
        bodyStyle={{ padding: 20 }}
        footer={
          <div
            style={{
              textAlign: 'right',
            }}
          >
            <Button
              text="Cancelar"
              onClick={CloseDrawer}
              style={{ marginRight: 8 }}
            />

            <Button
              text="Adicionar"
              loading={loadingButton}
              disabled={loadingButton}
              type="primary"
              onClick={() => {
                form
                  .validateFields()
                  .then((values) => {
                    form.resetFields();
                    onFinish(values);
                  })
                  .catch((info) => {
                    // console.log('Validate Failed:', info);
                  });
              }}
            />
          </div>
        }
      >
        <Form
          labelCol={{
            xs: { span: 24 },
            sm: { span: 24 },
          }}
          wrapperCol={{
            xs: { span: 24 },
            sm: { span: 24 },
          }}
          form={form}
          layout="vertical"
          size="small"
          onValuesChange={() => setFormValues(form.getFieldsValue())}
          scrollToFirstError
          initialValues={{
            status: 'ACTIVE',
          }}
        >
          <AddForm
            formValues={formValues}
            form={form}
            tags={tags}
            setTags={setTags}
            akas={akas}
            setAkas={setAkas}
          />
        </Form>
      </Drawer>
    </>
  );
}
