import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 15px 0;
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${(props) => props.align};
  width: ${(props) => props.width};
`;

export const FlexContent = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 15px;
`;
