import React, { useEffect, useState } from 'react';
//Libraries
import { Divider } from 'antd';
// Own Components
import Header from './components/Header';
import Content from './components/Content';
// Components
import Card from '~/components/card';
import Loading from '~/components/loading';
//Environments
import Debug from '~/environments/Debug';
// Utils
import SocialMeAPI from '~/utils/SocialMeAPI';

let isDebug = Debug.Administrator.ActivityTypes;

export default function ActivityTypes() {
  const [loadingContent, setLoadingContent] = useState(true);
  const [data, setData] = useState();
  const [searchInput, setSearchInput] = useState();
  const [pageParameters, setPageParameters] = useState({
    currentPage: 1,
    totalElements: 0,
    limit: 10,
  });

  useEffect(() => {
    async function GetData() {
      if (isDebug) console.log('🚀 API: Get Activity Types');
      const params = {
        operation: ['Admin', 'ActivityType', 'List'],
        data: {
          filter: {
            searchString: searchInput,
          },
          pagination: {
            sort: {
              by: 'name',
              order: 'asc',
            },
          },
        },
      };
      const response = await SocialMeAPI(params);
      if (isDebug)
        console.log('  ⚡ API Response (Get Activity Types): ', response);
      if (response.success) {
        setPageParameters({
          currentPage: response.body.activityTypes.currentPage,
          totalElements: response.body.activityTypes.totalElements,
          limit: response.body.activityTypes.limit,
        });
        setData(response.body.activityTypes.elements);
        setLoadingContent(false);
      }
    }
    GetData();
  }, [searchInput]);

  async function GetDataAgain(newPageParameters) {
    if (isDebug) console.log('🚀 API: Get Activity Types');
    const params = {
      operation: ['Admin', 'ActivityType', 'List'],
      data: {
        filter: {
          searchString: searchInput,
        },
        pagination: {
          limit: newPageParameters
            ? newPageParameters.limit
            : pageParameters.limit,
          page: newPageParameters
            ? newPageParameters.currentPage
            : pageParameters.currentPage,
          sort: {
            by: 'name',
            order: 'asc',
          },
        },
      },
    };
    const response = await SocialMeAPI(params);
    if (isDebug)
      console.log('  ⚡ API Response (Get Activity Types): ', response);
    if (response.success) {
      setPageParameters({
        ...pageParameters,
        currentPage: response.body.activityTypes.currentPage,
        totalElements: response.body.activityTypes.totalElements,
        limit: response.body.activityTypes.limit,
      });
      setData(response.body.activityTypes.elements);
      setLoadingContent(false);
    }
  }

  return (
    <div>
      <Card>
        <Header GetDataAgain={GetDataAgain} setSearchInput={setSearchInput} />
        <Divider />
        {loadingContent ? (
          <Loading text="Carregando tipos de atividades" />
        ) : (
          <Content
            data={data}
            GetDataAgain={GetDataAgain}
            pageParameters={pageParameters}
          />
        )}
      </Card>
    </div>
  );
}
