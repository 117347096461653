import React, { memo } from 'react';

import Button from '~/components/button';

function EditMode({ editMode, setEditMode }) {
  return (
    <Button
      style={{ margin: '0 5px 5px 0' }}
      onClick={() => setEditMode(!editMode)}
    >
      {editMode ? 'Desabilitar edição' : 'Habilitar edição'}
    </Button>
  );
}

export default memo(EditMode);
