import API from '~/environments/APIs';

const Donation = {
  Edit: {
    baseURL: API.socialMe,
    url: '/manage/donation/request/donationId',
    method: 'put',
    timeout: '50000',
  },
  File: {
    baseURL: API.socialMe,
    url: '/donation/request/donationId/file',
    method: 'post',
    timeout: '50000',
  },
  List: {
    baseURL: API.socialMe,
    url: '/manage/donation/requests',
    method: 'post',
    timeout: '50000',
  },
  Show: {
    baseURL: API.socialMe,
    url: '/manage/donation/request/donationId',
    method: 'get',
    timeout: '50000',
  },
  Judge: {
    baseURL: API.socialMe,
    url: '/manage/donation/request/donationId/judgment',
    method: 'post',
    timeout: '50000',
  },
  JudgeNoValidation: {
    baseURL: API.socialMe,
    url: '/manage/donation/request/donationId/judgment/no-validation',
    method: 'post',
    timeout: '50000',
  },
  ProductServices: {
    Search: {
      baseURL: API.socialMe,
      url: '/prod-serv/ps-models',
      method: 'post',
      timeout: '50000',
    },
    Add: {
      baseURL: API.socialMe,
      url: '/manage/donation/request/donationId/product-service',
      method: 'post',
      timeout: '50000',
    },
    Edit: {
      baseURL: API.socialMe,
      url:
        '/manage/donation/request/donationId/product-service/productServiceId',
      method: 'put',
      timeout: '50000',
    },
    Delete: {
      baseURL: API.socialMe,
      url:
        '/manage/donation/request/donationId/product-service/productServiceId',
      method: 'delete',
      timeout: '50000',
    },
    UnitsOfMeasure: {
      Get: {
        baseURL: API.socialMe,
        url: '/prod-serv/m-unities',
        method: 'post',
        timeout: '50000',
      },
    },
  },
};

export default Donation;
