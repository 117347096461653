import React, { useState, useContext } from 'react';
//Libraries
import { Drawer, Form, message } from 'antd';
//Contexts
import { AxiosContext } from '~/contexts/AxiosContext';
//Components
import Button from '~/components/button';
//Own Components
import DrawerContent from './components/DrawerContent';

export default function ButtonAdd({ PickUpPeopleWithPermissionsAgain }) {
  const { CallToTheAPI } = useContext(AxiosContext);
  const [visible, setVisible] = useState(false);
  const [view, setView] = useState('Search');
  const [foundPerson, setFoundPerson] = useState();
  const [form] = Form.useForm();

  function ShowDrawer() {
    setVisible(true);
  }
  function CloseDrawer() {
    setVisible(false);
    setView('Search');
    setFoundPerson();
  }

  async function UpdatePermissions(data) {
    const params = {
      operation: ['Manage', 'Permission', 'Update'],
      urlParams: {
        personId: foundPerson.id,
      },
      data,
    };
    //console.log('params -> ', params.data);
    const response = await CallToTheAPI(params);
    //console.log('response -> ', response);
    if (response.success) {
      CloseDrawer();
      setView('Search');
      setFoundPerson();
      message.success('Permissões atualizadas com sucesso');
      return PickUpPeopleWithPermissionsAgain();
    }
  }

  async function onFinish(data) {
    //console.log('Received data of form: ', data);
    let treatedDate = { permissions: [] };
    for (const key in data) {
      if (data[key]) {
        const newItem = {
          permission: key,
        };
        treatedDate.permissions.push(newItem);
      }
    }
    //console.log('treatedDate -> ', treatedDate);
    UpdatePermissions(treatedDate);
  }

  const RenderButton = () => {
    if (view === 'Adding Permissions') {
      return (
        <Button
          type="primary"
          onClick={() => {
            form
              .validateFields()
              .then((values) => {
                //form.resetFields();
                onFinish(values);
              })
              .catch((info) => {
                // console.log('Validate Failed:', info);
              });
          }}
        >
          {foundPerson
            ? foundPerson.permissions
              ? 'Atualizar'
              : 'Adicionar'
            : 'Adicionar'}
        </Button>
      );
    }
    if (foundPerson) {
      return (
        <Button type="primary" onClick={() => setView('Adding Permissions')}>
          Próximo
        </Button>
      );
    }
    return (
      <Button disabled={true} type="primary">
        Próximo
      </Button>
    );
  };

  return (
    <div>
      <Button type="primary" onClick={() => ShowDrawer()}>
        Adicionar Permissão
      </Button>

      <Drawer
        title="Adicionando Permissão"
        placement="right"
        closable={CloseDrawer}
        onClose={CloseDrawer}
        visible={visible}
        width={400}
        bodyStyle={{ padding: 10 }}
        footer={
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}
          >
            <Button
              text="Cancelar"
              onClick={CloseDrawer}
              style={{ marginRight: 8 }}
            />

            {RenderButton()}
          </div>
        }
      >
        <DrawerContent
          view={view}
          setFoundPerson={setFoundPerson}
          foundPerson={foundPerson}
          form={form}
        />
      </Drawer>
    </div>
  );
}
