import React, { useContext, memo } from 'react';

import { ThemeContext } from '~/contexts/ThemeContext';

import SearchInput from './style';

function AntInput({
  disabled,
  placeholder,
  size,
  prefix,
  suffix,
  width,
  margin,
  value,
  onChange,
  onSearch,
  enterButton,
  allowClear,
  loading,
}) {
  const { theme } = useContext(ThemeContext);

  return (
    <SearchInput
      disabled={disabled}
      loading={loading}
      onSearch={onSearch}
      onChange={onChange}
      enterButton={enterButton}
      allowClear={allowClear || true}
      placeholder={placeholder}
      size={size}
      prefix={prefix}
      suffix={suffix}
      value={value}
      width={width}
      margin={margin}
      backgroundcolor={theme.color.white}
      color={theme.color.textOnSecondaryBackground}
    />
  );
}

export default memo(AntInput);
