import React, { createContext, useEffect, useState } from 'react';
//Libraries
import { useLocation } from 'react-router-dom';
//Environments
import System from '~/environments/System';
import Debug from '~/environments/Debug';
//Utils
import Cookie from '~/utils/Cookie';
import SocialMeAPI from '~/utils/SocialMeAPI';

const isDebug = Debug.Context.Auth;

export const AuthContext = createContext();

const AuthContextProvider = ({ children }) => {
  const [user, setUser] = useState();
  const [endedUserLogic, setEndedUserLogic] = useState(false);

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  let query = useQuery();

  //CheckUserToken
  useEffect(() => {
    async function CheckUserToken() {
      const params = {
        operation: ['Auth', 'UserToken', 'Check'],
      };
      if (isDebug) console.log('🚀 API: Check user token');
      const response = await SocialMeAPI(params);
      if (isDebug) console.log('  ⚡ API Response: ', response);
      if (response.success) {
        if (isDebug) console.log('    ✔ Valid user token');
        let user = response.body.usr;
        if (response.body.permission) {
          user.permission = response.body.permission;
        }
        if (response.body.role) {
          user.role = response.body.role;
        }
        setUser(user);
      } else {
        if (isDebug) console.log('    ❌ Invalid user token');
      }
      return setEndedUserLogic(true);
    }
    async function VerifyUserToken() {
      if (isDebug) console.log('🔍 Searching for user token');
      const UserTokenCookies = await Cookie.Get(System.UserCookieName);
      if (UserTokenCookies) {
        if (isDebug) console.log('  ✔ User token found');
        CheckUserToken();
      } else {
        if (isDebug) console.log('  ❌ User token not found');
        setEndedUserLogic(true);
      }
    }
    VerifyUserToken();
  }, []);

  async function GetUserAgain() {
    const params = {
      operation: ['Auth', 'UserToken', 'Check'],
    };
    if (isDebug) console.log('🚀 API: Check user token');
    const response = await SocialMeAPI(params);
    if (isDebug) console.log('  ⚡ API Response: ', response);
    if (response.success) {
      let user = response.body.usr;
      if (response.body.permission) {
        user.permission = response.body.permission;
      }
      if (response.body.role) {
        user.role = response.body.role;
      }
      setUser(user);
    }
  }

  async function Redirect() {
    const serviceName = query.get('service');
    if (serviceName) {
      switch (serviceName) {
        case 'localhost':
          return window.location.replace('http://localhost:3000');
        case 'website':
          return window.location.replace('https://socialme.com.br');
        case 'myaccount':
          return window.location.replace('https://myaccount.socialme.com.br');
        case 'giver':
          return window.location.replace('https://giver.socialme.com.br');
        case 'beneficiary':
          return window.location.replace('https://beneficiary.socialme.com.br');
        case 'provider':
          return window.location.replace('https://provider.socialme.com.br');
        case 'manager':
          return window.location.replace('https://manager.socialme.com.br');
        case 'wallet':
          return window.location.replace('https://wallet.socialme.com.br');
        case 'doacoesMe':
          return window.location.replace('https://doacoes.me');
        default:
        case 'achei':
          return window.location.replace('https://achei.me');
      }
    }
  }

  async function PopulateUser(person, token) {
    if (isDebug) console.log('Populate User');
    const serviceName = query.get('service');
    if (!serviceName) {
      if (isDebug) console.log('  ❌ Service does not exist');
      person.permission = [];
      person.role = [];
      setUser(person);
    } else {
      if (isDebug) console.log('  ✔ Service exist');
    }
    if (isDebug) console.log('  🍪 Persist cookie in browser');
    const creatingTheCookie = await Cookie.Create({
      name: System.UserCookieName,
      value: token,
    });
    if (creatingTheCookie) {
      if (isDebug) console.log('  ✔ User cookie successfully created');
    } else {
      if (isDebug) console.log('  ❗ Error creating User Cookie');
    }
    Redirect();
  }

  async function Logout(url) {
    async function Refresh() {
      window.location.replace(url);
    }
    async function DeleteCookies() {
      const deletingTheBrowserCookie = await Cookie.Delete(
        System.BrowserCookieName
      );
      if (deletingTheBrowserCookie) {
        if (isDebug) console.log('  ✔ Browser cookie successfully deleted');
      }
      const deletingTheUserCookie = await Cookie.Delete(System.UserCookieName);
      if (deletingTheUserCookie) {
        if (isDebug) console.log('  ✔ User cookie successfully deleted');
      }
      const deletingTheThemeCookie = await Cookie.Delete(
        System.ThemeCookieName
      );
      if (deletingTheThemeCookie) {
        if (isDebug) console.log('  ✔ Theme cookie successfully deleted');
      }
      const deletingTheLanguageCookie = await Cookie.Delete(
        System.LanguageCookieName
      );
      if (deletingTheLanguageCookie) {
        if (isDebug) console.log('  ✔ Language cookie successfully deleted');
      }
      Refresh();
    }
    const params = {
      operation: ['Auth', 'Logout'],
    };
    if (isDebug) console.log('🚀 API: Logout');
    const response = await SocialMeAPI(params);
    if (isDebug) console.log('  ⚡ API Response: ', response);
    if (response.success) {
      await DeleteCookies();
    }
  }

  return (
    <AuthContext.Provider
      value={{ GetUserAgain, PopulateUser, Logout, user, endedUserLogic }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
