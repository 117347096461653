import styled from 'styled-components';

import { CaretDownOutlined } from '@ant-design/icons';

export const DropdownContainer = styled.div`
  position: relative;
  display: flex;
  margin: 0 5px 5px 0;
`;

export const Dropdown = styled.div`
  min-width: ${(props) => props.width};
  text-align: center;
  padding: 4px 10px;
  border: 1px solid ${(props) => props.borderColor};
  transition: all 0.3s;
  cursor: pointer;

  &:focus,
  &:hover {
    outline: none;
    color: ${(props) => props.primary};
    border: 1px solid ${(props) => props.primary};
  }

  &:hover svg {
    color: ${(props) => props.primary};
  }
`;

export const Item = styled.button`
  min-width: ${(props) => props.width};
  padding: 4px 10px;
  background-color: ${(props) => props.backgroundColor};
  color: ${(props) => props.color};
  border: 1px solid ${(props) => props.borderColor};
  outline: none;
  transition: all 0.3s;
  cursor: pointer;

  &:hover {
    background-color: ${(props) => props.primary};
    color: ${(props) => props.white};
    border-color: ${(props) => props.primary};
  }
`;

export const ItemContainer = styled.div`
  z-index: 1;
  position: absolute;
  margin-top: 34px;
  display: none;
`;

export const CaretIcon = styled(CaretDownOutlined)`
  margin: 5px 0 0 5px;
`;
