import API from '~/environments/APIs';

const Geo = {
  Get: {
    baseURL: API.socialMe,
    url: '/manage/location/csccs',
    method: 'post',
    timeout: '50000',
  },
  Add: {
    baseURL: API.socialMe,
    url: '/manage/location/cscc',
    method: 'post',
    timeout: '50000',
  },
  Edit: {
    baseURL: API.socialMe,
    url: '/manage/location/cscc/countryStateCityId',
    method: 'put',
    timeout: '50000',
  },
  Delete: {
    baseURL: API.socialMe,
    url: '/manage/location/cscc/countryStateCityId',
    method: 'delete',
    timeout: '50000',
  },
};

export default Geo;
