import React, { memo, useContext } from 'react';

import { ThemeContext } from '~/contexts/ThemeContext';

import {
  ItemContainer,
  DropdownContainer,
  Dropdown,
  Item,
  CaretIcon,
} from './style';

function ButtonDropdown({
  dropdownId,
  items,
  menuTitle,
  ItemOnClick,
  width,
  x,
}) {
  const { theme } = useContext(ThemeContext);

  function ChangeMenuVisibility(visibility) {
    const menu = document.getElementById(dropdownId);

    menu.style.display = visibility;
  }

  return (
    <DropdownContainer>
      <Dropdown
        width={width || '110px'}
        onMouseLeave={() => ChangeMenuVisibility('none')}
        onMouseOver={() => ChangeMenuVisibility('block')}
        onFocus={() => ChangeMenuVisibility('block')}
        borderColor={theme.color.borderColor}
        primary={theme.color.primary}
      >
        {menuTitle} <CaretIcon />
      </Dropdown>
      <ItemContainer id={dropdownId}>
        {items.map((item) => (
          <Item
            width={width || '110px'}
            borderColor={theme.color.borderColor}
            backgroundColor={theme.color.white}
            color={theme.color.textOnSecondaryBackground}
            primary={theme.color.primary}
            white={theme.color.white}
            key={item.value}
            value={item.value}
            onMouseLeave={() => ChangeMenuVisibility('none')}
            onMouseOver={() => ChangeMenuVisibility('block')}
            onFocus={() => ChangeMenuVisibility('block')}
            onClick={(event) => {
              ChangeMenuVisibility('none');
              ItemOnClick(event);
            }}
          >
            {item.label}
          </Item>
        ))}
      </ItemContainer>
    </DropdownContainer>
  );
}

export default memo(ButtonDropdown);
