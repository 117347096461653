import React, { useContext, useState, useEffect } from 'react';
// Libraries
import { message, Drawer, Form } from 'antd';
// Components
import Button from '~/components/button';
//Own Components
import EditForm from './components/EditForm';
// Contexts
import { AxiosContext } from '~/contexts/AxiosContext';
import { ResponsiveContext } from '~/contexts/ResponsiveContext';
//Utils
import {
  WalletFormatParse,
  WalletFormatConversion,
} from '~/utils/GeneralFunctions';

export default function Actions({ itemData, GetDataAgain }) {
  const { CallToTheAPI } = useContext(AxiosContext);
  const { isMobile } = useContext(ResponsiveContext);

  const [visibleDrawer, setVisibleDrawer] = useState(false);
  const [form] = Form.useForm();
  const [formValues, setFormValues] = useState('');
  const [loadingButton, setLoadingButton] = useState(false);
  const [tags, setTags] = useState([]);
  const [akas, setAkas] = useState([]);

  useEffect(() => {
    async function PrepareInitialValues() {
      itemData.value = WalletFormatConversion(itemData.value);
      itemData.measurementUnity = itemData.measurement_unity_id;
      if (itemData.status === 'false') itemData.status = false;
      if (itemData.tags) setTags(itemData.tags);
      if (itemData.akas) setAkas(itemData.akas);
    }
    if (itemData) {
      PrepareInitialValues();
    }
  }, [itemData]);

  const ShowDrawer = () => {
    setVisibleDrawer(true);
  };
  const CloseDrawer = () => {
    setVisibleDrawer(false);
  };
  async function Edit(data) {
    const params = {
      operation: ['ProductOrService', 'Edit'],
      urlParams: {
        offeringId: itemData.id,
      },
      data,
    };
    const response = await CallToTheAPI(params);
    if (response.success) {
      message.success('Produto Atualizado!');
      setLoadingButton(false);
      CloseDrawer();
      return GetDataAgain();
    }
    message.error('Alguma coisa aconteceu...');
    setLoadingButton(false);
  }

  const onFinish = (data) => {
    data.value = WalletFormatParse(data.value);
    data.tags = tags;
    data.akas = akas;
    Edit(data);
  };
  return (
    <>
      <Button
        text="Editar"
        onClick={() => ShowDrawer()}
        style={{ marginRight: 5 }}
      />

      <Drawer
        title={`EDITANDO ${itemData.name || ''}`}
        width={isMobile ? '100%' : 600}
        onClose={CloseDrawer}
        visible={visibleDrawer}
        bodyStyle={{ padding: 20 }}
        footer={
          <div
            style={{
              textAlign: 'right',
            }}
          >
            <Button onClick={CloseDrawer} style={{ marginRight: 8 }}>
              Cancelar
            </Button>

            <Button
              loading={loadingButton}
              disabled={loadingButton}
              type="primary"
              onClick={() => {
                form
                  .validateFields()
                  .then((values) => {
                    //form.resetFields();
                    //form.setFieldsValue(values);
                    onFinish(values);
                  })
                  .catch((info) => {
                    //console.log('Validate Failed:', info);
                  });
              }}
            >
              Atualizar
            </Button>
          </div>
        }
      >
        <Form
          labelCol={{
            xs: { span: 24 },
            sm: { span: 24 },
          }}
          wrapperCol={{
            xs: { span: 24 },
            sm: { span: 24 },
          }}
          form={form}
          layout="vertical"
          size="small"
          onValuesChange={() => setFormValues(form.getFieldsValue())}
          scrollToFirstError
          initialValues={itemData}
        >
          <EditForm
            formValues={formValues}
            form={form}
            tags={tags}
            setTags={setTags}
            akas={akas}
            setAkas={setAkas}
          />
        </Form>
      </Drawer>
    </>
  );
}
