import React, { useContext, memo } from 'react';
//Own Components
import FormItems from './components/FormItems';
// Components
import Page from '~/components/pageStructure/type1';
import { ImageWithPrimaryAndSecondaryTextCell } from '~/components/table/cells';
// Contexts
import { AuthContext } from '~/contexts/AuthContext';

function Cities() {
  const { user } = useContext(AuthContext);

  const breadcrumbItems = [
    {
      title: 'Painel do Administrador',
    },
    {
      title: 'Localidades',
    },
  ];
  const tableColumns = [
    {
      title: 'Localidade',
      dataIndex: 'name',
      key: 'name',
      render: (name, record) => (
        <ImageWithPrimaryAndSecondaryTextCell
          image={record.flag}
          primaryText={name || 'sem nome'}
          secondaryText={``}
        />
      ),
    },
    {
      title: 'Cidade',
      dataIndex: 'city_name',
      key: 'city_name',
    },
    {
      title: 'Estado',
      dataIndex: 'state_name',
      key: 'state_name',
    },
    {
      title: 'País',
      dataIndex: 'country_name',
      key: 'country_name',
    },
  ];

  return (
    <Page
      // Geral Functions
      parametersToGetTheData={{
        operation: ['Geo', 'Get'],
        data: {
          filter: {
            type: 'COM',
          },
          sort: {
            by: 'name',
            order: 'asc',
          },
        },
      }}
      getTheDataMessages={{
        success: 'Localidades listados com sucesso!',
        error: 'Alguma coisa aconteceu...',
      }}
      pathToTheData={['body', 'countryStateCityCommunityList', 'elements']}
      pagination
      pathToThePageParameters={['body', 'countryStateCityCommunityList']}
      FormItems={FormItems}
      // Header
      title="Localidades"
      breadcrumbItems={breadcrumbItems}
      addButtonText="Nova localidade"
      search
      searchPlaceholder="Buscar localidade"
      parametersToObtainTheDataWithSearch={{
        operation: ['Geo', 'Get'],
        urlParams: {
          personId: user._id,
        },
        data: {
          filter: {
            type: 'COM',
            searchString: 'searchInput',
          },
          sort: {
            by: 'name',
            order: 'asc',
          },
        },
      }}
      parametersForAddingData={{
        operation: ['Geo', 'Add'],
      }}
      addingDataInitialValues={{
        type: 'COM',
      }}
      addingDataMessages={{
        success: 'Localidade adicionado com sucesso!',
        error: 'Alguma coisa aconteceu...',
      }}
      // Content
      tableColumns={tableColumns}
      actionCellType="EditingAndDeleting"
      paramsIdName="countryStateCityId"
      operationForDeleteData={['Geo', 'Delete']}
      deleteDataMessages={{
        success: 'Localidade apagado com sucesso!',
        error: 'Alguma coisa aconteceu...',
      }}
      operationForEditData={['Geo', 'Edit']}
      editDataMessages={{
        success: 'Localidade atualizado com sucesso!',
        error: 'Alguma coisa aconteceu...',
      }}
    />
  );
}

export default memo(Cities);
