import React from 'react';
// Libraries
import { Row, Col } from 'antd';
// Components
import Divider from '~/components/divider';
import FormItem from '~/components/form/FormItem';
import TextArea from '~/components/textArea';
import Text from '~/components/typography/text';

// Utils
import { WalletFormatConversion } from '~/utils/Transformations';

import ProductServicesItem from './components/ProductServicesItem';
import ProductServiceButtons from './components/ProductServiceButtons';

export default function DonationInformation({
  setDonation,
  donation,
  theme,
  isDebug,
}) {
  function GetTotal() {
    let total = 0;
    donation.product_services.map((item) => {
      let totalItem = item.value * item.quantity;
      return (total += totalItem);
    });
    return WalletFormatConversion(total);
  }

  return (
    <div>
      <Row>
        <Col span={24}>
          <ProductServiceButtons
            donation={donation}
            setDonation={setDonation}
            isDebug={isDebug}
          />
        </Col>
        <Col span={24}>
          <Text strong>Total geral da doação: {GetTotal()}</Text>
        </Col>

        <Col span={24}>
          {donation.product_services.map((productService) => (
            <ProductServicesItem
              donation={donation}
              setDonation={setDonation}
              theme={theme}
              isDebug={isDebug}
              key={productService.id}
              productService={productService}
            />
          ))}
        </Col>
        <Divider borderColor={theme.color.white} />
        <Col span={24}>
          <FormItem
            label="Conte um pouco sobre a sua história"
            name={['history']}
            item={<TextArea autoSize />}
          />
        </Col>
        <Col span={24}>
          <FormItem
            label="Qual o seu maior sonho?"
            name={['dream']}
            item={<TextArea autoSize />}
          />
        </Col>
        <Col span={24}>
          <FormItem
            label="Como essa doação irá lhe ajudar?"
            name={['help']}
            item={<TextArea autoSize />}
          />
        </Col>
      </Row>
    </div>
  );
}
