import React, { useState, createContext, useContext, useEffect } from 'react';
//Libraries
import { useHistory } from 'react-router-dom';
//Contexts
import { AuthContext } from '~/contexts/AuthContext';
//Environments
import Debug from '~/environments/Debug';

const isDebug = Debug.Context.Permission;

export const PermissionContext = createContext();

const PermissionContextProvider = ({ children }) => {
  const { user } = useContext(AuthContext);

  const history = useHistory();
  const [permission, setPermission] = useState(false);
  const [God, setGod] = useState(false);

  useEffect(() => {
    if (user) {
      if (isDebug) console.log('✔ User found');
      const numberOfPermissions = Object.entries(user.permission).length;
      if (numberOfPermissions !== 0) {
        if (isDebug)
          console.log(`Number of user permissions: ` + numberOfPermissions);
        setPermission(user.permission);
        if (user.permission.GOD) {
          if (isDebug) console.log(`🌎 It's a God!!!`);
          setGod(true);
        }
      } else {
        if (isDebug) console.log(`⛔ User without any permission!`);
      }
    }
  }, [user]);

  function CheckPermission(permissionName, basePermission) {
    if (God || permissionName === 'ANY') {
      return true;
    }
    if (permission[permissionName.toUpperCase()]) {
      if (isDebug)
        console.log(`✔ You have permission: ${permissionName.toUpperCase()}`);
      return true;
    }
    if (basePermission) {
      if (isDebug)
        console.log(
          `⛔ You do not have permission: ${permissionName.toUpperCase()}`
        );
      return history.push('/');
    }
    if (isDebug)
      console.log(
        `⛔ You do not have permission: ${permissionName.toUpperCase()}`
      );
    return false;
  }

  return (
    <PermissionContext.Provider value={{ CheckPermission }}>
      {children}
    </PermissionContext.Provider>
  );
};

export default PermissionContextProvider;
