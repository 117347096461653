import React, { useContext } from 'react';

import { Empty } from 'antd';

import { PermissionContext } from '~/contexts/PermissionContext';

import JudgmentButton from './components/JudgmentButton';

import { ImageWithTextCell, DateAndTimeCell } from '~/components/table/cells';

import Table from '~/components/table';
import { ExternalLink } from '~/components/link';
import Pagination from '~/components/pagination';

export default function Content({
  data,
  theme,
  GetDataAgain,
  isMobile,
  pageParameters,
}) {
  const { CheckPermission } = useContext(PermissionContext);

  const tableColumns = [
    {
      title: 'Beneficiário',
      dataIndex: 'avatar',
      key: 'avatar',
      width: '75%',
      render: (avatar, record) => (
        <ExternalLink to={`/pessoa/${record.person_id}`}>
          <ImageWithTextCell src={avatar || record} text={record.person_name} />
        </ExternalLink>
      ),
    },
    {
      title: 'Data',
      dataIndex: 'created_at',
      key: 'created_at',
      width: '15%',
      render: (created_at, record) => (
        <DateAndTimeCell timestamp={created_at} />
      ),
    },
    // {
    //   title: 'Ações',
    //   dataIndex: 'id',
    //   key: 'id',
    //   align: 'center',
    //   width: '10%',
    //   render: (id, record) => (
    //     <JudgmentButton
    //       theme={theme}
    //       isMobile={isMobile}
    //       itemData={record}
    //
    //     />
    //   ),
    // },
    /*
    {
      title: 'Visualização',
      key: 'action',
      render: (data, record) => (
        <div>
          <a
            href={`https://doacoes.me/doacao/${record.id}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <EyeOutlined style={{ marginRight: 5 }} />
            Visualizar
          </a>
        </div>
      ),
    },
    */
  ];

  if (CheckPermission('JUDGE-DONATION-REQUEST')) {
    tableColumns.push({
      title: 'Ações',
      key: 'action',
      align: 'center',
      width: '10%',
      render: (data, record) => (
        <JudgmentButton
          theme={theme}
          isMobile={isMobile}
          itemData={record}
          GetDataAgain={GetDataAgain}
        />
      ),
    });
  }

  return data.length !== 0 ? (
    <>
      <Table tableColumns={tableColumns} dataReceived={data} />
      <Pagination
        onChange={(currentPage, limit) => GetDataAgain({ currentPage, limit })}
        onShowSizeChange={(currentPage, limit) =>
          GetDataAgain({ currentPage, limit })
        }
        total={pageParameters.totalElements}
        limit={pageParameters.limit}
        currentPage={pageParameters.currentPage}
        showSizeChanger
        showQuickJumper
        showTotal={(total) => `Total ${total} itens`}
      />
    </>
  ) : (
    <Empty description="Não há nenhuma doação." />
  );
}
