import React, { useEffect, useState, useContext, memo } from 'react';
// Libraries
import { Row, Col } from 'antd';
// Contexts
import { AuthContext } from '~/contexts/AuthContext';
import { LanguageContext } from '~/contexts/LanguageContext';
// Standalone Components
import { Container, Link } from './style.js';
// Own Components
import Item from './components/Item';
// Environments
import System from '~/environments/System';
// Utils
import { GetBrandIcon } from '~/utils/SocialMeAssets';

function ItemsContainer() {
  const { user } = useContext(AuthContext);
  const { translate } = useContext(LanguageContext);

  const [isAdmin, setIsAdmin] = useState(false);
  const modules = [
    {
      title: translate.brands.accountManagement.text,
      image: GetBrandIcon('accountManagement'),
      url: `https://${System.hostname.accountManagement}`,
    },
    {
      title: translate.brands.operationsFinder.text,
      image: GetBrandIcon('operationsFinder'),
      url: `https://${System.hostname.operationsFinder}`,
    },
    /*
    {
      title: translate.brands.digitalWallet.text,
      image: GetBrandIcon('digitalWallet'),
      url: `https://${System.hostname.digitalWallet}`,
    },
    */
    {
      title: translate.brands.beneficiary.text,
      image: GetBrandIcon('beneficiary'),
      url: `https://${System.hostname.beneficiary}`,
    },
    /*
    {
      title: translate.brands.manager.text,
      image: GetBrandIcon('manager'),
      url: `https://${System.hostname.manager}`,
    },
    */
    {
      title: translate.brands.partner.text,
      image: GetBrandIcon('partner'),
      url: `https://${System.hostname.partner}`,
    },
    {
      title: translate.brands.donor.text,
      image: GetBrandIcon('donor'),
      url: `https://${System.hostname.donor}`,
    },
    {
      title: translate.brands.donationsPortal.text,
      image: GetBrandIcon('donationsPortal'),
      url: `https://${System.hostname.donationsPortal}`,
    },
    /*
    {
      title: translate.brands.marketplace.text,
      image: GetBrandIcon('marketplace'),
      url: `https://${System.hostname.marketplace}`,
    },
    {
      title: translate.brands.ecoop.text,
      image: GetBrandIcon('ecoop'),
      url: 'https://ropetec.com.br/ecoopsm/wwpbaseobjects.home.aspx',
    },
    {
      title: translate.brands.citizen.text,
      image: GetBrandIcon('citizen'),
      url: `https://${System.hostname.operationsFinder}`,
    },
    {
      title: translate.brands.medicalRecord.text,
      image: GetBrandIcon('medicalRecord'),
      url: `https://${System.hostname.operationsFinder}`,
    },
    */
  ];

  useEffect(() => {
    if (user) {
      if (user.permission) {
        if (Object.entries(user.permission).length > 0) {
          setIsAdmin(true);
        }
      }
    }
  }, [user]);

  return (
    <Container>
      <Row>
        {isAdmin ? (
          <>
            <Col span={8} />
            <Col span={8}>
              <Link href={`https://${System.hostname.administration}`}>
                <Item
                  image={GetBrandIcon('administration')}
                  title={'Administrador'}
                />
              </Link>
            </Col>
            <Col span={8} />
          </>
        ) : (
          <></>
        )}

        {modules.map((item) => (
          <Col key={item.title} span={8}>
            <Link href={item.url}>
              <Item
                image={item.image}
                imageHover={item.imageHover}
                title={item.title}
              />
            </Link>
          </Col>
        ))}
      </Row>
    </Container>
  );
}

export default memo(ItemsContainer);
