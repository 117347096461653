import React from 'react';

//Libraries
import { ConfigProvider } from 'antd';
import ptBR from 'antd/es/locale/pt_BR';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

// Contexts
import DebugContextProvider from '~/contexts/DebugContext';
import AxiosContextProvider from '~/contexts/AxiosContext';
import BrowserContextProvider from '~/contexts/BrowserContext';
import LanguageContextProvider from '~/contexts/LanguageContext';
import ResponsiveContextProvider from '~/contexts/ResponsiveContext';
import ThemeContextProvider from '~/contexts/ThemeContext';
import AuthContextProvider from '~/contexts/AuthContext';
import PermissionContextProvider from '~/contexts/PermissionContext';
import InterfaceContextProvider from '~/contexts/InterfaceContext';
import SidebarContextProvider from '~/contexts/SidebarContext';
// Assets
import '~/assets/css/antDesignCustom.css';
// Pages
import Page from '~/pages/AdminCheck';
// import Page from '~/pages/AuthenticationCheck';

// Libraries
// Contexts
// Standalone Components
// Components
// Own Components
// Services
// Utils
// Environments
// Assets
// Pages
// Steps
// Views
// Destructuring

export default function App() {
  return (
    <ConfigProvider locale={ptBR}>
      <Router>
        <DebugContextProvider>
          <AxiosContextProvider>
            <BrowserContextProvider>
              <LanguageContextProvider>
                <ResponsiveContextProvider>
                  <ThemeContextProvider>
                    <AuthContextProvider>
                      <PermissionContextProvider>
                        <InterfaceContextProvider>
                          <SidebarContextProvider>
                            <Switch>
                              <Route path="/" component={Page} />
                            </Switch>
                          </SidebarContextProvider>
                        </InterfaceContextProvider>
                      </PermissionContextProvider>
                    </AuthContextProvider>
                  </ThemeContextProvider>
                </ResponsiveContextProvider>
              </LanguageContextProvider>
            </BrowserContextProvider>
          </AxiosContextProvider>
        </DebugContextProvider>
      </Router>
    </ConfigProvider>
  );
}
