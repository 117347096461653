import React from 'react';

import Text from '~/components/typography/text';

import {
  FilterContainer,
  FilterCard,
  CheckIcon,
  IssuesIcon,
  CloseIcon,
} from './style';

export default function Filters({
  theme,
  status,
  setStatus,
  setLimit,
  setCurrentPage,
}) {
  return (
    <FilterContainer>
      <FilterCard
        onClick={() => {
          setStatus('PENDING');
          setLimit(10);
          setCurrentPage(1);
        }}
        borderColor={theme.color.primary}
        backgroundColor={
          status === 'PENDING'
            ? theme.color.primary
            : theme.color.textOnPrimaryBackground
        }
        textColor={
          status === 'PENDING'
            ? theme.color.textOnPrimaryBackground
            : theme.color.primary
        }
      >
        <IssuesIcon style={{ fontSize: 20 }} />
        <Text style={{ textAlign: 'left' }}>Pendentes</Text>
      </FilterCard>

      <FilterCard
        onClick={() => {
          setStatus('ACTIVE');
          setLimit(10);
          setCurrentPage(1);
        }}
        borderColor={theme.color.primary}
        backgroundColor={
          status === 'ACTIVE'
            ? theme.color.primary
            : theme.color.textOnPrimaryBackground
        }
        textColor={
          status === 'ACTIVE'
            ? theme.color.textOnPrimaryBackground
            : theme.color.primary
        }
      >
        <CheckIcon style={{ fontSize: 20 }} />
        <Text>Aprovadas</Text>
      </FilterCard>

      <FilterCard
        onClick={() => {
          setStatus('INACTIVE');
          setLimit(10);
          setCurrentPage(1);
        }}
        borderColor={theme.color.primary}
        backgroundColor={
          status === 'INACTIVE'
            ? theme.color.primary
            : theme.color.textOnPrimaryBackground
        }
        textColor={
          status === 'INACTIVE'
            ? theme.color.textOnPrimaryBackground
            : theme.color.primary
        }
      >
        <CloseIcon style={{ fontSize: 20 }} />
        <Text>Rejeitadas</Text>
      </FilterCard>
    </FilterContainer>
  );
}
