export default {
  Production: false,
  ResponsiveBreakpoint: 680,
  BrowserCookieName: 'brwsrtkn',
  UserCookieName: 'usrtkn',
  ThemeCookieName: 'usrthm',
  LanguageCookieName: 'usrlngg',
  CookieExpires: 'Thu, 30 Dec 2060 23:59:59 UTC',
  CookieDomain: '.socialme.com.br',
  hostname: {
    administration: 'admin.socialme.com.br',
    authentication: 'conta.socialme.com.br',
    accountManagement: 'perfil.socialme.com.br',
    beneficiary: 'beneficiario.socialme.com.br',
    citizen: '',
    digitalWallet: 'carteira.socialme.com.br',
    donationsPortal: 'doacoes.me',
    donor: 'doador.socialme.com.br',
    ecoop: 'ropetec.com.br',
    manager: 'gestor.socialme.com.br',
    marketplace: 'feiralivre.me',
    medicalRecord: '',
    operationsFinder: 'achei.me',
    partner: 'parceiro.socialme.com.br',
    paymentGateway: 'pagamentos.socialme.com.br',
    telephoneOperator: '',
  },
};
