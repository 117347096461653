export default {
  SM04C8874: {
    message: 'User account successfully listed',
  },
  SMF5177B8: {
    message: 'The account balance was successfully Read',
  },
  SMD275823: {
    message: 'The digital wallet account was successfully Read',
  },
  SM7533DBE: {
    message: 'The qr-code was successfully Listed',
  },
  SMB2D88E7: {
    message: "The 'detailed account balance' was successfully Read",
  },
  SME9664D8: {
    message: 'The qr-code was successfully Read',
  },
  SMC7BCEEA: {
    message: 'The product/service model was successfully Listed',
  },
  SM8117F92: {
    message: 'The measurement unity was successfully Listed',
  },
  SMFE8020E: {
    message: 'The people was successfully Listed',
    //Manage.Permission.SearchPerson
  },
  SM69F70D3: {
    message: 'The permission was successfully Listed',
    //Manage.Permission.Get
  },
  SMF3F4CF9: {
    message: 'The product/service offering was successfully Listed',
    //ProductOrService.Get
  },
  SMAF702EE: {
    message: 'The product/service offering was successfully Listed',
    //Admin.ActivityType.List
  },
  SM0A94EF9: {
    message: 'The place was successfully Listed',
    //Admin.Place.List
  },
  SM4CDB57A: {
    message: 'The place was successfully Listed',
    //Admin.Place.LinkedAddresses.List
  },
  SMF33830B: {
    message: 'The country, state, city or community was successfully Listed',
  },
  SMCB44BB1: {
    message: "The 'donation request' was successfully Listed",
  },
  SM8EEEC31: {
    message: "The 'donation request' was successfully Read",
  },
  SMC37772B: {
    message: "The 'person profile' was successfully Read",
  },
  SMC27A776: {
    message: "The 'manual requests' was successfully Read",
  },
};
