import React from 'react';

import { Row, Col } from 'antd';

import Search from '~/components/search';
import Title from '~/components/typography/title';

import { Container, SearchIcon } from './style';

export default function Header({ search, setSearch, GetDataAgain }) {
  return (
    <Container>
      <Row>
        <Col
          style={{ display: 'flex', justifyContent: 'flex-start' }}
          xs={24}
          sm={24}
          md={12}
          lg={12}
          xl={12}
        >
          <Title level={4}>Doações pendentes</Title>
        </Col>
        <Col
          style={{ display: 'flex', justifyContent: 'flex-end' }}
          xs={24}
          sm={24}
          md={12}
          lg={12}
          xl={12}
        >
          <Search
            value={search}
            enterButton={<SearchIcon />}
            onChange={(event) => setSearch(event.target.value)}
            onSearch={() => GetDataAgain()}
          />
        </Col>
      </Row>
    </Container>
  );
}
