import React, { useState, useContext } from 'react';
import { ResponsiveContext } from '~/contexts/ResponsiveContext';
import { ThemeContext } from '~/contexts/ThemeContext';
import { LanguageContext } from '~/contexts/LanguageContext';
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';

import Text from '~/components/typography/text';
import Button from '~/components/button';

import { Drawer } from 'antd';

import {
  Header,
  Content,
  TriggerContainer,
  DrawerFooterContainer,
} from './style';

export default function MyDrawer({
  placement,
  title,
  footer,
  closable,
  headerStyle,
  bodyStyle,
  Trigger,
  DrawerContentComponent,
  DrawerContentComponentParams,
  width,
  widthMobile,
  keyboard,
  children,
  footerCancelButton,
  footerMainButton,
}) {
  const [visible, setVisible] = useState(false);

  const { translate } = useContext(LanguageContext);
  const { isMobile } = useContext(ResponsiveContext);
  const { theme } = useContext(ThemeContext);

  function SetWidth() {
    if (widthMobile) return widthMobile;
    if (isMobile) {
      return '100vw';
    }
    if (width) return width;
    return '35vw';
  }

  function ShowDrawer() {
    setVisible(true);
  }
  function CloseDrawer() {
    setVisible(false);
  }

  const HeaderDesktop = () => {
    return (
      <>
        <Content width="90%">
          <Text
            strong
            fontSize={16}
            color={theme.color.textOnSecondaryBackground}
          >
            {title || ''}
          </Text>
        </Content>
        <Content width="10%">
          <ArrowRightOutlined
            onClick={() => CloseDrawer()}
            color={theme.color.textOnSecondaryBackground}
            size={32}
          />
        </Content>
      </>
    );
  };

  const HeaderMobile = () => {
    return (
      <>
        <Content width="10%">
          <ArrowLeftOutlined
            onClick={() => CloseDrawer()}
            color={theme.color.textOnSecondaryBackground}
            size={32}
          />
        </Content>
        <Content width="90%">
          <Text
            strong
            fontSize={14}
            color={theme.color.textOnSecondaryBackground}
          >
            {title || ''}
          </Text>
        </Content>
      </>
    );
  };

  const titleAtr = title
    ? {
        title: (
          <Header>{isMobile ? <HeaderMobile /> : <HeaderDesktop />}</Header>
        ),
      }
    : {};

  return (
    <>
      <TriggerContainer onClick={ShowDrawer}>{Trigger}</TriggerContainer>
      <Drawer
        {...titleAtr}
        width={SetWidth()}
        footer={
          footer ? (
            <DrawerFooterContainer>
              {footerCancelButton && (
                <Button onClick={CloseDrawer} style={{ marginRight: 8 }}>
                  {translate.basic.cancel.text}
                </Button>
              )}
              {footerMainButton}
            </DrawerFooterContainer>
          ) : (
            false
          )
        }
        closable={closable || false}
        placement={placement}
        visible={visible}
        headerStyle={
          headerStyle || {
            padding: 0,
            margin: 0,
          }
        }
        bodyStyle={bodyStyle || { padding: 20 }}
        keyboard={keyboard}
      >
        {DrawerContentComponent && (
          <DrawerContentComponent
            propertys={DrawerContentComponentParams}
            CloseDrawer={CloseDrawer}
          />
        )}
      </Drawer>
    </>
  );
}
