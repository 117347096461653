import React, { useContext, useEffect, useState } from 'react';
//Contexts
import { AuthContext } from '~/contexts/AuthContext';
//Components
import Loading from '~/components/loading';
//Pages
import AdminDashboard from './AdminDashboard';

import { Container } from './style';

export default function AdminCheck() {
  const { user } = useContext(AuthContext);

  const [checkingPermission, setCheckingPermission] = useState(true);
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    if (user) {
      const numberOfPermissions = Object.entries(user.permission).length;
      if (numberOfPermissions !== 0) {
        setIsAdmin(true);
      }
      setCheckingPermission(false);
    }
  }, [user]);

  return checkingPermission ? (
    <Loading height={'calc(100vh)'} text="Verificando permissão" />
  ) : isAdmin ? (
    <AdminDashboard />
  ) : (
    <Container>
      <p>Redirecionamento...</p>
    </Container>
  );
}
