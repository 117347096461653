import styled from 'styled-components';

export const UploadContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
`;

export const Label = styled.label`
  background-color: ${(props) => props.backgroundColor};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-weight: 500;
  padding: 0 8px;
  margin-right: 5px;
  min-width: ${(props) => `${props.size}px`};
  min-height: ${(props) => `${props.size}px`};

  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;

  border: 2px solid ${(props) => props.borderColor};

  transition: 0.5s all;
  cursor: pointer;

  &:hover {
    border-color: ${(props) => props.hoverColor};
    color: ${(props) => props.hoverColor};
  }

  @media (max-width: 768px) {
    padding: 5px;
    margin: 0;
  }
`;

export const Input = styled.input`
  display: none !important;
`;

export const Column = styled.input`
  display: flex;
  flex-direction: column;
`;

export const Center = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
