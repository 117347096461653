import React, { useContext, useEffect, useState } from 'react';
// Contexts
import { Route, Switch, Redirect } from 'react-router-dom';
import { Layout } from 'antd';
import { ThemeContext } from '~/contexts/ThemeContext';
import { PermissionContext } from '~/contexts/PermissionContext';
// import { LanguageContext } from '~/contexts/LanguageContext';
// Components
import Sidebar from '~/components/sidebar';
import MainNavbar from '~/components/navbar/Main';
import Loading from '~/components/loading';
// Pages
import Home from './pages/Home';
import ActivityTypes from './pages/ActivityTypes/PermissionLayer';
import Permissions from './pages/Permissions/PermissionLayer';
import ChangePassword from './pages/ChangePassword/PermissionLayer';
import Places from './pages/Places/PermissionLayer';
import Products from './pages/Products/PermissionLayer';
import Services from './pages/Services/PermissionLayer';
import PersonInfo from './pages/PersonInfo/PermissionLayer';
import IncompleteDonations from './pages/IncompleteDonations/PermissionLayer';
import PendingDonations from './pages/PendingDonations/PermissionLayer';
import PublishedDonations from './pages/PublishedDonations/PermissionLayer';
import RejectedDonations from './pages/RejectedDonations/PermissionLayer';
import ManualDepositsCashIn from './pages/ManualDepositsCashIn/PermissionLayer';
import ManualDepositsCashOut from './pages/ManualDepositsCashOut/PermissionLayer';
import FinancialReport from './pages/FinancialReport/PermissionLayer';
import InstitutionsSigam from './pages/InstitutionsSigam/PermissionLayer';

import TextEditor from './pages/TextEditor';
import Geo from './pages/Geo';

const { Content } = Layout;

export default function AdminDashboard() {
  const { theme } = useContext(ThemeContext);
  const { CheckPermission } = useContext(PermissionContext);

  const [loadingPage, setLoadingPage] = useState(true);
  const [sidebarItems, setSidebarItems] = useState([]);

  useEffect(() => {
    const adminItems = [
      {
        title: 'Início',
        url: '/inicio',
        component: Home,
        permission: 'ANY',
      },
      {
        title: 'Doações Incompletas',
        url: '/doacoes-incompletas',
        component: IncompleteDonations,
        permission: 'READ-DONATION-REQUEST',
      },
      {
        title: 'Doações Pendentes',
        url: '/doacoes-pendentes',
        component: PendingDonations,
        permission: 'READ-DONATION-REQUEST',
      },
      {
        title: 'Doações Publicadas',
        url: '/doacoes-publicadas',
        component: PublishedDonations,
        permission: 'READ-DONATION-REQUEST',
      },
      {
        title: 'Doações Rejeitadas',
        url: '/doacoes-rejeitadas',
        component: RejectedDonations,
        permission: 'READ-DONATION-REQUEST',
      },
      {
        title: 'Geo - Cidades',
        url: '/geografia/cidades',
        component: Geo,
        permission: 'READ-CSCC',
      },
      {
        title: 'Geo - Comunidades',
        url: '/geografia/comunidades',
        component: Geo,
        permission: 'READ-CSCC',
      },
      {
        title: 'Geo - Estados',
        url: '/geografia/estados',
        component: Geo,
        permission: 'READ-CSCC',
      },
      {
        title: 'Geo - Países',
        url: '/geografia/paises',
        component: Geo,
        permission: 'READ-CSCC',
      },
      {
        title: 'Tipos de Atividades',
        url: '/tipos-de-atividades',
        component: ActivityTypes,
        permission: 'READ-ACTIVITY-TYPE',
      },
      {
        title: 'Locais',
        url: '/locais',
        component: Places,
        permission: 'READ-PLACE',
      },
      {
        title: 'Permissões',
        url: '/permissoes',
        component: Permissions,
        permission: 'READ-PERSON-PERMISSION',
      },
      {
        title: 'Produtos',
        url: '/produtos',
        component: Products,
        permission: 'READ-PRODUCT-SERVICE-MODEL',
      },
      {
        title: 'Tipos de Atividades',
        url: '/tipos-de-atividades',
        component: ActivityTypes,
        permission: 'READ-ACTIVITY-TYPE',
      },
      {
        title: 'Serviços',
        url: '/servicos',
        component: Services,
        permission: 'READ-PRODUCT-SERVICE-MODEL',
      },
      {
        title: 'Alterar senha',
        url: '/alterar-senha',
        component: ChangePassword,
        permission: 'GOD',
      },
      {
        title: ' Cash-In Manual',
        url: '/depositos-manuais-cash-in',
        component: ManualDepositsCashIn,
        permission: 'GOD',
      },
      {
        title: ' Cash-Out Manual',
        url: '/depositos-manuais-cash-out',
        component: ManualDepositsCashOut,
        permission: 'GOD',
      },
      {
        title: 'Editor de texto',
        url: '/editor-de-texto',
        component: TextEditor,
        permission: 'GOD',
      },
      {
        title: 'Relatório financeiro',
        url: '/relatorio-financeiro',
        component: FinancialReport,
        permission: 'GOD',
      },
      {
        title: 'Insituições Sigam',
        url: '/instituicoes-sigam',
        component: InstitutionsSigam,
        permission: 'GOD',
      },
    ];
    async function PrepareSidebarItems() {
      const array = [];
      array.push({
        index: 0,
        title: 'First',
      });
      adminItems.map((item) => {
        if (CheckPermission(item.permission)) {
          let newItem = {};
          newItem.index = array.length;
          newItem.title = item.title;
          newItem.url = item.url;
          return array.push(newItem);
        }
        return null;
      });
      array.push({
        index: array.length,
        title: 'Last',
      });
      setSidebarItems(array);
      setLoadingPage(false);
    }
    PrepareSidebarItems();
  }, [CheckPermission]);

  if (loadingPage) {
    return <Loading text="Arrumando as coisas" />;
  }

  return (
    <div>
      <Layout
        style={{
          backgroundColor: theme.color.backgroundPrimary,
          height: '100vh',
        }}
      >
        <Sidebar items={sidebarItems} />
        <Layout
          style={{
            padding: 10,
            backgroundColor: theme.color.backgroundSecondary,
          }}
        >
          <MainNavbar />
          <Content
            style={{
              overflow: 'auto',
            }}
          >
            <Switch>
              <Route exact path="/">
                <Redirect to="/inicio" />
              </Route>
              <Route exact path="/inicio" component={Home} />
              <Route exact path="/permissoes" component={Permissions} />
              <Route path="/produtos" component={Products} />
              <Route path="/servicos" component={Services} />
              <Route path="/tipos-de-atividades" component={ActivityTypes} />
              <Route
                path="/depositos-manuais-cash-in"
                component={ManualDepositsCashIn}
              />
              <Route
                path="/depositos-manuais-cash-out"
                component={ManualDepositsCashOut}
              />
              <Route path="/locais" component={Places} />
              <Route path="/doacoes-pendentes" component={PendingDonations} />
              <Route path="/alterar-senha" component={ChangePassword} />
              <Route path="/pessoa/:personId" component={PersonInfo} />
              <Route path="/editor-de-texto" component={TextEditor} />
              <Route path="/relatorio-financeiro" component={FinancialReport} />

              <Route
                path="/doacoes-incompletas"
                component={IncompleteDonations}
              />
              <Route
                path="/doacoes-publicadas"
                component={PublishedDonations}
              />
              <Route path="/doacoes-rejeitadas" component={RejectedDonations} />
              <Route path="/geografia" component={Geo} />
            </Switch>
          </Content>
        </Layout>
      </Layout>
    </div>
  );
}
