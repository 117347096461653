import React, { memo, useContext } from 'react';

import { BsFileEarmarkPlus } from 'react-icons/bs';

import Text from '~/components/typography/text';
import { FileGroup } from '~/components/file';

import imageCompression from 'browser-image-compression';

import { ThemeContext } from '~/contexts/ThemeContext';

import { Label, Input, UploadContainer } from './style';

const UploadInput = ({ files, size, onSelect, onDelete }) => {
  const { theme } = useContext(ThemeContext);

  async function CompressFile(file) {
    await imageCompression.getExifOrientation(file);

    const options = {
      maxWidthOrHeight: 600,
      maxSizeMB: 1.5,
      useWebWorker: true,
      initialQuality: 0.7,
    };

    const output = await imageCompression(file, options);

    output.lastModifiedDate = new Date();

    const outputFile = new File([output], file.name, {
      type: file.type,
      lastModified: Date.now(),
    });

    return outputFile;
  }

  const onChangeFile = async (files) => {
    if (files.type.includes('image')) {
      const file = await CompressFile(files);

      return onSelect(file);
    }

    return onSelect(files);
  };

  return (
    <UploadContainer>
      <Label
        size={size || 120}
        borderColor={theme.color.borderColor}
        backgroundColor={theme.color.backgroundSecondary}
        hoverColor={theme.color.primary}
        htmlFor="upload"
      >
        <BsFileEarmarkPlus
          style={{
            fontSize: '18pt',
            marginBottom: 5,
          }}
        />
        <Text>Anexar arquivo</Text>
      </Label>
      <Input
        type="file"
        value=""
        onChange={(event) => onChangeFile(event.target.files[0])}
        name="upload"
        id="upload"
        required
      />
      {files && <FileGroup size={size} files={files} onDelete={onDelete} />}
    </UploadContainer>
  );
};

export default memo(UploadInput);
