import React, { useEffect, useState } from 'react';
// Libraries
import { Row } from 'antd';
// Components
import Title from '~/components/typography/title';
import Text from '~/components/typography/text';
import Space from '~/components/space';
import Card from '~/components/card';

const possibleParameters = {
  kind: 'Método',
  pixKeyType: 'Tipo de Chave do PIX',
  pixKey: 'Chave do PIX',
  description: 'Descrição',
  bank: 'Banco',
  agency: 'Agência',
  accountNumber: 'Conta',
  accountType: 'Tipo de conta',
  fullName: 'Nome',
  mainDocument: 'Documento',
  date: 'Data',
  hour: 'Hora',
};

export default function PaymentTypeDetails({ deposit }) {
  const [parametersReceived, setParametersReceived] = useState([]);
  useEffect(() => {
    async function PrepareParameters() {
      let parameters = [];

      for (const keyOtherParameters in deposit.settings) {
        if (possibleParameters.hasOwnProperty(keyOtherParameters)) {
          parameters.push(keyOtherParameters);
        }
      }
      setParametersReceived(parameters);
    }
    PrepareParameters();
  }, [deposit.settings]);

  const bodyStyle = {
    padding: 0,
  };

  const cardStyle = {
    margin: '15px 0',
  };

  return (
    <Card style={cardStyle} bodyStyle={bodyStyle} bordered={false}>
      <Row>
        <Title level={4}>Informações adicionais</Title>
      </Row>
      {parametersReceived.map((parameter) => (
        <Row key={parameter}>
          <Space>
            <Text strong>{possibleParameters[parameter]}: </Text>
            <Text>{deposit.settings[parameter]}</Text>
          </Space>
        </Row>
      ))}
    </Card>
  );
}
