import React, { memo } from 'react';

import { SelectDropdown } from '~/components/dropdown';

function Variables({ mainEditor, variables, editorRef, setValue }) {
  function SetMention(event) {
    mainEditor.focus();

    console.log(`setValue`, setValue);

    mainEditor.ownerDocument.execCommand(
      'insertHTML',
      false,
      `<span >${event.target.value}&nbsp;</span>`
    );

    // setValue(editorRef.current.innerHTML);
  }

  return (
    <SelectDropdown
      menuTitle="Variáveis"
      dropdownId="variables"
      items={variables}
      ItemOnClick={SetMention}
    />
  );
}

export default memo(Variables);
