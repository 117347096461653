import React, { useContext, useEffect, useState } from 'react';
//Libraries
import { Divider } from 'antd';
// Contexts
import { DebugContext } from '~/contexts/DebugContext';
import { AxiosContext } from '~/contexts/AxiosContext';
// Own Components
import Header from './components/Header';
import Content from './components/Content';
// Components
import Card from '~/components/card';
import Loading from '~/components/loading';

export default function Services() {
  const { CallToTheAPI } = useContext(AxiosContext);
  const { debug } = useContext(DebugContext);
  let isDebug = debug.Administrator.Services;

  const [loadingContent, setLoadingContent] = useState(true);
  const [data, setData] = useState();
  const [searchInput, setSearchInput] = useState();
  const [searchStatus, setSearchStatus] = useState('ALL');
  const [pageParameters, setPageParameters] = useState({
    currentPage: 1,
    totalElements: 0,
    limit: 10,
  });

  useEffect(() => {
    async function GetData() {
      if (isDebug) console.log('🚀 API: Get Services');
      const params = {
        operation: ['ProductOrService', 'Get'],
        data: {
          filter: {
            kind: 'SERVICE',
            searchString: searchInput,
            status: searchStatus,
          },
          pagination: {
            sort: {
              by: 'name',
              order: 'asc',
            },
          },
        },
      };
      const response = await CallToTheAPI(params);
      if (isDebug) console.log('  ⚡ API Response (Get Services): ', response);
      if (response.success) {
        setPageParameters({
          currentPage: response.body.psModels.currentPage,
          totalElements: response.body.psModels.totalElements,
          limit: response.body.psModels.limit,
        });
        setData(response.body.psModels.elements);
        setLoadingContent(false);
      }
    }
    GetData();
  }, [CallToTheAPI, isDebug, searchInput, searchStatus]);

  async function GetDataAgain(newPageParameters) {
    if (isDebug) console.log('🚀 API: Get Services (Again)');
    const params = {
      operation: ['ProductOrService', 'Get'],
      data: {
        filter: {
          kind: 'SERVICE',
          searchString: searchInput,
          status: searchStatus,
        },
        pagination: {
          limit: newPageParameters
            ? newPageParameters.limit
            : pageParameters.limit,
          page: newPageParameters
            ? newPageParameters.currentPage
            : pageParameters.currentPage,
          sort: {
            by: 'name',
            order: 'asc',
          },
        },
      },
    };
    const response = await CallToTheAPI(params);
    if (isDebug) console.log('  ⚡ API Response: ', response);
    if (response.success) {
      setPageParameters({
        ...pageParameters,
        currentPage: response.body.psModels.currentPage,
        totalElements: response.body.psModels.totalElements,
        limit: response.body.psModels.limit,
      });
      setData(response.body.psModels.elements);
      setLoadingContent(false);
    }
  }

  return (
    <div>
      <Card>
        <Header
          GetDataAgain={GetDataAgain}
          setSearchInput={setSearchInput}
          searchStatus={searchStatus}
          setSearchStatus={setSearchStatus}
        />
        <Divider />
        {loadingContent ? (
          <Loading text="Carregando serviços" />
        ) : (
          <Content
            data={data}
            GetDataAgain={GetDataAgain}
            pageParameters={pageParameters}
          />
        )}
      </Card>
    </div>
  );
}
