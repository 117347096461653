import React, { useState, createContext, useEffect } from 'react';

export const ResponsiveContext = createContext();

const ResponsiveContextProvider = (props) => {
  const [isMobile, setIsMobile] = useState(false);

  const updateWidth = () => {
    if (window.innerWidth < 680) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  useEffect(() => {
    if (window.innerWidth < 680) {
      setIsMobile(true);
    }
  }, []);

  useEffect(() => {
    window.addEventListener('resize', updateWidth);
    return () => window.removeEventListener('resize', updateWidth);
  });

  return (
    <ResponsiveContext.Provider value={{ isMobile }}>
      {props.children}
    </ResponsiveContext.Provider>
  );
};

export default ResponsiveContextProvider;
