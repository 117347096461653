import React, { useContext } from 'react';
//Libraries
import { Avatar, Alert } from 'antd';
//Contexts
import { ThemeContext } from '~/contexts/ThemeContext';
//Components
import Text from '~/components/typography/text';
//Utils
import { CapitalizeFirstLetter } from '~/utils/GeneralFunctions';

export default function Header({ foundPerson }) {
  const { theme } = useContext(ThemeContext);
  return (
    <div>
      {foundPerson.permissions ? (
        <div>
          <Alert
            message="Já existem permissões associadas a esse usuário"
            type="warning"
            showIcon
            closable
          />
        </div>
      ) : (
        <></>
      )}
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          marginTop: 10,
        }}
      >
        <div>
          {foundPerson.avatar ? (
            <Avatar src={foundPerson.avatar} size={32} />
          ) : (
            <Avatar
              style={{
                color: theme.color.textOnPrimaryBackground,
                backgroundColor: theme.color.primary,
              }}
              size={32}
            >
              {foundPerson.social_name
                ? foundPerson.social_name.charAt(0)
                : foundPerson.name.charAt(0)}
            </Avatar>
          )}
        </div>
        <div style={{ marginLeft: 10 }}>
          <Text level={4}>
            {foundPerson.social_name
              ? CapitalizeFirstLetter(foundPerson.social_name)
              : CapitalizeFirstLetter(foundPerson.name)}
          </Text>
        </div>
      </div>
    </div>
  );
}
