import React, { useEffect, useState, useContext } from 'react';
//Libraries
import { Divider } from 'antd';
//Contexts
import { DebugContext } from '~/contexts/DebugContext';
import { AxiosContext } from '~/contexts/AxiosContext';
//Own Components
import Header from './components/Header';
import Content from './components/Content';
//Components
import Loading from '~/components/loading';
import Card from '~/components/card';

export default function Permissions() {
  const { CallToTheAPI } = useContext(AxiosContext);
  const { debug } = useContext(DebugContext);
  let isDebug = debug.Administrator.Permissions;

  const [loadingContent, setLoadingContent] = useState(true);
  const [peopleWithPermissions, setPeopleWithPermissions] = useState([]);

  useEffect(() => {
    async function GetPermissions(person) {
      if (isDebug) console.log(`🚀 API: Get Permissions (${person.name}) `);
      const params = {
        operation: ['Manage', 'Permission', 'Get'],
        urlParams: {
          personId: person.id,
        },
      };
      const response = await CallToTheAPI(params);
      if (isDebug)
        console.log(
          `  ⚡ API Response (Get Permissions (${person.name}) ): `,
          response
        );
      if (response.success) {
        return response.body.permission;
      }
    }
    async function PickUpPeopleWithPermissions() {
      if (isDebug) console.log('🚀 API: Pick Up  People With Permissions');
      const params = {
        operation: ['Manage', 'Permission', 'SearchPerson'],
        data: {
          pagination: {
            sort: {
              by: 'name',
              order: 'asc',
            },
          },
        },
      };
      const response = await CallToTheAPI(params);
      //console.log(response);
      if (isDebug)
        console.log(
          '  ⚡ API Response (Pick Up  People With Permissions): ',
          response
        );
      if (response.success) {
        const array = [];
        for (let i = 0; i < response.body.peopleList.elements.length; i++) {
          let item = response.body.peopleList.elements[i];
          item.permissions = await GetPermissions(item);
          array.push(item);
        }
        setPeopleWithPermissions(array);
        return setLoadingContent(false);
      }
    }
    PickUpPeopleWithPermissions();
  }, [CallToTheAPI, isDebug]);

  async function GetPermissionsAgain(person) {
    if (isDebug) console.log(`🚀 API: Get Permissions Again (${person.name}) `);
    const params = {
      operation: ['Manage', 'Permission', 'Get'],
      urlParams: {
        personId: person.id,
      },
    };
    const response = await CallToTheAPI(params);
    if (isDebug)
      console.log(
        `  ⚡ API Response (Get Permissions Again (${person.name}) ): `,
        response
      );
    if (response.success) {
      return response.body.permission;
    }
  }

  async function PickUpPeopleWithPermissionsAgain() {
    if (isDebug) console.log('🚀 API: Pick Up  People With Permissions Again');
    const params = {
      operation: ['Manage', 'Permission', 'SearchPerson'],
      data: {
        pagination: {
          sort: {
            by: 'name',
            order: 'asc',
          },
        },
      },
    };
    const response = await CallToTheAPI(params);
    if (isDebug)
      console.log(
        '  ⚡ API Response (Pick Up  People With Permissions Again): ',
        response
      );
    if (response.success) {
      const array = [];
      for (let i = 0; i < response.body.peopleList.elements.length; i++) {
        let item = response.body.peopleList.elements[i];
        item.permissions = await GetPermissionsAgain(item);
        array.push(item);
      }
      setPeopleWithPermissions(array);
      return setLoadingContent(false);
    }
  }

  return (
    <div>
      <Card>
        <Header
          PickUpPeopleWithPermissionsAgain={PickUpPeopleWithPermissionsAgain}
        />
        <Divider />
        {loadingContent ? (
          <Loading />
        ) : (
          <Content
            peopleWithPermissions={peopleWithPermissions}
            PickUpPeopleWithPermissionsAgain={PickUpPeopleWithPermissionsAgain}
          />
        )}
      </Card>
    </div>
  );
}
