import React, { useContext, memo } from 'react';
// Libraries
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
// Standalone components
import { LoadContainer } from './style';
import { ThemeContext } from '~/contexts/ThemeContext';

function AntLoading({ height, text }) {
  const { theme } = useContext(ThemeContext);
  return (
    <LoadContainer height={height || '300px'}>
      <Spin
        style={{ color: theme.color.primary }}
        indicator={
          <LoadingOutlined
            style={{ fontSize: 24, color: theme.color.primary }}
            spin
          />
        }
        tip={text || 'Carregando...'}
      />
    </LoadContainer>
  );
}

export default memo(AntLoading);
