import React from 'react';
//View
import Search from './view/Search';
import AddingPermissions from './view/AddingPermissions';

export default function DrawerContent({ view, setFoundPerson, foundPerson, form }) {

    const RenderView = () => {
        switch (view) {
            case 'Search':
                return <Search setFoundPerson={setFoundPerson} foundPerson={foundPerson} />
            case 'Adding Permissions':
                return <AddingPermissions foundPerson={foundPerson} form={form} />
            default:
                return null;
        }
    }

    return (
        <div>
            {RenderView()}
        </div>
    )
}