import React, { memo } from 'react';
// Libraries
import { Route, Switch, useRouteMatch, Redirect } from 'react-router-dom';
//Tabs
import Countries from './Pages/Countries';
import States from './Pages/States';
import Cities from './Pages/Cities';
import Locales from './Pages/Locales';

function Geo() {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={path}>
        <Redirect to={`${path}/paises`} />
      </Route>
      <Route path={`${path}/paises`} component={() => <Countries />} />
      <Route path={`${path}/estados`} component={() => <States />} />
      <Route path={`${path}/cidades`} component={() => <Cities />} />
      <Route path={`${path}/comunidades`} component={() => <Locales />} />
    </Switch>
  );
}

export default memo(Geo);
