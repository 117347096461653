import React, { useContext } from 'react';
//Libraries
import { Space } from 'antd';
//Contexts
import { PermissionContext } from '~/contexts/PermissionContext';
//Components
import EditButton from './components/EditButton';
import DeleteButton from './components/DeleteButton';
import AddressManager from './components/AddressManager';

export default function Actions({ data, GetDataAgain }) {
  const { CheckPermission } = useContext(PermissionContext);
  return (
    <span
      style={{
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
      }}
    >
      {CheckPermission('UPDATE-PLACE') && (
        <AddressManager itemData={data} GetDataAgain={GetDataAgain} />
      )}
      <Space>
        {CheckPermission('UPDATE-PLACE') && (
          <EditButton itemData={data} GetDataAgain={GetDataAgain} />
        )}
        {CheckPermission('DELETE-PLACE') && (
          <DeleteButton itemData={data} GetDataAgain={GetDataAgain} />
        )}
      </Space>
    </span>
  );
}
