import API from '~/environments/APIs';

const Places = {
  List: {
    baseURL: API.socialMe,
    url: '/manage/location/places',
    method: 'post',
    timeout: '50000',
  },
  Add: {
    baseURL: API.socialMe,
    url: '/manage/location/place',
    method: 'post',
    timeout: '50000',
  },
  Edit: {
    baseURL: API.socialMe,
    url: '/manage/location/place/placeId',
    method: 'put',
    timeout: '50000',
  },
  Delete: {
    baseURL: API.socialMe,
    url: '/manage/location/place/placeId',
    method: 'delete',
    timeout: '50000',
  },
  LinkedAddresses: {
    List: {
      baseURL: API.socialMe,
      url: '/manage/location/place/placeId/location/addresses',
      method: 'post',
      timeout: '50000',
    },
    Add: {
      baseURL: API.socialMe,
      url: '/manage/location/place/placeId/location/address',
      method: 'post',
      timeout: '50000',
    },
    Edit: {
      baseURL: API.socialMe,
      url: '/manage/location/place/placeId/location/address/addressId',
      method: 'put',
      timeout: '50000',
    },
    Delete: {
      baseURL: API.socialMe,
      url: '/manage/location/place/placeId/location/address/addressId',
      method: 'delete',
      timeout: '50000',
    },
  },
};

export default Places;
