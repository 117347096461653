import { format, addHours } from 'date-fns';

export function CapitalizeFirstLetter(string) {
  const stringLowerCase = string.toLowerCase();
  var array1 = stringLowerCase.split(' ');
  var newarray1 = [];

  for (var x = 0; x < array1.length; x++) {
    newarray1.push(array1[x].charAt(0).toUpperCase() + array1[x].slice(1));
  }
  return newarray1.join(' ');
}

export function CapitalizeString(str) {
  let string = `${str}`;
  const stringLowerCase = string.toLowerCase();
  var array1 = stringLowerCase.split(' ');
  var newarray1 = [];

  for (var x = 0; x < array1.length; x++) {
    newarray1.push(array1[x].charAt(0).toUpperCase() + array1[x].slice(1));
  }
  return newarray1.join(' ');
}

export const ShowHoursInBrazilianFormat = (date) => {
  return format(new Date(date), 'HH:mm:ss');
};

export function WalletFormatConversion(value) {
  //console.log('value -> ', value)
  //console.log('isNaN -> ', isNaN(value))
  //console.log('isInteger -> ' + Number.isInteger(value))
  //console.log('typeof -> ' + typeof value)
  //console.log('Division -> ' + value / 1000000)
  if (isNaN(value)) {
    return value;
  }
  const division = value / 1000000;
  if (division % 1 === 0) {
    //console.log('É inteiro: ' + division);
    const newValue = `${division},00`;
    //console.log('newValue -> ', newValue)
    return newValue;
  } else {
    //console.log('DECIMAL' + division);
    const newValue = division.toFixed(2);
    //console.log('newValue -> ', newValue)
    return newValue.replace('.', ',');
  }
}

export function WalletFormatParse(value) {
  value = parseFloat(value.replace(',', '.'));
  if (value % 1 === 0) {
    const newValue = value * 1000000;
    return newValue;
  } else {
    const newValue = value * 1000000;
    return newValue;
  }
}

export const DateParseAmericanFormat = (value) => {
  const date = value.split('/');
  const newDate = `${date[2]}-${date[1]}-${date[0]}`;
  return newDate;
};

export const ShowDateInBrazilianFormat = (date) => {
  const newDate = addHours(new Date(date), 3);
  return format(new Date(newDate), 'dd/MM/yyyy');
};

export const ValidateDate = (value, past) => {
  if (value) {
    if (value.includes('_')) {
      return false;
    }
    const date = value.split('/');
    let year;
    if (past) {
      year = new Date().getFullYear();
    } else {
      const yearActive = new Date().getFullYear();
      year = yearActive + 50;
    }
    if (
      date[2] > year ||
      date[1] > 12 ||
      date[1] === '00' ||
      date[0] === '00' ||
      date[0] > 31
    ) {
      return false;
    }
    return true;
  }
  return false;
};

export const RemoveWhatIsNotNumber = (value) => {
  return value.replace(/[^\d]+/g, '');
};

export const GetIcon = (icon) => {
  switch (icon) {
    default:
    case 'product':
      return '/assets/img/icons/product.svg';
    case 'service':
      return '/assets/img/icons/service.svg';
  }
};

export const GetDocumentImage = (document) => {
  switch (document) {
    case 'cpf':
      return '/assets/img/documents/cpf.png';
    case 'rg':
      return '/assets/img/documents/rg.png';
    case 'cns':
      return '/assets/img/documents/cns.png';
    case 'cnh':
      return '/assets/img/documents/cnh.png';
    case 'passport':
    case 'passaporte':
      return '/assets/img/documents/passaporte.png';
    case 'tituloDeEleitor':
    case 'voterCertificate':
      return '/assets/img/documents/tituloDeEleitor.png';
    case 'carteiraDeTrabalho':
    case 'ctps':
      return '/assets/img/documents/carteiraDeTrabalho.png';
    case 'certidaoDeNascimento':
    case 'birthCertificate':
      return '/assets/img/documents/certidaoDeNascimento.png';
    default:
      return 'error';
  }
};

export const GetCarrierImage = (carrier) => {
  switch (carrier) {
    case 'claro':
      return '/assets/img/carriers/claro.png';
    case 'tim':
      return '/assets/img/carriers/tim.png';
    case 'oi':
      return '/assets/img/carriers/oi.png';
    case 'vivo':
      return '/assets/img/carriers/vivo.png';
    case 'nextel':
      return '/assets/img/carriers/nextel.png';
    default:
      return 'error';
  }
};
