import React from 'react';
//Components
import FormItem from '~/components/form/FormItem';
import Input from '~/components/input';
import TextArea from '~/components/textArea';
import Select from '~/components/select';
import Checkbox from '~/components/checkbox';
import DynamicTags from '~/components/tags/dynamicTags';

export default function ProductsFormItems({ tags, setTags, akas, setAkas }) {
  const AgentsOptions = [
    {
      label: 'Agente de teste 1',
      value: '1',
    },
    {
      label: 'Agente de teste 2',
      value: '2',
    },
    {
      label: 'Agente de teste 3',
      value: '3',
    },
  ];

  return (
    <div>
      <FormItem
        label="Nome"
        name={['name']}
        rules={[
          {
            required: true,
            message: 'Um nome é obrigatório',
          },
        ]}
        item={<Input />}
      />
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <FormItem
          label="Agente Nacional"
          name={['national_agent_id']}
          item={<Select width="200px" options={AgentsOptions} />}
        />
        <FormItem
          label="Status"
          name="status"
          valuePropName="checked"
          item={<Checkbox text={'Ativo'} />}
        />
      </div>
      <FormItem label="Descrição" name={['description']} item={<TextArea />} />
      <FormItem label="Avatar" name={['avatar']} item={<Input />} />

      <FormItem
        label="Tags"
        name={['tags']}
        item={<DynamicTags tags={tags} setTags={setTags} />}
      />

      <FormItem
        label="AKAS"
        name={['akas']}
        item={<DynamicTags tags={akas} setTags={setAkas} />}
      />
    </div>
  );
}
