import API from '~/environments/APIs';

const Manage = {
    PersonSearch: {
        Simple: {
            baseURL: API.socialMe,
            url: '/manage/person/search/simple',
            method: 'post',
            timeout: '50000',
        }
    },
    Permission: {
        Get: {
            baseURL: API.socialMe,
            url: '/manage/permissions/person/personId',
            method: 'get',
            timeout: '50000',
        },
        SearchPerson: {
            baseURL: API.socialMe,
            url: '/manage/permission/person/search',
            method: 'post',
            timeout: '50000',
        },
        Update:{
            baseURL: API.socialMe,
            url: '/manage/permissions/person/personId',
            method: 'put',
            timeout: '50000',
        }
    }
};

export default Manage;
