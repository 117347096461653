export default {
  SM00EF13A: {
    message: 'Browser token successfully created',
  },
  SM9DC07A7: {
    message: 'Successfully registered person',
  },
  SMC463647: {
    message: 'The product/service model was successfully created',
    //ProductOrService.Add
  },
  SM0CDE9E7: {
    message: 'The activity type was successfully created',
    //Admin.ActivityType.Add
  },
  SM927FEDF: {
    message: 'The address was successfully created',
  },
  SM2FD0F9F: {
    message: "The 'country, state, city or community' was successfully created",
  },
  SMDD65D86: {
    message: "The 'donation request product/service' was successfully created",
  },
  SMC9DFC93: {
    message: "The 'file' was successfully created",
  },
};
