//Libraries
import axios from 'axios';
//Services
import Request from '~/services/requests';
import ResponseCodeSuccess from '~/services/responseCode/success';
import ResponseCodeError from '~/services/responseCode/error';
//Environments
import System from '~/environments/System';
import Debug from '~/environments/Debug';
//Utils
import Cookie from '~/utils/Cookie';

const isDebug = Debug.Hooks.useSocialMeAPI;

async function PrepareTheHeader() {
  if (isDebug) console.log('📒 API: Preparing the header');
  let headers;
  const UserTokenCookie = await Cookie.Get(System.UserCookieName);
  const BrowserTokenCookie = await Cookie.Get(System.BrowserCookieName);
  if (BrowserTokenCookie) {
    if (UserTokenCookie) {
      headers = {
        brwsrtkn: BrowserTokenCookie,
        usrtkn: UserTokenCookie,
      };
    } else {
      headers = { brwsrtkn: BrowserTokenCookie };
    }
  }
  if (isDebug) console.log('  API Header: ', headers);
  return headers;
}
async function PrepareTheURL(url, urlParams) {
  if (isDebug) console.log('📙 API: Preparing the URL');
  let newURL = url;

  for (const props in urlParams) {
    newURL = newURL.replace(props, urlParams[props]);
  }
  if (isDebug) console.log('  API URL: ', newURL);
  return newURL;
}
async function PrepareTheParameters(paramsReceived) {
  if (isDebug) console.log('📘 API: Preparing the parameters');
  const operationPath = paramsReceived.operation;
  const operationParams = operationPath.reduce(
    (obj, operationPath) => obj[operationPath],
    Request
  );
  const options = {};
  options.headers = await PrepareTheHeader();
  if (paramsReceived.urlParams) {
    options.url = await PrepareTheURL(
      operationParams.url,
      paramsReceived.urlParams
    );
  } else {
    options.url = operationParams.url;
  }
  if (paramsReceived.data) {
    options.data = paramsReceived.data;
  }
  options.baseURL = operationParams.baseURL;
  options.method = operationParams.method;
  options.timeout = operationParams.timeout;
  return options;
}
async function GetCodeInformation(code) {
  for (const keyFather in ResponseCodeSuccess) {
    for (const key in ResponseCodeSuccess[keyFather]) {
      if (key === code) {
        let codeInformation = ResponseCodeSuccess[keyFather][key];
        codeInformation.success = true;
        return codeInformation;
      }
    }
  }
  for (const keyFather in ResponseCodeError) {
    for (const key in ResponseCodeError[keyFather]) {
      if (key === code) {
        let codeInformation = ResponseCodeError[keyFather][key];
        codeInformation.success = false;
        return codeInformation;
      }
    }
  }
}

export default async function useSocialMeAPI(paramsReceived) {
  const treatedParams = await PrepareTheParameters(paramsReceived);

  const response = await axios(treatedParams)
    .then(async (res) => {
      const { code } = res.data.header;
      const codeInformation = await GetCodeInformation(code);
      if (codeInformation) {
        res.data.success = codeInformation.success;
        res.data.message = codeInformation.message;
      }
      if (isDebug) console.log('📗 API Response: ', res.data);
      return res.data;
    })
    .catch(async (error) => {
      if (error.response) {
        const { code } = error.response.data.header;
        const codeInformation = await GetCodeInformation(code);
        if (codeInformation) {
          error.response.data.success = codeInformation.success;
          error.response.data.message = codeInformation.message;
        }
        error.response.data.status = error.response.status;
        const errorInformation = {
          data: error.response.data,
          status: error.response.status,
          headers: error.response.headers,
        };
        if (isDebug) console.log('📕 API Error: ', errorInformation);
        return error.response.data;
      }
      let errorResponse = {};
      errorResponse.error = error;
      errorResponse.success = false;
      errorResponse.message = 'Timeout';
      if (isDebug) console.log('📕 API Error (Timeout): ', errorResponse);
      return errorResponse;
    });

  return response;
}
