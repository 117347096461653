import React from 'react';

import { message } from 'antd';

import SocialMeAPI from '~/utils/SocialMeAPI';

import { UploadArray } from '~/components/uploadInput';

export default function Header({ isDebug, donation, setDonation }) {
  async function DeleteImage(removedImage) {
    const images = donation.images.filter((image) => image !== removedImage);

    const params = {
      operation: ['Donation', 'Edit'],
      urlParams: {
        donationId: donation.id,
      },
      data: {
        images,
      },
    };

    if (isDebug) console.log('📦 Params: ', params);

    const response = await SocialMeAPI(params);

    if (isDebug) console.log('🚀 API Response, Donation Edit: ', response);

    if (response.success) {
      if (isDebug) console.log('Image was removed successfully');

      message.success('Image removida com sucesso');
      setDonation({
        ...donation,
        images: response.body.donationRequest.images,
      });
    } else if (isDebug) {
      console.log('Image was not removed');
    }
  }

  async function AddImageInDonation(file) {
    const data = new FormData();

    data.append('file', file);

    let params = {
      operation: ['Donation', 'File'],
      urlParams: {
        donationId: donation.id,
      },
      data,
    };

    if (isDebug) console.log('📦 Params: ', params);

    const response = await SocialMeAPI(params);

    if (isDebug) console.log('🚀 API Response, Donation File: ', response);

    let images = '';

    if (donation.images) {
      images = [...donation.images, response.body.newFile];
    } else {
      images = [response.body.newFile];
    }

    if (file === null) {
      message.error('Selecione uma image clicando no Retângulo.');
    } else {
      params = {
        operation: ['Donation', 'Edit'],
        urlParams: {
          donationId: donation.id,
        },
        data: {
          images,
        },
      };

      if (isDebug) console.log('📦 Params: ', params);

      const anotherResponse = await SocialMeAPI(params);

      if (isDebug)
        console.log('🚀 API Response, Donation File: ', anotherResponse);

      if (anotherResponse.success) {
        if (isDebug) console.log('Image add on donation');
        message.success('Uma nova imagem foi adicionada em sua doação.');
        setDonation({
          ...donation,
          images: anotherResponse.body.donationRequest.images,
        });
      } else if (isDebug) {
        console.log('Image was not add in donation');
      }
    }
  }

  return (
    <UploadArray
      onSelect={AddImageInDonation}
      onDelete={DeleteImage}
      name="upload"
      files={donation.images}
    />
  );
}
