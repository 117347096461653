import Admin from './admin';
import Auth from './auth';
import Donation from './donation';
import Geo from './geo';
import Location from './location';
import Person from './person';
import ProductOrService from './productOrService';
import Manage from './manage';
import Profile from './profile';
import Places from './places';
import System from './system';

const Request = {
  Admin,
  Auth,
  Donation,
  Geo,
  Location,
  Person,
  ProductOrService,
  Manage,
  Profile,
  Places,
  System,
};

export default Request;
