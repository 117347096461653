import React, { useContext } from 'react';
// Contexts
import { ThemeContext } from '~/contexts/ThemeContext';
//Libraries
import { Form } from 'antd';

export default function FormItem({
  style,
  name,
  label,
  item,
  rules,
  valuePropName,
  hasFeedback,
  getValueProps,
}) {
  const { theme } = useContext(ThemeContext);
  if (style) {
    style.color =
      (theme && theme.color && theme.color.textOnSecondaryBackground) ||
      '#303030';
  }
  return (
    <Form.Item
      validateFirst
      hasFeedback={hasFeedback}
      getValueProps={getValueProps}
      name={name}
      label={label}
      style={
        style || {
          color:
            (theme && theme.color && theme.color.textOnSecondaryBackground) ||
            '#303030',
        }
      }
      rules={rules}
      valuePropName={valuePropName}
    >
      {item}
    </Form.Item>
  );
}
