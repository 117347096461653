import React, { useState, useContext, useEffect, memo } from 'react';
// Contexts
import { AxiosContext } from '~/contexts/AxiosContext';
import { AuthContext } from '~/contexts/AuthContext';
// Libraries
import { Divider, message, Pagination, Input } from 'antd';
// Own Components
import Header from './components/Header';
import AddButton from './components/AddButton';
import ActionCellEditingAndDeleting from './components/ActionCellEditingAndDeleting';
//Components
import Card from '~/components/card';
import Title from '~/components/typography/title';
import Loading from '~/components/loading';
import Breadcrumb from '~/components/breadcrumb';
import Table from '~/components/table';

const { Search } = Input;

function PageStructureType1({
  // General
  parametersToGetTheData,
  getTheDataMessages,
  pathToTheData,
  pathToThePageParameters,
  FormItems,
  // Header
  title,
  breadcrumbItems,
  addButtonText,
  addingDataInitialValues,
  parametersForAddingData,
  addingDataMessages,
  search,
  searchPlaceholder,
  parametersToObtainTheDataWithSearch,
  // Content
  tableColumns,
  actionCellType,
  paramsIdName,
  operationForDeleteData,
  deleteDataMessages,
  operationForEditData,
  editDataMessages,
  pagination,
}) {
  // Contexts
  const { CallToTheAPI } = useContext(AxiosContext);
  const { user } = useContext(AuthContext);
  // Variables
  const [load, setLoad] = useState(true);
  const [loadingTable, setLoadingTable] = useState(false);
  const [data, setData] = useState();
  const [searchInput, setSearchInput] = useState('');
  const [pageParameters, setPageParameters] = useState();
  const [preparedTheActionCell, setPreparedTheActionCell] = useState(false);
  const [gotTheFirstData, setGotTheFirstData] = useState(false);
  // Functions
  // GetData
  useEffect(() => {
    async function GetData() {
      if (pagination) parametersToGetTheData.data.page = 1;
      console.log('parameters 1 -> ', parametersToGetTheData.data);
      const response = await CallToTheAPI(parametersToGetTheData);
      console.log('response 1 -> ', response);
      if (pagination) {
        const responsePageParameters = pathToThePageParameters.reduce(
          (obj, pathToThePageParameters) => obj[pathToThePageParameters],
          response
        );
        await setPageParameters({
          current: responsePageParameters.currentPage,
          totalElements: responsePageParameters.totalElements,
          limit: responsePageParameters.limit,
        });
      }
      await setData(
        pathToTheData.reduce(
          (obj, pathToTheData) => obj[pathToTheData],
          response
        )
      );
      if (getTheDataMessages) {
        if (response.success) {
          if (getTheDataMessages.success) {
            setGotTheFirstData(true);
            message.success(getTheDataMessages.success);
          }
        } else if (getTheDataMessages.error) {
          message.error(getTheDataMessages.error);
        }
      }
      return setLoad(false);
    }
    if (user) {
      if (!gotTheFirstData) {
        GetData();
      }
    }
  }, [
    user,
    CallToTheAPI,
    parametersToGetTheData,
    pathToTheData,
    getTheDataMessages,
    pagination,
    pathToThePageParameters,
    gotTheFirstData,
  ]);
  // Prepare action cell
  useEffect(() => {
    async function GetDataAgainCell() {
      setLoadingTable(true);
      if (pagination) {
        if (pageParameters) {
          parametersToGetTheData.data.pagination.page = pageParameters.page;
          parametersToGetTheData.data.pagination.limit =
            pageParameters.pageSize;
        }
      }
      console.log('parameters 2 -> ', parametersToGetTheData.data);
      const response = await CallToTheAPI(parametersToGetTheData);
      setData(
        pathToTheData.reduce(
          (obj, pathToTheData) => obj[pathToTheData],
          response
        )
      );
      return setLoadingTable(false);
    }
    async function PrepareActionCell() {
      const actionCell = {
        title: 'Ações',
        key: 'action',
        render: (data) => {
          switch (actionCellType) {
            case 'EditingAndDeleting':
              return (
                <ActionCellEditingAndDeleting
                  item={data}
                  FormItems={FormItems}
                  GetDataAgain={GetDataAgainCell}
                  parametersForDeleteData={{
                    operation: operationForDeleteData,
                    urlParams: {
                      personId: user.id,
                      [paramsIdName]: data.id,
                    },
                  }}
                  deleteDataMessages={deleteDataMessages}
                  parametersForEditData={{
                    operation: operationForEditData,
                    urlParams: {
                      personId: user.id,
                      [paramsIdName]: data.id,
                    },
                  }}
                  editDataMessages={editDataMessages}
                />
              );
            default:
          }
        },
      };
      tableColumns.push(actionCell);
    }
    if (actionCellType) {
      if (!preparedTheActionCell) {
        PrepareActionCell();
        setPreparedTheActionCell(true);
      }
    }
  }, [
    FormItems,
    actionCellType,
    operationForDeleteData,
    deleteDataMessages,
    operationForEditData,
    editDataMessages,
    paramsIdName,
    tableColumns,
    user,
    CallToTheAPI,
    parametersToGetTheData,
    pathToTheData,
    pagination,
    pageParameters,
    pathToThePageParameters,
    preparedTheActionCell,
  ]);
  useEffect(() => {
    async function GetSearchData() {
      setLoadingTable(true);
      setPageParameters({
        current: 1,
        totalElements: 0,
      });
      parametersToObtainTheDataWithSearch.data.filter.searchString = searchInput;
      const response = await CallToTheAPI(parametersToObtainTheDataWithSearch);
      if (pagination) {
        const responsePageParameters = pathToThePageParameters.reduce(
          (obj, pathToThePageParameters) => obj[pathToThePageParameters],
          response
        );
        await setPageParameters({
          current: responsePageParameters.currentPage,
          totalElements: responsePageParameters.totalElements,
          limit: responsePageParameters.limit,
        });
      }
      setData(
        pathToTheData.reduce(
          (obj, pathToTheData) => obj[pathToTheData],
          response
        )
      );
      return setLoadingTable(false);
    }
    if (searchInput) {
      GetSearchData();
    }
  }, [
    searchInput,
    CallToTheAPI,
    pagination,
    parametersToObtainTheDataWithSearch,
    pathToTheData,
    pathToThePageParameters,
  ]);
  async function GetDataAgain(pageParameters) {
    setLoadingTable(true);
    if (pagination) {
      if (pageParameters) {
        parametersToGetTheData.data.pagination.page = pageParameters.page;
        parametersToGetTheData.data.pagination.limit = pageParameters.pageSize;
      }
    }
    if (search) {
      if (searchInput !== '') {
        parametersToGetTheData.data.filter.searchString = searchInput;
      }
    }
    const response = await CallToTheAPI(parametersToGetTheData);
    if (pagination) {
      const responsePageParameters = pathToThePageParameters.reduce(
        (obj, pathToThePageParameters) => obj[pathToThePageParameters],
        response
      );
      await setPageParameters({
        current: responsePageParameters.currentPage,
        totalElements: responsePageParameters.totalElements,
        limit: responsePageParameters.limit,
      });
    }
    setData(
      pathToTheData.reduce((obj, pathToTheData) => obj[pathToTheData], response)
    );
    return setLoadingTable(false);
  }
  const headerLeftElements = () => {
    return <Title level={4}>{title}</Title>;
  };
  const headerRightElements = () => {
    return (
      <>
        {search ? (
          <Search
            style={{ marginRight: 10, width: 200 }}
            defaultValue={searchInput}
            placeholder={searchPlaceholder}
            onSearch={(value) => setSearchInput(value)}
          />
        ) : (
          <></>
        )}

        <AddButton
          addButtonText={addButtonText}
          GetDataAgain={GetDataAgain}
          parametersForAddingData={parametersForAddingData}
          addingDataMessages={addingDataMessages}
          FormItems={FormItems}
          addingDataInitialValues={addingDataInitialValues}
        />
      </>
    );
  };
  return (
    <div>
      <Breadcrumb breadcrumbItems={breadcrumbItems} />
      <Card>
        {load ? (
          <Loading />
        ) : (
          <>
            <Header
              LeftElements={headerLeftElements}
              RightElements={headerRightElements}
            />
            <Divider />
            <Table
              loading={loadingTable}
              GetDataAgain={GetDataAgain}
              dataReceived={data}
              tableColumns={tableColumns}
            />
            {pagination ? (
              <div>
                {pageParameters ? (
                  <Pagination
                    style={{
                      marginTop: 10,
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                    onChange={(page, pageSize) =>
                      GetDataAgain({ page, pageSize })
                    }
                    onShowSizeChange={(current, size) =>
                      GetDataAgain({ page: current, pageSize: size })
                    }
                    total={pageParameters.totalElements}
                    showSizeChanger
                    showQuickJumper
                    showTotal={(total) => `Total ${total} items`}
                  />
                ) : (
                  <></>
                )}
              </div>
            ) : (
              <></>
            )}
          </>
        )}
      </Card>
    </div>
  );
}

export default memo(PageStructureType1);
