import React, { useState, useContext, useEffect } from 'react';
// Contexts
import { AxiosContext } from '~/contexts/AxiosContext';
import { AuthContext } from '~/contexts/AuthContext';
//Libraries
import { Row, Col } from 'antd';
import { LinkOutlined } from '@ant-design/icons';
//Components
import FormItem from '~/components/form/FormItem';
import Input from '~/components/input';
import Select from '~/components/select';
import TextArea from '~/components/textArea';
import Button from '~/components/button';
import { AutoComplete } from '~/components/autoComplete';
import { ImageWithTextCell } from '~/components/table/cells';

export default function CitiesFormItems({ data, form }) {
  const { CallToTheAPI } = useContext(AxiosContext);
  const { user } = useContext(AuthContext);
  const [autoCompleteStatesOptions, setAutoCompleteStatesOptions] = useState(
    []
  );
  const [linkNewState, setLinkNewState] = useState(false);
  const [inputCountry, setInputCountry] = useState('');

  useEffect(() => {
    setLinkNewState(false);
  }, [data]);

  async function GetStates(input) {
    const params = {
      operation: ['Geo', 'Get'],
      urlParams: {
        personId: user._id,
      },
      data: {
        filter: {
          type: 'ST',
          searchString: input,
          city: data && data._id ? data._id : undefined,
        },
        sort: {
          by: 'name',
          order: 'asc',
        },
      },
    };
    const response = await CallToTheAPI(params);
    //console.log(response);
    if (response.success) {
      let data = [];
      response.body.countryStateCity.elements.map((item) => {
        let newItem = {};
        newItem._id = item._id;
        newItem.value = `${item.name} ${
          item.country && item.country.name ? `- ${item.country.name}` : null
        }`;
        newItem.country = item.country || null;
        data.push(newItem);
        return null;
      });
      setAutoCompleteStatesOptions(data);
    }
  }
  async function SelectState(data) {
    form.setFieldsValue({ state: data._id });
    if (data.country) {
      form.setFieldsValue({ country: data.country._id });
      setInputCountry(data.country.name);
    }
  }

  const type = [
    {
      value: 'CO',
      label: 'País',
    },
    {
      value: 'ST',
      label: 'Estado',
    },
    {
      value: 'CI',
      label: 'Cidade',
    },
  ];
  /*
  const continents = [
    {
      value: 'África',
      label: 'África',
    },
    {
      value: 'América do Norte',
      label: 'América do Norte',
    },
    {
      value: 'América do Sul',
      label: 'América do Sul',
    },
    {
      value: 'Antártida',
      label: 'Antártida',
    },
    {
      value: 'Ásia',
      label: 'Ásia',
    },
    {
      value: 'Europa',
      label: 'Europa',
    },
    {
      value: 'Oceania',
      label: 'Oceania',
    },
  ];
*/
  return (
    <div>
      <Row>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <FormItem
            label="Tipo"
            name={['type']}
            style={{ width: '100px' }}
            item={<Select disabled options={type} />}
          />
        </Col>
        <Col xs={24} sm={24} md={16} lg={16} xl={16}>
          <FormItem
            label="Nome da cidade"
            name={['name']}
            style={{ marginRight: 10 }}
            item={<Input />}
          />
        </Col>
        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
          <FormItem label="Etnônimo" name={['ethnonym']} item={<Input />} />
        </Col>

        {data && data.state && data.state.name && linkNewState === false ? (
          <>
            <Col xs={24} sm={24} md={16} lg={16} xl={16}>
              <FormItem
                label={
                  <span>
                    Estado Vinculado
                    <LinkOutlined style={{ marginLeft: 5 }} />
                  </span>
                }
                item={
                  <div>
                    <ImageWithTextCell
                      text={data.state.name}
                      image={data.state.flag}
                    />
                  </div>
                }
              />
            </Col>
            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <FormItem
                label=" "
                item={
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      alignItems: 'center',
                    }}
                  >
                    <Button type="link" onClick={() => setLinkNewState(true)}>
                      Vincular novo estado
                    </Button>
                  </div>
                }
              />
            </Col>
            <Col xs={24} sm={24} md={16} lg={16} xl={16}>
              <FormItem
                label={
                  <span>
                    País Vinculado
                    <LinkOutlined style={{ marginLeft: 5 }} />
                  </span>
                }
                item={
                  <div>
                    <ImageWithTextCell
                      text={data.country.name}
                      image={data.country.flag}
                    />
                  </div>
                }
              />
            </Col>
          </>
        ) : (
          <>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <FormItem
                label={
                  <span>
                    Vincular Estado
                    <LinkOutlined style={{ marginLeft: 5 }} />
                  </span>
                }
                item={
                  <AutoComplete
                    options={autoCompleteStatesOptions}
                    onChange={(value) => GetStates(value)}
                    onSelect={(value, data) => SelectState(data)}
                  />
                }
              />
            </Col>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={24}
              xl={24}
              style={{ display: 'none' }}
            >
              <FormItem name={['state']} item={<Input disabled />} />
            </Col>
            {data && data.state ? (
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                  }}
                >
                  <Button type="link" onClick={() => setLinkNewState(false)}>
                    Cancelar
                  </Button>
                </div>
              </Col>
            ) : (
              <></>
            )}
            <Col
              xs={24}
              sm={24}
              md={12}
              lg={12}
              xl={12}
              style={{ display: 'none' }}
            >
              <FormItem
                label="Vincular País"
                name={['country']}
                item={<Input disabled />}
              />
            </Col>
            <Col
              xs={24}
              sm={24}
              md={12}
              lg={12}
              xl={12}
              style={{ display: 'none' }}
            >
              <FormItem
                label="Nome do país vinculado"
                item={<Input disabled value={inputCountry} />}
              />
            </Col>
          </>
        )}
        {/* 
        <Col xs={24} sm={24} md={14} lg={14} xl={14}>
          <FormItem
            label="Continente"
            name={['continent']}
            style={{ marginRight: 10 }}
            item={<Select options={continents} />}
          />
        </Col>
        */}
        <Col xs={24} sm={24} md={6} lg={6} xl={6}>
          <FormItem
            label="Nome Curto"
            name={['shortName']}
            style={{ marginRight: 10 }}
            item={<Input />}
          />
        </Col>
        <Col xs={24} sm={24} md={4} lg={4} xl={4}>
          <FormItem label="Abreviação" name={['abrev']} item={<Input />} />
        </Col>
        <Col xs={24} sm={24} md={14} lg={14} xl={14}></Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <FormItem
            label="Começo do CEP"
            name={['code', 'zip', 'start']}
            style={{ marginRight: 10 }}
            item={<Input />}
          />
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <FormItem
            label="Final do CEP"
            name={['code', 'zip', 'end']}
            item={<Input />}
          />
        </Col>
        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
          <FormItem
            label="Latitude"
            name={['code', 'lat']}
            style={{ marginRight: 10 }}
            item={<Input />}
          />
        </Col>
        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
          <FormItem
            label="Longitude"
            name={['code', 'lng']}
            style={{ marginRight: 10 }}
            item={<Input />}
          />
        </Col>
        <Col xs={24} sm={24} md={4} lg={4} xl={4}>
          <FormItem
            label="DDD"
            name={['code', 'ddd']}
            style={{ marginRight: 10 }}
            item={<Input />}
          />
        </Col>
        <Col xs={24} sm={24} md={4} lg={4} xl={4}>
          <FormItem label="DDI" name={['code', 'ddi']} item={<Input />} />
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <FormItem label="Descrição" name={['desc']} item={<TextArea />} />
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <FormItem
            label="Bandeira"
            name={['flag']}
            item={<Input textTransform="none" />}
          />
        </Col>
      </Row>
    </div>
  );
}
