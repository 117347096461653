import React, { memo } from 'react';

import { ButtonDropdown } from '~/components/dropdown';

function Emojis({ mainEditor, setValue, editorRef }) {
  const emojis = [
    '😊',
    '🙂',
    '😃',
    '😄',
    '😅',
    '😉',
    '😇',
    '😂',
    '🥰',
    '🤩',
    '😑',
    '😛',
    '🤔',
    '🤭',
    '🤫',
    '🤪',
    '🤑',
    '😝',
    '😜',
    '😏',
    '😐',
    '😔',
    '😶',
    '😒',
    '🙄',
    '🤥',
    '😪',
    '🤕',
    '😷',
    '🤒',
    '😴',
  ];

  const emijosContent = (
    <div className="emojis-container">
      {emojis.map((emoji) => (
        <button
          key={emoji}
          onClick={(event) => SetEmojisOnEditor(event)}
          role="img"
          aria-labelledby="smilte"
        >
          {emoji}
        </button>
      ))}
    </div>
  );

  function SetEmojisOnEditor(event) {
    mainEditor.focus();

    console.log(`mainEditor`, mainEditor);

    mainEditor.ownerDocument.execCommand(
      'insertHTML',
      false,
      `<span >${event.target.innerHTML}&nbsp;</span>`
    );

    setValue(editorRef.current.innerHTML);
  }

  return (
    <ButtonDropdown
      dropdownId="emojis"
      menuTitle="Emojis"
      content={emijosContent}
    />
  );
}

export default memo(Emojis);
