export default {
  Context: {
    Auth: false,
    Browser: false,
    Interface: false,
    Language: false,
    Permission: false,
    Responsive: false,
    Sidebar: false,
    Theme: false,

    Axios: false,
    Wallet: false,
    Hook: false,
  },
  Modules: {
    Authentication: false,
  },
  Hooks: {
    useSocialMeAPI: false,
    useSWRStrategy: false,
  },
  Accounts: false,
  Administrator: {
    ActivityTypes: false,
    Places: false,
    IncompleteDonations: true,
    PendingDonations: true,
    PublishedDonations: true,
    RejectedDonations: true,
    ManualDeposits: true,
  },
  Wallet: {
    Auth: false,
    Home: false,
    QRCodes: false,
    CheckingAccount: false,
  },
  Provider: {
    Home: false,
  },
};

// console.log('😀🍪❌✔❗❓⛔🚫🧭🛸⚠🔗🔧🔑🔒🔓📍📌📊📋📉📈💼📦💳📒📙📘📗📕💡🔍💻🔋📱🔔💎🏆🔥💧⚡🚀⌛🛑🚧🌎')
