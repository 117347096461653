export default {
  SMB89FCB3: {
    message: 'The product/service model was successfully deleted',
    //ProductOrService.Delete
  },
  SMC7A8AE7: {
    message: 'The activity type was successfully deleted',
    //Admin.ActivityType.Delete
  },
  SM0ACEA3C: {
    message: 'The address was successfully deleted',
  },
  SMF3A7263: {
    message: "The 'country, state, city or community' was successfully deleted",
  },
  SMCAE7318: {
    message: "The 'donation request product/service' was successfully deleted",
  },
};
