import React from 'react';
//Libraries
import { Divider } from 'antd';
//Components
import FormItem from '~/components/form/FormItem';
import Checkbox from '~/components/checkbox';
import Text from '~/components/typography/text';

export default function CheckboxGroup({ permissionGroups, form, values }) {
  function VerifyWhetherRequired(permission) {
    if (permission.ItIsRequiredBy) {
      for (let i = 0; i < permission.ItIsRequiredBy.length; i++) {
        if (values[permission.ItIsRequiredBy[i]]) {
          return true;
        }
      }
    }
    return false;
  }

  function CheckRequeriments(value, permission) {
    if (permission.requeriments) {
      const checkedValue = value;
      for (let i = 0; i < permission.requeriments.length; i++) {
        if (checkedValue) {
          form.setFieldsValue({
            [permission.requeriments[i]]: checkedValue,
          });
        }
      }
    }
    return;
  }

  const RenderGroup = (item) => {
    return (
      <div key={item.title}>
        <Text strong>{item.title}</Text>
        {item.permissions.map((permission) => (
          <FormItem
            key={permission.name}
            name={permission.name}
            valuePropName="checked"
            item={
              <Checkbox
                text={permission.text}
                helpText={permission.helpText}
                disabled={VerifyWhetherRequired(permission)}
                onChange={(event) =>
                  CheckRequeriments(event.target.checked, permission)
                }
              />
            }
          />
        ))}
        <Divider style={{ margin: '0px' }} />
      </div>
    );
  };
  return <div>{permissionGroups.map((item) => RenderGroup(item))}</div>;
}
