import React from 'react';
// Components
import EditButton from './components/EditButton';
import DeleteButton from './components/DeleteButton';

export default function Actions({
  item,
  FormItems,
  parametersForDeleteData,
  deleteDataMessages,
  parametersForEditData,
  editDataMessages,
  GetDataAgain,
}) {
  return (
    <span style={{ display: 'flex', justifyContent: 'center' }}>
      <EditButton
        item={item}
        FormItems={FormItems}
        parametersForEditData={parametersForEditData}
        editDataMessages={editDataMessages}
        GetDataAgain={GetDataAgain}
      />
      <DeleteButton
        parametersForDeleteData={parametersForDeleteData}
        deleteDataMessages={deleteDataMessages}
        GetDataAgain={GetDataAgain}
      />
    </span>
  );
}
