import React, { useContext, useState, useEffect } from 'react';
// Libraries
import { message, Drawer, Form } from 'antd';
// Components
import Button from '~/components/button';
// Contexts
import { AxiosContext } from '~/contexts/AxiosContext';
import { ResponsiveContext } from '~/contexts/ResponsiveContext';

export default function Actions({
  item,
  FormItems,
  parametersForEditData,
  editDataMessages,
  GetDataAgain,
}) {
  // Contexts
  const { CallToTheAPI } = useContext(AxiosContext);
  const { isMobile } = useContext(ResponsiveContext);
  // Variables
  const [visibleDrawer, setVisibleDrawer] = useState(false);
  const [load, setLoad] = useState(false);
  const [form] = Form.useForm();
  const [initialValues, setInitalValues] = useState();
  // Functions
  useEffect(() => {
    async function GetInitialValues() {
      const newInitialValues = {};
      for (const key in item) {
        if (item[key]) {
          newInitialValues[key] = item[key];
        }
      }
      setInitalValues(newInitialValues);
    }
    if (item) {
      GetInitialValues();
    }
  }, [item]);
  const ShowDrawer = () => {
    setVisibleDrawer(true);
  };
  const CloseDrawer = () => {
    setVisibleDrawer(false);
  };
  async function EditData(data) {
    setLoad(true);
    const params = parametersForEditData;
    params.data = data;
    console.log('Params -> ', data);
    const response = await CallToTheAPI(params);
    console.log(response);
    if (editDataMessages) {
      if (response.success) {
        if (editDataMessages.success) {
          message.success(editDataMessages.success);
        }
        GetDataAgain();
        setLoad(false);
        return CloseDrawer();
      } else {
        if (editDataMessages.error) {
          message.error(editDataMessages.error);
        }
        return setLoad(false);
      }
    }
  }
  const onFinish = (data) => {
    EditData(data);
    //console.log('Received data of form: ', data);
  };
  return (
    <>
      <Button
        text="Editar"
        onClick={() => ShowDrawer()}
        style={{ marginRight: 10 }}
      />
      <Drawer
        title={`EDITANDO ${item.name || ''}`}
        width={isMobile ? '100%' : 600}
        onClose={CloseDrawer}
        visible={visibleDrawer}
        bodyStyle={{ padding: 20 }}
        footer={
          <div
            style={{
              textAlign: 'right',
            }}
          >
            <Button
              text="Cancelar"
              onClick={CloseDrawer}
              style={{ marginRight: 8 }}
            />

            <Button
              text="Atualizar"
              loading={load}
              disabled={load}
              type="primary"
              onClick={() => {
                form
                  .validateFields()
                  .then((values) => {
                    onFinish(values);
                  })
                  .catch((info) => {
                    console.log('Validate Failed:', info);
                  });
              }}
            />
          </div>
        }
      >
        <Form
          labelCol={{ xs: { span: 24 }, sm: { span: 24 } }}
          wrapperCol={{ xs: { span: 24 }, sm: { span: 24 } }}
          form={form}
          layout="vertical"
          size="small"
          onFinish={onFinish}
          scrollToFirstError
          initialValues={initialValues}
          //initialValues={{ name: ['Movies', 'Books', 'Music', 'Sports'] }}
        >
          <FormItems dataId={item.id} data={item} form={form} />
        </Form>
      </Drawer>
    </>
  );
}
