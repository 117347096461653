import React, { useContext } from 'react';
//Libraries
import { Badge } from 'antd';
//Contexts
import { ThemeContext } from '~/contexts/ThemeContext';
//Components
import Text from '~/components/typography/text';

export default function ExpandedRow({ record }) {
    const { theme } = useContext(ThemeContext);

    const PermissionRow = (name) => {
        return (
            <div key={name} style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                <Badge color={theme.color.primary} status="success" />
                <Text type="secondary">{name}</Text>
            </div>
        )
    }

    const RenderPermissions = () => {
        let rows = []
        for (const key in record.permissions) {
            rows.push(PermissionRow(key))
        }
        return rows;
    }
    return (
        <div style={{ paddingLeft: 35 }}>
            <div>
                {RenderPermissions()}
            </div>
        </div>
    )
}