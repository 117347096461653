import React from 'react';

import { Row } from 'antd';

import Card from '~/components/card';
import Title from '~/components/typography/title';

import { FileGroup } from '~/components/file';

import Container from './style';

export default function Files({ deposit }) {
  const bodyStyle = {
    padding: 0,
  };

  const cardStyle = {
    margin: '15px 0',
  };

  console.log(deposit.settings.files);

  return (
    <Card style={cardStyle} bodyStyle={bodyStyle} bordered={false}>
      <Row>
        <Title level={4}>Arquivos auxiliares</Title>
      </Row>
      <Container>
        <FileGroup files={deposit.settings.files} />
      </Container>
    </Card>
  );
}
