import React, { memo } from 'react';

import { SelectDropdown } from '~/components/dropdown';

function DocumentsModal({ documents, editorRef, setValue }) {
  function SetModelInEditor(event) {
    editorRef.current.innerHTML = event.target.value;

    setValue(editorRef.current.innerHTML);
  }

  return (
    <SelectDropdown
      menuTitle="Modelos"
      dropdownId="documents"
      items={documents || []}
      ItemOnClick={(event) => SetModelInEditor(event)}
    />
  );
}

export default memo(DocumentsModal);
