import React, { useEffect, useState, useContext } from 'react';
// Contexts
import { AxiosContext } from '~/contexts/AxiosContext';
import { AuthContext } from '~/contexts/AuthContext';
//Components
import FormItem from '~/components/form/FormItem';
import Input from '~/components/input';
import TextArea from '~/components/textArea';
import Select from '~/components/select';
import Checkbox from '~/components/checkbox';
import DynamicTags from '~/components/tags/dynamicTags';

export default function ProductsFormItems({ tags, setTags, akas, setAkas }) {
  const { CallToTheAPI } = useContext(AxiosContext);
  const { user } = useContext(AuthContext);

  const [measurementUnities, setMeasurementUnities] = useState([]);

  useEffect(() => {
    async function GetMeasurementUnities() {
      const params = {
        operation: ['System', 'MeasurementUnit', 'GetAll'],
      };
      const response = await CallToTheAPI(params);
      //console.log(response);
      if (response.success) {
        let array = response.body.mUnities;
        const treatment = [];
        await array.map((item) => {
          let newItem = {};
          newItem.value = item.id;
          newItem.label = item.name;
          return treatment.push(newItem);
        });
        await setMeasurementUnities(treatment);
      }
    }
    GetMeasurementUnities();
  }, [CallToTheAPI, user]);

  const KindOptions = [
    {
      value: 'PRODUCT',
      label: 'Produto',
    },
    {
      value: 'SERVICE',
      label: 'Serviço',
    },
  ];

  return (
    <div>
      <FormItem
        label="Nome"
        name={['name']}
        rules={[
          {
            required: true,
            message: 'Um nome é obrigatório',
          },
        ]}
        item={<Input />}
      />
      <FormItem
        label="Categoria"
        name={['category']}
        rules={[
          {
            required: true,
            message: 'Uma categoria é obrigatória',
          },
        ]}
        item={<Input />}
      />
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <FormItem label="NCM" name={['ncm']} item={<Input width="100px" />} />
        <FormItem
          label="Preço base"
          name={['value']}
          rules={[
            {
              required: true,
              message: 'Um preço é obrigatório',
            },
          ]}
          item={<Input width="100px" prefix="R$" />}
        />
        <FormItem
          label="Tipo"
          name={['kind']}
          item={<Select disabled options={KindOptions} />}
        />
        <FormItem
          label="Status"
          name="status"
          valuePropName="checked"
          item={<Checkbox text={'Ativo'} />}
        />
      </div>
      <FormItem
        label="Unidade de Medida"
        name={['measurementUnity']}
        rules={[
          {
            required: true,
            message: 'Uma unidade de medida é obrigatória',
          },
        ]}
        item={<Select options={measurementUnities} />}
      />
      <FormItem label="Descrição" name={['desc']} item={<TextArea />} />
      <FormItem label="Avatar" name={['avatar']} item={<Input />} />

      <FormItem
        label="Tags"
        name={['tags']}
        item={<DynamicTags tags={tags} setTags={setTags} />}
      />

      <FormItem
        label="AKAS"
        name={['akas']}
        item={<DynamicTags tags={akas} setTags={setAkas} />}
      />

      {/* 
      <FormItem
        label="Tags"
        name={['tags']}
        valuePropName={'tagsReceived'}
        item={<DynamicTags AddTag={AddTag} DeleteTag={DeleteTag} />}
      />

      <FormItem
        label="Akas"
        name={['akas']}
        valuePropName={'tagsReceived'}
        item={<DynamicTags AddTag={AddAkas} DeleteTag={DeleteAkas} />}
      />
      */}
    </div>
  );
}
