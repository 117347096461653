import React, { useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';

import SocialMeAPI from '~/utils/SocialMeAPI';

import BasicInfo from './components/BasicInfo';

import Card from '~/components/card';
import Loading from '~/components/loading';

// import { ThemeContext } from '~/contexts/ThemeContext';
// import { ResponsiveContext } from '~/contexts/ResponsiveContext';

export default function PendingDonations() {
  const [loading, setLoading] = useState(true);

  const [person, setPerson] = useState({});

  // const { isMobile } = useContext(ResponsiveContext);
  // const { theme } = useContext(ThemeContext);

  const { personId } = useParams();

  useEffect(() => {
    async function GetData() {
      setLoading(true);

      const params = {
        operation: ['Person', 'Show'],
        urlParams: {
          personId,
        },
      };

      const response = await SocialMeAPI(params);

      if (response.success) {
        setPerson(response.body.person);
      }

      setLoading(false);
    }
    GetData();
  }, [personId]);

  return (
    <Card>
      {loading ? (
        <Loading text="Carregando dados da pessoa..." />
      ) : (
        <BasicInfo person={person} />
      )}
    </Card>
  );
}
