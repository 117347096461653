import React, { useState } from 'react';
// Libraries
import { message, Drawer, Form } from 'antd';
// Components
import Button from '~/components/button';
import Divider from '~/components/divider';
import Loading from '~/components/loading';
//Own Components
import Header from './components/Header';
import DonationInformation from './components/DonationInformation';
import AdminInformation from './components/AdminInformation';
// Contexts
import SocialMeAPI from '~/utils/SocialMeAPI';

import Debug from '~/environments/Debug';

export default function JudgmentButton({
  itemData,
  GetDataAgain,
  theme,
  isMobile,
}) {
  const [visibleDrawer, setVisibleDrawer] = useState(false);
  const [form] = Form.useForm();
  const [loadingButton, setLoadingButton] = useState(false);
  const [loadingBody, setLoadingBody] = useState(true);
  const [donation, setDonation] = useState([]);

  const [tags, setTags] = useState([]);
  const [personalTags, setPersonalTags] = useState([]);
  const [locationTags, setLocationTags] = useState([]);

  const isDebug = Debug.Administrator.PendingDonations;

  const ShowDrawer = () => {
    setVisibleDrawer(true);

    async function GetData() {
      const params = {
        operation: ['Donation', 'Show'],
        urlParams: {
          donationId: itemData.id,
        },
      };

      if (isDebug) console.log('📦 Params: ', params);

      const response = await SocialMeAPI(params);

      if (isDebug) console.log('🚀 API Response, Donation Show: ', response);

      if (response.success) {
        let donationInformation = response.body.donationRequest;
        if (donationInformation.tags) setTags(donationInformation.tags);
        if (donationInformation.personal_tags)
          setPersonalTags(donationInformation.personal_tags);
        if (donationInformation.location_tags)
          setLocationTags(donationInformation.location_tags);

        if (isDebug) console.log('Donation was found, increase tags on it');

        setDonation(donationInformation);
        setLoadingBody(false);
      } else if (isDebug) {
        console.log('No donation found');
      }
    }
    GetData();
  };

  const CloseDrawer = () => {
    setVisibleDrawer(false);
  };

  async function Judge(data, judgment) {
    const params = {
      operation: ['Donation', 'JudgeNoValidation'],
      urlParams: {
        donationId: itemData.id,
        judgment,
      },
      data: {
        ...data,
        resolved_by_comment: 'teste',
      },
    };

    if (isDebug) console.log('📦 Params: ', params);

    const response = await SocialMeAPI(params);

    if (isDebug)
      console.log('🚀 API Response, Donation JudgeNoValidation: ', response);

    if (response.success) {
      if (isDebug) console.log('The donation was successfully judge');
      message.success('Doação Atualizado!');
      setLoadingButton(false);
      CloseDrawer();
      return GetDataAgain();
    }

    if (isDebug) console.log('Donation not judge');
    message.error('Alguma coisa aconteceu...');
    setLoadingButton(false);
  }

  const onFinish = (data, judgment) => {
    setLoadingButton(true);

    data.tags = tags;
    data.personal_tags = personalTags;
    data.location_tags = locationTags;

    if (judgment === 'REJECT') {
      Judge(data, judgment);
    } else if (judgment === 'APPROVE') {
      Judge(data, judgment);
    }
  };
  return (
    <>
      <Button onClick={() => ShowDrawer()} style={{ marginRight: 5 }}>
        Julgar
      </Button>

      <Drawer
        title={`Julgando pedido`}
        width={isMobile ? '100%' : 600}
        onClose={CloseDrawer}
        visible={visibleDrawer}
        bodyStyle={{ padding: 20 }}
        footer={
          <div
            style={{
              textAlign: 'right',
            }}
          >
            <Button onClick={CloseDrawer} style={{ marginRight: 8 }}>
              Cancelar
            </Button>

            <Button
              style={{ marginRight: 8 }}
              loading={loadingButton}
              disabled={loadingButton}
              type="dashed"
              onClick={() => {
                form.validateFields().then((values) => {
                  onFinish(values, 'REJECT');
                });
              }}
            >
              Rejeitar
            </Button>

            <Button
              loading={loadingButton}
              disabled={loadingButton}
              type="primary"
              onClick={() => {
                form.validateFields().then((values) => {
                  onFinish(values, 'APPROVE');
                });
              }}
            >
              Aprovar
            </Button>
          </div>
        }
      >
        {loadingBody ? (
          <Loading text="Buscando informações do pedido" />
        ) : (
          <Form
            labelCol={{
              xs: { span: 24 },
              sm: { span: 24 },
            }}
            wrapperCol={{
              xs: { span: 24 },
              sm: { span: 24 },
            }}
            form={form}
            layout="vertical"
            size="small"
            //onValuesChange={() => setFormValues(form.getFieldsValue())}
            scrollToFirstError
            initialValues={{
              history: donation.history === 'null' ? '' : donation.history,
              dream: donation.dream === 'null' ? '' : donation.dream,
              help: donation.help === 'null' ? '' : donation.help,
            }}
          >
            <Header setDonation={setDonation} donation={donation} />
            <Divider borderColor={theme.color.white} />
            <DonationInformation
              theme={theme}
              isMobile={isMobile}
              donation={donation}
              isDebug={isDebug}
              setDonation={setDonation}
            />
            <Divider borderColor={theme.color.white} />
            <AdminInformation
              tags={tags}
              setTags={setTags}
              personalTags={personalTags}
              setPersonalTags={setPersonalTags}
              locationTags={locationTags}
              setLocationTags={setLocationTags}
            />
          </Form>
        )}
      </Drawer>
    </>
  );
}
