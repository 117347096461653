import { format, addMinutes, differenceInDays } from 'date-fns';

let debug = false;

export const HourInBrazilianFormat = (date) => {
  return format(new Date(date), 'HH:mm');
};

export const DateInBrazilianFormat = (date) => {
  if (debug) console.log('date -> ', date);
  var d = new Date();
  var n = d.getTimezoneOffset();
  const newDate = addMinutes(new Date(date), n * 2);
  if (debug) console.log('newDate -> ', newDate);

  //
  return format(new Date(newDate), 'dd/MM/yyyy');
};

export const DiferenceBetweenTheCurrentDate = (date) => {
  const currentDate = new Date();

  return differenceInDays(currentDate, new Date(date));
};
