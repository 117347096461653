import React, { useState, useContext } from 'react';
// Libraries
import { Drawer, message, Form } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
// Components
import Button from '~/components/button';
// Contexts
import { AxiosContext } from '~/contexts/AxiosContext';
import { ResponsiveContext } from '~/contexts/ResponsiveContext';

export default function AddButton({
  GetDataAgain,
  addButtonText,
  parametersForAddingData,
  addingDataMessages,
  FormItems,
  addingDataInitialValues,
}) {
  // Contexts
  const { CallToTheAPI } = useContext(AxiosContext);
  const { isMobile } = useContext(ResponsiveContext);
  // Variables
  const [visibleDrawer, setVisibleDrawer] = useState(false);
  const [load, setLoad] = useState(false);
  const [form] = Form.useForm();
  // Functions
  const ShowDrawer = () => {
    setVisibleDrawer(true);
  };
  const CloseDrawer = () => {
    setVisibleDrawer(false);
  };

  async function AddData(data) {
    setLoad(true);
    parametersForAddingData.data = data;
    console.log('PARAMS -> ', parametersForAddingData);
    const response = await CallToTheAPI(parametersForAddingData);
    //console.log('Add Button -> ', response);
    if (addingDataMessages) {
      if (response.success) {
        if (addingDataMessages.success) {
          message.success(addingDataMessages.success);
        }
      } else if (addingDataMessages.error) {
        message.error(addingDataMessages.error);
      }
    }
    setLoad(false);
    CloseDrawer();
    return GetDataAgain();
  }
  const onFinish = (data) => {
    AddData(data);
    // console.log('Received data of form: ', data);
  };
  return (
    <>
      <Button
        text={addButtonText}
        onClick={() => ShowDrawer()}
        type="primary"
        icon={<PlusOutlined />}
      />

      <Drawer
        title={addButtonText}
        width={isMobile ? '100%' : 600}
        onClose={CloseDrawer}
        visible={visibleDrawer}
        bodyStyle={{ padding: 20 }}
        footer={
          <div
            style={{
              textAlign: 'right',
            }}
          >
            <Button
              text="Cancelar"
              onClick={CloseDrawer}
              style={{ marginRight: 8 }}
            />

            <Button
              text="Adicionar"
              loading={load}
              disabled={load}
              type="primary"
              onClick={() => {
                form
                  .validateFields()
                  .then((values) => {
                    form.resetFields();
                    onFinish(values);
                  })
                  .catch((info) => {
                    // console.log('Validate Failed:', info);
                  });
              }}
            />
          </div>
        }
      >
        <Form
          labelCol={{ xs: { span: 24 }, sm: { span: 24 } }}
          wrapperCol={{ xs: { span: 24 }, sm: { span: 24 } }}
          form={form}
          layout="vertical"
          size="small"
          onFinish={onFinish}
          scrollToFirstError
          initialValues={addingDataInitialValues || {}}
        >
          <FormItems form={form} />
        </Form>
      </Drawer>
    </>
  );
}
