import React, { memo, useContext } from 'react';

import { Form } from 'antd';

import { ThemeContext } from '~/contexts/ThemeContext';

import Modal from '~/components/modal';
import Button from '~/components/button';
import InputNumber from '~/components/inputNumber';
import FormItem from '~/components/form/FormItem';

function GenerateTables({ setValue, editMode, editorRef, setMainEditor }) {
  const [form] = Form.useForm();

  const { theme } = useContext(ThemeContext);

  function SetOnClickOnTableData() {
    const tablesData = document.querySelectorAll('td');

    tablesData.forEach((td) => {
      td.addEventListener('click', (event) => {
        editorRef.current.removeAttribute('contentEditable');

        const options = {
          currentTag: event.target,
          currentTagName: event.target.tagName,
          parentElement: event.target.parentElement,
          parentElementTag: event.target.parentElement.nodeName,
        };

        const {
          currentTag,
          currentTagName,
          parentElement,
          parentElementTag,
        } = options;

        if (currentTagName === 'TD') {
          currentTag.style.border = `1px solid ${theme.color.primary}`;
          currentTag.setAttribute('contentEditable', editMode);
          setMainEditor(currentTag);
          currentTag.focus();
        } else {
          currentTag.style.border = 'none';
          currentTag.style.outline = 'none';
        }

        if (parentElementTag === 'TD') {
          parentElement.style.border = `1px solid ${theme.color.primary}`;
          parentElement.setAttribute('contentEditable', editMode);
          setMainEditor(parentElementTag);
          parentElement.focus();
        }
      });

      td.addEventListener('blur', (event) => {
        event.target.removeAttribute('contentEditable');

        const options = {
          currentTag: event.target,
          currentTagName: event.target.tagName,
          parentElement: event.target.parentElement,
          parentElementTag: event.target.parentElement.nodeName,
        };

        const {
          currentTag,
          currentTagName,
          parentElement,
          parentElementTag,
        } = options;

        if (currentTagName === 'TD') {
          currentTag.style.border = 'none';
          currentTag.style.borderRight = `1px solid ${theme.color.borderColor}`;
        } else {
          currentTag.style.border = 'none';
          currentTag.style.outline = 'none';
        }

        if (parentElementTag === 'TD') {
          parentElement.style.border = 'none';
          parentElement.style.borderRight = `1px solid ${theme.color.borderColor}`;
          console.log(parentElement);
        }

        editorRef.current.setAttribute('contentEditable', editMode);
      });
    });
  }

  function InsertTables(values) {
    editorRef.current.focus();

    const columns = new Array(values.columns).fill(0);
    const rows = new Array(values.rows).fill(0);

    let html = '<table id="editor" >';

    columns.forEach((columns) => {
      html += ' <tr>';
      rows.forEach((row) => {
        html += `<td ></td>`;
      });
      html += '</tr>';
    });

    html += '</table>';

    editorRef.current.ownerDocument.execCommand('insertHTML', false, html);

    setValue(editorRef.current.innerHTML);

    SetOnClickOnTableData();
  }

  return (
    <>
      <Modal
        title="Adicionar tabelas"
        okText="Adicionar"
        onOk={() => {
          form.validateFields().then((values) => {
            InsertTables(values);
          });
        }}
        Trigger={<Button style={{ margin: '0 5px 5px 0' }}>Tabelas</Button>}
      >
        <>
          <Form form={form} layout="vertical">
            <div className="flex">
              <FormItem
                label="Colunas"
                name={['columns']}
                style={{ width: '100%' }}
                initialValues
                item={<InputNumber defaultValue={1} min={1} max={10} />}
              />
              <FormItem
                style={{ width: '100%' }}
                label="Linhas"
                name={['rows']}
                item={<InputNumber defaultValue={1} min={1} max={10} />}
              />
            </div>
          </Form>
        </>
      </Modal>
    </>
  );
}

export default memo(GenerateTables);
