import React from 'react';

import Text from '~/components/typography/text';
import Avatar from '~/components/avatar';

import {
  WalletFormatConversion,
  CapitalizeString,
} from '~/utils/Transformations';

import { Container, Content, FlexContent } from './style';

export default function PersonInfo({ deposit }) {
  console.log(deposit);

  return (
    <Container bordered={false}>
      <Content width="70%" align="flex-start">
        <FlexContent>
          <Avatar src={deposit.person_avatar} size={42} />
        </FlexContent>
        <FlexContent>
          <Text>{CapitalizeString(deposit.person_name)}</Text>
          <Text>{CapitalizeString(deposit.person_cpf_number)}</Text>
        </FlexContent>
      </Content>

      <Content width="30%" align="flex-end">
        <FlexContent>
          <Text strong>{WalletFormatConversion(deposit.amount)}</Text>
        </FlexContent>
      </Content>
    </Container>
  );
}
