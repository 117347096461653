import React, { useState, memo } from 'react';
// Standalone Components
import { ModalContainer } from './style';

function AntModal({
  afterClose,
  bodyStyle,
  cancelButtonProps,
  cancelText,
  centered,
  closable,
  closeIcon,
  content,
  confirmLoading,
  destroyOnClose,
  footer,
  forceRender,
  getContainer,
  keyboard,
  mask,
  maskClosable,
  maskStyle,
  modalRender,
  okButtonProps,
  okText,
  okType,
  onOk,
  onCancel,
  style,
  title,
  Trigger,
  width,
  wrapClassName,
  zIndex,
  children,
}) {
  const [visible, setVisible] = useState(false);

  function ShowModal() {
    setVisible(true);
  }
  function CloseModal() {
    setVisible(false);
  }
  function OnOk() {
    onOk();
    CloseModal();
  }
  function OnCancel() {
    onCancel();
    CloseModal();
  }

  return (
    <>
      <div onClick={() => ShowModal()}>{Trigger}</div>
      <ModalContainer
        afterClose={afterClose}
        bodyStyle={bodyStyle}
        cancelButtonProps={cancelButtonProps}
        cancelText={cancelText}
        centered={centered}
        closable={closable}
        closeIcon={closeIcon}
        confirmLoading={confirmLoading}
        destroyOnClose={destroyOnClose}
        footer={footer}
        forceRender={forceRender}
        getContainer={getContainer}
        keyboard={keyboard}
        mask={mask}
        maskClosable={maskClosable}
        maskStyle={maskStyle}
        modalRender={modalRender}
        okButtonProps={okButtonProps}
        okText={okText}
        okType={okType}
        onCancel={onCancel ? OnCancel : CloseModal}
        onOk={onOk ? OnOk : CloseModal}
        style={style}
        title={title}
        visible={visible}
        width={width}
        wrapClassName={wrapClassName}
        zIndex={zIndex}
      >
        {content || children}
      </ModalContainer>
    </>
  );
}

export default memo(AntModal);
