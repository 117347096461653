import React, { useEffect, useState, useContext } from 'react';

import { Empty } from 'antd';

import DepositsTable from './components/DepositsTable';
import Filters from './components/Filters';

import Pagination from '~/components/pagination';
import Loading from '~/components/loading';
import Card from '~/components/card';
import Divider from '~/components/divider';
import Title from '~/components/typography/title';

import { ThemeContext } from '~/contexts/ThemeContext';

import Debug from '~/environments/Debug';

// Contexts
import SocialMeAPI from '~/utils/SocialMeAPI';

import { Div, FlexCenter } from './style';

export default function ManualDeposits() {
  const [loading, setLoading] = useState(true);

  const [manualDeposits, setManualDeposits] = useState([]);

  const [status, setStatus] = useState('PENDING');
  const [limit, setLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(10);

  const { theme } = useContext(ThemeContext);

  const isDebug = Debug.Administrator.ManualDeposits;

  useEffect(() => {
    async function GetDeposits() {
      setLoading(true);

      const params = {
        operation: ['Admin', 'ManualDeposits', 'List'],
        data: {
          pagination: {
            limit,
            page: currentPage,
          },
          filter: {
            nature: 'CASH-OUT',
            status,
          },
        },
      };

      if (isDebug) console.log('📦 Params: ', params);

      const response = await SocialMeAPI(params);

      if (isDebug)
        console.log('🚀 API Response, Admin ManualDeposits List: ', response);

      if (response.success) {
        if (isDebug) console.log('deposits was successfully listed');

        const { elements, totalElements } = response.body.manualOperations;

        setManualDeposits(elements);
        setTotalItems(totalElements);
      } else if (isDebug) {
        console.log('No deposits waslisted');
      }

      setLoading(false);
    }
    GetDeposits();
  }, [isDebug, status, currentPage, limit]);

  async function GetDepositsAgain() {
    setLoading(true);

    const params = {
      operation: ['Admin', 'ManualDeposits', 'List'],
      data: {
        pagination: {
          limit,
          page: currentPage,
        },
        filter: {
          nature: 'CASH-IN',
          status,
        },
      },
    };

    if (isDebug) console.log('📦 Params: ', params);

    const response = await SocialMeAPI(params);

    if (isDebug)
      console.log('🚀 API Response, Admin ManualDeposits List: ', response);

    if (response.success) {
      if (isDebug) console.log('deposits was successfully listed');

      const { elements, totalElements } = response.body.manualOperations;

      setManualDeposits(elements);
      setTotalItems(totalElements);
    } else if (isDebug) {
      console.log('No deposits waslisted');
    }

    setLoading(false);
  }

  return (
    <Div>
      <Card>
        <FlexCenter>
          <Title level={4}>Cash-In Manual</Title>
        </FlexCenter>
        <Divider borderColor={theme.color.white} />
        {loading ? (
          <Loading />
        ) : (
          <>
            <Filters
              theme={theme}
              status={status}
              setStatus={setStatus}
              setCurrentPage={setCurrentPage}
              setLimit={setLimit}
            />
            {manualDeposits.length === 0 ? (
              <Empty description="Nenhum depósito foi encontrado" />
            ) : (
              <>
                <DepositsTable
                  GetDepositsAgain={GetDepositsAgain}
                  theme={theme}
                  manualDeposits={manualDeposits}
                />
                <Pagination
                  onChange={(page) => setCurrentPage(page)}
                  onShowSizeChange={(current, size) => setLimit(size)}
                  total={totalItems}
                  limit={limit}
                  currentPage={currentPage}
                />
              </>
            )}
          </>
        )}
      </Card>
    </Div>
  );
}
