import React, { useContext, memo } from 'react';
//Own Components
import FormItems from './components/FormItems';
// Components
import Page from '~/components/pageStructure/type1';
import { ImageWithPrimaryAndSecondaryTextCell } from '~/components/table/cells';
// Contexts
import { AuthContext } from '~/contexts/AuthContext';

function Countries() {
  const { user } = useContext(AuthContext);

  const breadcrumbItems = [
    {
      title: 'Painel do Administrador',
    },
    {
      title: 'Países',
    },
  ];
  const tableColumns = [
    {
      title: 'País',
      dataIndex: 'name',
      key: 'name',
      render: (name, record) => (
        <ImageWithPrimaryAndSecondaryTextCell
          primaryText={name || 'sem nome'}
          secondaryText={
            record.capital && record.capital.name
              ? `Capital: ${record.capital.name}`
              : ''
          }
          image={record.flag || null}
        />
      ),
    },
  ];

  return (
    <Page
      // Geral Functions
      parametersToGetTheData={{
        operation: ['Geo', 'Get'],
        urlParams: {
          personId: user._id,
        },
        data: {
          filter: {
            type: 'COU',
          },
          sort: {
            by: 'name',
            order: 'asc',
          },
        },
      }}
      getTheDataMessages={{
        success: 'Países listados com sucesso!',
        error: 'Alguma coisa aconteceu...',
      }}
      pathToTheData={['body', 'countryStateCityCommunityList', 'elements']}
      pagination
      pathToThePageParameters={['body', 'countryStateCityCommunityList']}
      FormItems={FormItems}
      // Header
      title="Países"
      breadcrumbItems={breadcrumbItems}
      addButtonText="Novo país"
      search
      searchPlaceholder="Buscar país"
      parametersToObtainTheDataWithSearch={{
        operation: ['Geo', 'Get'],
        urlParams: {
          personId: user._id,
        },
        data: {
          filter: {
            type: 'CO',
            searchString: 'searchInput',
          },
          sort: {
            by: 'name',
            order: 'asc',
          },
        },
      }}
      parametersForAddingData={{
        operation: ['Geo', 'Add'],
        urlParams: {
          personId: user._id,
        },
      }}
      addingDataInitialValues={{
        type: 'CO',
      }}
      addingDataMessages={{
        success: 'País adicionado com sucesso!',
        error: 'Alguma coisa aconteceu...',
      }}
      // Content
      tableColumns={tableColumns}
      actionCellType="EditingAndDeleting"
      paramsIdName="countryStateCityId"
      operationForDeleteData={['Geo', 'Delete']}
      deleteDataMessages={{
        success: 'País apagado com sucesso!',
        error: 'Alguma coisa aconteceu...',
      }}
      operationForEditData={['Geo', 'Edit']}
      editDataMessages={{
        success: 'País atualizado com sucesso!',
        error: 'Alguma coisa aconteceu...',
      }}
    />
  );
}

export default memo(Countries);
