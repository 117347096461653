import React, { useContext } from 'react';
//Libraries
import { Avatar } from 'antd';
import Animate, { FadeIn } from 'animate-css-styled-components';
//Contexts
import { ThemeContext } from '~/contexts/ThemeContext';
//Components
import Title from '~/components/typography/title';
//Utils
import { CapitalizeFirstLetter } from '~/utils/GeneralFunctions';

export default function FoundPersonContainer({ foundPerson }) {
    const { theme } = useContext(ThemeContext);
    return (
        <div style={{ display: 'flex', justifyContent: 'center', padding: 50 }}>
            <Animate
                Animation={[FadeIn]}
                duration={['0.8s']}
                delay={['0.2s']}
            >
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                    <div>
                        {foundPerson.avatar ?
                            <Avatar src={foundPerson.avatar} size={150} />
                            :
                            <Avatar
                                style={{
                                    color: theme.color.textOnPrimaryBackground,
                                    backgroundColor: theme.color.primary,
                                }}
                                size={150}
                            >
                                {foundPerson.social_name ? foundPerson.social_name.charAt(0) : foundPerson.name.charAt(0)}
                            </Avatar>
                        }
                    </div>
                    <div style={{ marginTop: 20 }}>
                        <Title level={4}>{foundPerson.social_name ? CapitalizeFirstLetter(foundPerson.social_name) : CapitalizeFirstLetter(foundPerson.name)}</Title>
                    </div>
                </div>
            </Animate>
        </div>
    )
}