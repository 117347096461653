import React from 'react';
// Libraries
import { Row, Col } from 'antd';
//Components
import FormItem from '~/components/form/FormItem';
import TextArea from '~/components/textArea';
import DynamicTags from '~/components/tags/dynamicTags';

export default function AdminInformation({
  tags,
  setTags,
  personalTags,
  setPersonalTags,
  locationTags,
  setLocationTags,
}) {
  return (
    <div>
      <Row>
        <Col span={24}>
          <FormItem
            label="Descrição curta"
            name={['short_description']}
            rules={[
              {
                required: true,
                message: 'A descrição do adiministrador é obrigatória',
              },
            ]}
            item={
              <TextArea
                maxLength={280}
                showCount
                autoSize={{ minRows: 4, maxRows: 6 }}
              />
            }
          />
        </Col>
        <Col span={24}>
          <FormItem
            label="Comentário do Admin"
            name={['resolved_by_comment']}
            rules={[
              {
                required: true,
                message: 'O comentário do adiministrador é obrigatória',
              },
            ]}
            item={
              <TextArea
                maxLength={280}
                showCount
                autoSize={{ minRows: 3, maxRows: 5 }}
              />
            }
          />
        </Col>
        <Col span={24}>
          <FormItem
            label="Tags"
            name={['tags']}
            item={<DynamicTags tags={tags} setTags={setTags} />}
          />
        </Col>
        <Col span={24}>
          <FormItem
            label="Tags pessoais"
            name={['tags']}
            item={<DynamicTags tags={personalTags} setTags={setPersonalTags} />}
          />
        </Col>
        <Col span={24}>
          <FormItem
            label="Tags de localização"
            name={['tags']}
            item={<DynamicTags tags={locationTags} setTags={setLocationTags} />}
          />
        </Col>
      </Row>
    </div>
  );
}
