import styled from 'styled-components';

import { Row, Col } from 'antd';

export const AntRow = styled(Row)`
  align-items: center;
  justify-content: space-between;
`;

export const AntCol = styled(Col)``;
